import Language from '../pages'

export default {
  message: {
    hello: '你好世界'
  },
  // 头部
  header: {
    brand: {
      title: '品牌中心',
      ppgs: '品牌故事',
      mxzx: '明星之选',
      ppsj: '品牌瞬间',
      xwzx: '新闻中心'
    },
    store: {
      title: '门店探索',
      list: [
        '杭州柏悅', '北京半岛', '成都华尔道夫', '北京瑰丽', '深圳莱佛士', '上海镛舍公寓', '广州瑰丽府邸', '北京璞瑄', '香港HUMANSA', '深圳文华东方', '上海外滩华尔道夫', '上海苏宁宝丽嘉', '厦门七尚', '杭州秋水山庄', '上海愚园独栋洋房', '香港四季'
      ],
      rzjd: {
        title1: '圣贝拉母婴护理中心入驻酒店',
        title2: '母婴护理中心独栋系列'
      }
    },
    zyhh: {
      title: '专业呵护',
      hs1d1: '24h护士一对一',
      hlms: '护理模式',
      mmhh: '妈妈呵护',
      bbhh: '宝宝呵护',
      ycqzqfw: 'LTV孕产全周期服务',
      ysly: '艺术疗养'
    },
    yzss: {
      title: '月子膳食',
      sjdsstl: '四阶段膳食调理',
      wdssyz: '五大膳食原则'
    },
    chxf: {
      title: '产后修复',
      chyxs: 'STB产后研修所',
      zycp: 'STB自研产品'
    },
    country: {
      cn: '中国大陆',
      hk: '中国香港',
      sg: '新加坡'
    }
  },
  // 底部
  footer: {
    qgmd: '全球门店',
    ppzx: '品牌中心',
    hyqy: '会员权益',
    zyhh: '专业呵护',
    yjyys: '予家',
    bkhlxx: '贝康护理学校',
    lxwm: {
      title: '联系我们',
      name: '杭州贝康健康科技集团有限公司（总部）',
      address: '地址：杭州市萧山区建设二路666号信息港六期6幢1层',
      tel: '电话：',
      email: '商务合作：',
      mtlx: '媒体联系：'
    },
    zskf: {
      title: '专属客服',
      time: '周一至周日 ',
      rzzx: '入住咨询:'
    },
    gzjh: '工作机会',
    sidebar: {
      title1: '圣贝拉母婴护理中心入驻酒店',
      title2: '母婴护理中心独栋系列'
    }
  },
  comp: {
    xxgd: '向下滚动'
  },
  // 首页
  home: Language.homeCn,
  // 品牌中心
  brand: {
    story: Language.brandStoryCn,
    ...Language.brandCn
  },
  major: {
    hours: Language.majorHoursCn,
    cycle: Language.majorCycleCn,
    art: Language.majorArtCn,
    join: Language.majorJoinCn,
    timer: Language.majorTimerCn,
    ...Language.majorCn
  },
  month: Language.monthFoodCn,
  school: Language.schoolCn,
  store: {
    detail: Language.storeDetailCn,
    ...Language.storeCn
  },
  repair: Language.postpartumRepairCn,
  vip: Language.vipCn,
  job: Language.jobHuntCn,
  yujia: Language.yujiaCn
}
