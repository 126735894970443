<template>
  <div class="text" :class="[className]">
    <p :class="hideClass" @mouseenter="mouseenter" @mouseout="mouseout">{{ value }}</p>
    <div class="hover" :class="[className]" :style="{ opacity }">{{ value }}</div>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'

const opacity = ref(0)

// eslint-disable-next-line no-undef
const props = defineProps({
  className: {
    type: String,
    default: () => ''
  },
  line: String,
  value: String
})
const hideClassKey = ['text-hide-1', 'text-hide-2', 'text-hide-3']
const hideClass = computed(() => {
  const line = props.line
  let value = ''
  !line && (value = '')
  value = hideClassKey[line - 1] || ''
  if (value === '') console.error('传入隐藏行数有误,仅支持 1 || 2 || 3')
  return value
})

// 鼠标进入
const mouseenter = () => {
  opacity.value = 1
}

// 鼠标离开
const mouseout = () => {
  opacity.value = 0
}

</script>

<style lang="less" scoped>
.text {
  position: relative;
  p {
    line-height: inherit;
    font-weight: inherit;
  }
  .hover {
    position: absolute;
    top: 0px;
    left: 0;
    right: 0;
    margin: 0 auto;
    transform: translateY(-100%);
    transition: all 0.3s;
    // z-index: 1 !important;
    // opacity: 1 !important;
    background-color: #000;
    color: #fff;
    padding: 12px;
    border-radius: 12px;
    box-sizing: border-box;
    &::after {
      content: '';
      position: absolute;
      width: 12px;
      height: 12px;
      background-color: #000;
      bottom: 0;
      left: 0;
      right: 0;
      transform: translateY(40%) rotateZ(45deg);
      margin: 0 auto;
    }
  }
}

</style>

<!-- web端样式 -->
<style lang="less" scoped>
@media screen and (max-width: 751px) {
}
</style>
