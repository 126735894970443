// 中文翻译
const cn = {
  zyhh: '专业呵护',
  xshl: '24小时1v1护理，跨越孕产全周期的陪伴式服务',
  jkts: '即刻探索',
  xshs: '24小时护士一对一',
  ycqzqfw: '孕产全周期服务',
  ysly: '艺术疗养'
}
// 繁体翻译
const tw = {
  zyhh: '專業呵護',
  xshl: '24小時1v1護理，跨越孕產全週期的陪伴式服務',
  jkts: '即刻探索',
  xshs: '24小時護士一對一',
  ycqzqfw: '孕產全週期服務',
  ysly: '藝術療養'
}
// 英文翻译
const en = {
  zyhh: '', // 专业呵护
  xshl: '24-hour 1v1 care, companionship service spanning the entire pregnancy and childbirth cycle',
  jkts: 'Explore Now',
  xshs: '', // 24小时护士一对一
  ycqzqfw: '', // 孕产全周期服务
  ysly: '' // 艺术疗养
}
export { cn, tw, en }
