<template>
  <div class="swiper-contianer">
    <swiper class="mySwiper" :centeredSlides="true" :slidesPerView="'auto'" :spaceBetween="65" :modules="modules"
      :simulateTouch="false" :navigation="{
        prevEl: '.page-prev',
        nextEl: '.page-next'
      }" :onSlideChange="onSlideChange">
      <swiper-slide v-for="(item, index) in list" :key="index">
        <template v-if="Array.isArray(item)">
          <div class="block" v-for="_item in item" :key="_item.name">
            <a :href="_item.link" target="_blank">
              <div class="image_hide">
                <img :src="getAssetsSrc(_item.imgPath)" />
              </div>
              <h4 class="title text-hide">
                <span v-for="(text, textIndex) in _item.title?.split?.(' x ')" :key="text">
                  {{ text }}
                  <span v-if="textIndex + 1 !== _item.title?.split?.(' x ').length" class="x">x</span>
                </span>
              </h4>
              <p class="titleCn text-hide">{{ _item.subhead }}</p>
              <p class="year">{{ _item.label }}</p>
              <p class="desc text-hide-3">{{ _item.desc }}</p>
            </a>
          </div>
        </template>
        <template v-else>
          <a :href="item.link" target="_blank">
            <div class="image_hide">
              <img :src="getAssetsSrc(item.imgPath)" />
            </div>
            <h4 class="title text-hide">
              <span v-for="(text, textIndex) in item.title?.split?.(' x ')" :key="text">
                {{ text }}
                <span v-if="textIndex + 1 !== item.title?.split?.(' x ').length" class="x">x</span>
              </span>
            </h4>
            <p class="titleCn text-hide-2">{{ item.subhead }}</p>
            <p class="year">{{ item.label }}</p>
            <p class="desc text-hide-3">{{ item.desc }}</p>
          </a>
        </template>

      </swiper-slide>
      <div class="page-control">
        <div class="page-prev">
          <div class="line-x"></div>
          <div class="line-y"></div>
        </div>
        <div class="page-next">
          <div class="line-x"></div>
          <div class="line-y"></div>
        </div>
      </div>
    </swiper>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import { getAssetsSrc, valueLang, getIsMobile } from '@/util'
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Navigation } from 'swiper'
import { _getMomentList } from '@/api'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/effect-coverflow'
import 'swiper/css/pagination'

const modules = [Navigation]
const list = ref([])
// 新闻请求参数
const post = ref({
  limit: 9,
  page: 1
})
// 获取瞬间列表
const getList = () => {
  _getMomentList(post.value).then(res => {
    res.list = res.list.map(v => ({ ...v, title: v[valueLang('title')], subhead: v[valueLang('subhead')], desc: v[valueLang('desc')] }))
    if (!getIsMobile()) {
      // 狗屁需求 cnmd
      res.list = [res.list.slice(0, 3), res.list.slice(3, 6), res.list.slice(6, 9)].filter(v => v.length !== 0)
    }
    list.value = post.value.page === 1 ? res.list : [...list.value, ...res.list]
    res.list.length && post.value.page++
  })
}

onMounted(() => {
  getList()
})

// swiper发生变化
const onSlideChange = (swiper) => {
  const { activeIndex, slides } = swiper
  activeIndex + 2 === slides?.length && getList()
}
</script>

<style lang="less" scoped>
.swiper-contianer {
  width: 100%;

  .mySwiper {
    width: 100%;
    height: 828px;
    // padding-left: 120px;
    box-sizing: border-box;

    .swiper-slide {
      width: 1920px;
      padding: 0 0 0 90px;
      box-sizing: border-box;
      display: flex;

      .block {
        width: 554px;
        margin-right: 38px;
      }

      .image_hide {
        overflow: hidden;
        width: 100%;
        height: 360px;

        img {
          width: 100%;
          height: 100%;
          transition: all 0.6s;

          &:hover {
            transform: scale(1.1);
          }
        }
      }

      .title {
        margin-top: 42px;
        font-size: 30px;
        font-family: STSongti-SC-Regular;
        font-weight: 400;
        color: #000000;
        line-height: 44px;
        display: flex;
        align-items: center;

        .x {
          font-size: 20px;
          font-family: SourceHanSansCN-Light, SourceHanSansCN;
          font-weight: 300;
          color: #000000;
          line-height: 36px;
          margin: 0 12px 0 9px;
        }
      }

      .titleCn {
        font-size: 30px;
        font-family: STSongti-SC-Regular, STSongti-SC;
        font-weight: 400;
        color: #000000;
        line-height: 42px;
        margin-top: 6px;
      }

      .year {
        font-size: 16px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #000000;
        line-height: 36px;
        margin-top: 16px;
      }

      .desc {
        font-size: 18px;
        font-family: SourceHanSansCN-Light, SourceHanSansCN;
        font-weight: 300;
        color: #000000;
        line-height: 34px;
        margin-top: 10px;
      }
    }
  }

  .page-control {
    position: absolute;
    bottom: 20px;
    left: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 9;

    .page-prev,
    .page-next {
      border: 1px solid #999;
      cursor: pointer;
      width: 66px;
      height: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: all 0.3s;

      .line {

        &-x {
          width: 12px;
          height: 1px;
          background-color: #000;
          position: absolute;
          transform-origin: bottom left;
          transform: rotateZ(-50deg);
        }

        &-y {
          width: 12px;
          height: 1px;
          background-color: #000;
          position: absolute;
          transform-origin: top left;
          transform: rotateZ(50deg);
        }
      }

      &:hover {
        background-color: #000;

        .line-x,
        .line-y {
          background-color: #fff;
        }
      }
    }

    .page-next {
      border-right: none;
      transform: rotateZ(180deg);
    }
  }

}

.swiper .swiper-button-disabled {
  .line- {

    &x,
    &y {
      opacity: 0.2;
    }
  }

  &:hover {
    background-color: #fff !important;
    cursor: auto;

    .line- {

      &x,
      &y {
        background-color: #000 !important;
      }
    }
  }
}
</style>

<!-- web端样式 -->
<style lang="less" scoped >
@media screen and (max-width: 751px) {
  .contianer {
    padding: 0 0 160px;

    .title {
      font-size: 60px;
      line-height: 87px;
    }

    .subtitle {
      font-size: 30px;
      line-height: 42px;
      margin-top: 6px;
    }
  }

  .swiper-contianer {
    .mySwiper {
      // padding-left: 60px;
      height: 885px;

      .swiper-slide {
        width: 554px;
        // overflow: hidden;
        padding: 0;

        // margin-right: 60px !important;
        .title {
          margin-top: 40px;
          width: 554px;

          span {
            font-size: 36px;
            line-height: 52px;

            .x {
              font-size: 24px;
              line-height: 36px;
            }
          }
        }

        .titleCn {
          font-size: 36px;
          line-height: 50px;
          margin-top: 6px;
        }

        .year {
          font-size: 22px;
          line-height: 36px;
          margin-top: 20px;
        }

        .desc {
          font-size: 24px;
          line-height: 44px;
          margin-top: 30px;
        }
      }
    }
  }
}
</style>
