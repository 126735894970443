import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, unref as _unref, withCtx as _withCtx, vModelText as _vModelText, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withModifiers as _withModifiers } from "vue";
const _hoisted_1 = {
  key: 1,
  class: "container"
};
const _hoisted_2 = {
  class: "main"
};
const _hoisted_3 = {
  class: "info"
};
const _hoisted_4 = {
  class: "value"
};
const _hoisted_5 = {
  class: "value"
};
const _hoisted_6 = {
  class: "from"
};
const _hoisted_7 = {
  class: "select"
};
const _hoisted_8 = {
  class: "select"
};
const _hoisted_9 = ["placeholder"];
import { ElCascader, ElSelect, ElOption, ElMessage } from 'element-plus';
import 'element-plus/es/components/cascader/style/css'
import 'element-plus/es/components/select/style/css'
import 'element-plus/es/components/option/style/css'
import 'element-plus/es/components/message/style/css'

import { lockScroll, unlockScroll, getConfig, getDistance, storage, timerDebounce } from '@/util';
import store from '@/store';
import { BaiduMap } from 'vue-baidu-map-3x';
import { computed, ref, reactive, watchEffect, onMounted, watch } from 'vue';
import { _submitFeedBack } from '@/api';

// 是否人工客服

export default {
  __name: 'Feedback',
  setup(__props, {
    expose: __expose
  }) {
    const isArtificial = computed(() => storage('serviceMode') === '1');
    // 门店信息
    const storeInfo = reactive({
      countryList: JSON.parse(storage('CountryList')) || [],
      // 国家列表
      storeList: [],
      // 门店列表
      checkStore: {},
      // 当前选中的门店信息
      recentlyStore: {} // 根据经纬度计算的最近门店信息
    });
    const feedbackShow = ref(false);
    const mapState = reactive({
      zoom: 3,
      center: {
        lng: 0,
        lat: 0
      }
    });
    const post = ref({
      name: '',
      email: '',
      phone: '',
      content: '',
      country: '',
      // 意向国家
      store: '',
      // 意向门店
      budget: '',
      // 预算
      edoc: '' // 预产期
    });

    // 渲染门店列表
    const renderStoreList = computed(() => {
      return storeInfo.countryList.map(v => ({
        value: v.id,
        label: v.identifier,
        children: storeInfo.storeList.filter(i => i.countryID === v.id).map(l => ({
          value: l.name_en,
          label: l.name_en
        }))
      }));
    });
    // 渲染预算信息
    const renderBudget = computed(() => {
      const identifier = storeInfo.countryList.find(v => v.id === post.value.country)?.identifier;
      if (identifier === 'SG') {
        return {
          tips: 'Budget (SGD)',
          list: ['Up to $30,000', '$30,000-50,000', '$50,000-70,000', '$70,000-90,000', 'Above $90,000']
        };
      }
      if (identifier === 'HK') {
        return {
          tips: 'Budget (HKD)',
          list: ['HKD 300,000-400,000', 'HKD 400,000-500,000', 'Above HKD 500,000']
        };
      }
      if (identifier === 'CN') {
        return {
          tips: 'Budget (RMB)',
          list: ['RMB 150,000-250,000', 'RMB 250,000-300,000', 'Above RMB 300,000']
        };
      }
      return {
        tips: 'Budget',
        list: []
      };
    });
    watch(() => post.value.store, () => {
      // post.value.store = ''
      post.value.budget = '';
    });

    // 设置距离最近的门店
    watch(() => store.state.storeList, storeList => {
      if (isArtificial.value) return;
      const ipInfo = JSON.parse(storage('IpInfo')) || {};
      storeInfo.storeList = storeList;
      if (storeList?.length >= 0 && ipInfo.lat && ipInfo.lng) {
        const distanceList = storeList.map(v => {
          if (v.location === null) return 999999999999999;
          const [lat, lng] = v.location.split(',');
          // 后台把经纬度写反了，这里重新设置一下
          v.center = {
            lng: lat,
            lat: lng
          };
          return getDistance({
            lat: ipInfo.lat,
            lng: ipInfo.lng
          }, {
            lat,
            lng
          }).m;
        });
        // 距离最近的门店
        const recentlyStore = storeList[distanceList.findIndex(v => v === Math.min(...distanceList))];
        storeInfo.recentlyStore = recentlyStore;
      }
    });

    // 选中门店信息
    const renderStore = computed(() => post.value.store ? storeInfo.countryList.find(v => v.id === post.value.country)?.identifier + ' / ' + post.value.store : '');
    // 设置选中门店信息
    const handleChange = e => {
      post.value.country = e[0];
      post.value.store = e[1];
    };
    const openFeedback = () => {
      feedbackShow.value = true;
    };
    const submit = () => {
      const objKeys = Object.keys(post.value);
      for (let i = 0; i < objKeys.length; i++) {
        if (post.value[objKeys[i]] === '') return ElMessage('Please fill out the form completely');
      }
      const _post = {
        ...post.value,
        country: storeInfo.countryList.find(v => v.id === post.value.country)?.name,
        store: storeInfo.storeList.find(v => v.name_en === post.value.store)?.name
      };
      _submitFeedBack(_post).then(() => {
        ElMessage({
          message: 'Submitted successfully.',
          type: 'success'
        });
        feedbackShow.value = false;
        post.value = {
          name: '',
          email: '',
          phone: '',
          content: '',
          budget: '',
          edoc: '',
          country: '',
          store: ''
        };
      });
    };
    const ready = async res => {
      const config = await getConfig(false);
      mapState.zoom = 17;
      const center = {
        lng: 103.82554111681691,
        lat: 1.250350444652684
      };
      if (config.location) {
        const location = config.location.split(',');
        center.lng = location[0];
        center.lat = location[1];
      }
      mapState.center = center;
    };
    __expose({
      openFeedback
    });
    onMounted(() => {
      post.value.edoc = getNowFormatDate();
    });

    // 获取当前日期函数
    const getNowFormatDate = () => {
      const date = new Date();
      const year = date.getFullYear(); // 获取完整的年份(4位)
      let month = date.getMonth() + 1; // 获取当前月份(0-11,0代表1月)
      let strDate = date.getDate(); // 获取当前日(1-31)
      if (month < 10) month = `0${month}`; // 如果月份是个位数，在前面补0
      if (strDate < 10) strDate = `0${strDate}`; // 如果日是个位数，在前面补0
      return `${year}-${month}-${strDate}`;
    };
    watchEffect(() => {
      if (feedbackShow.value) {
        lockScroll();
      } else {
        unlockScroll();
        post.value.edoc = getNowFormatDate();
      }
    });
    return (_ctx, _cache) => {
      const _component_bm_marker = _resolveComponent("bm-marker");
      return _openBlock(), _createElementBlock(_Fragment, null, [!isArtificial.value ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "click",
        onClick: openFeedback
      })) : _createCommentVNode("", true), feedbackShow.value ? (_openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", {
        class: "feedback",
        onClick: _cache[9] || (_cache[9] = _withModifiers(() => {}, ["stop"]))
      }, [_createElementVNode("div", {
        class: "close",
        onClick: _cache[0] || (_cache[0] = $event => feedbackShow.value = false)
      }, _cache[10] || (_cache[10] = [_createElementVNode("div", {
        class: "x"
      }, null, -1), _createElementVNode("div", {
        class: "y"
      }, null, -1)])), _createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_cache[11] || (_cache[11] = _createElementVNode("p", {
        class: "title"
      }, "联系我们", -1)), _cache[12] || (_cache[12] = _createElementVNode("p", {
        class: "title"
      }, "Contact Us", -1)), _cache[13] || (_cache[13] = _createElementVNode("p", {
        class: "address"
      }, "The Nearest Address", -1)), _createElementVNode("p", _hoisted_4, _toDisplayString(storeInfo.recentlyStore.addressName_en), 1), _createElementVNode("p", _hoisted_5, _toDisplayString(storeInfo.recentlyStore.addressValue_en), 1), _cache[14] || (_cache[14] = _createElementVNode("p", {
        class: "email"
      }, [_createElementVNode("span", null, "Email："), _createTextVNode("info@saintbella.com")], -1))]), _createVNode(_unref(BaiduMap), {
        class: "map",
        zoom: mapState.zoom,
        center: storeInfo.recentlyStore?.center,
        onReady: ready
      }, {
        default: _withCtx(() => [_createVNode(_component_bm_marker, {
          position: storeInfo.recentlyStore?.center,
          dragging: true
        }, null, 8, ["position"])]),
        _: 1
      }, 8, ["zoom", "center"])]), _createElementVNode("div", _hoisted_6, [_withDirectives(_createElementVNode("input", {
        class: "input",
        placeholder: "Name",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => post.value.name = $event)
      }, null, 512), [[_vModelText, post.value.name]]), _withDirectives(_createElementVNode("input", {
        class: "input",
        placeholder: "Email",
        "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => post.value.email = $event)
      }, null, 512), [[_vModelText, post.value.email]]), _withDirectives(_createElementVNode("input", {
        class: "input",
        placeholder: "Phone",
        "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => post.value.phone = $event)
      }, null, 512), [[_vModelText, post.value.phone]]), _withDirectives(_createElementVNode("input", {
        class: "input inputDate",
        type: "date",
        placeholder: "Expected date of confinement",
        "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => post.value.edoc = $event),
        lang: "en_US.UTF-8"
      }, null, 512), [[_vModelText, post.value.edoc]]), _createElementVNode("div", _hoisted_7, [_withDirectives(_createElementVNode("input", {
        placeholder: "Intended Location",
        "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => renderStore.value = $event)
      }, null, 512), [[_vModelText, renderStore.value]]), _createVNode(_unref(ElCascader), {
        class: "feedbackcascader",
        options: renderStoreList.value,
        onChange: handleChange
      }, null, 8, ["options"])]), _createElementVNode("div", _hoisted_8, [_withDirectives(_createElementVNode("input", {
        placeholder: renderBudget.value.tips,
        "onUpdate:modelValue": _cache[6] || (_cache[6] = $event => post.value.budget = $event)
      }, null, 8, _hoisted_9), [[_vModelText, post.value.budget]]), _createVNode(_unref(ElSelect), {
        class: "feedbackcascader",
        "no-data-text": "Please select a location",
        onChange: _cache[7] || (_cache[7] = value => post.value.budget = value)
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(renderBudget.value.list, (value, index) => {
          return _openBlock(), _createBlock(_unref(ElOption), {
            key: index,
            label: value,
            value: value
          }, null, 8, ["label", "value"]);
        }), 128))]),
        _: 1
      })]), _withDirectives(_createElementVNode("textarea", {
        class: "textarea",
        "onUpdate:modelValue": _cache[8] || (_cache[8] = $event => post.value.content = $event),
        placeholder: "*If you are Chinese currently residing overseas, you may leave your mainland mobile number in the contact form.\n*After receiving your submission, a representative from our team will reach out to you within 24 hours."
      }, null, 512), [[_vModelText, post.value.content]])]), _createElementVNode("div", {
        class: "submit",
        onClick: submit
      }, "Submit")])])) : _createCommentVNode("", true)], 64);
    };
  }
};