// 中文翻译
const cn = {
  banner: {
    title: '月子膳食',
    subTitle: '五星级主厨烹饪，中医营养师定制月子膳食'
  },
  lddsy: {
    titlen: '28',
    title: '天流动的盛宴',
    desc: '由健康专家和营养师精心制作菜谱，酒店五星主厨烹饪，调配出符合哺乳期产妇所需营养及热量的四阶段调理餐食方案。<br>一日六餐，三餐三点，低油、低盐、无味素无化学调味品；搭配炖、蒸、熬、煲的调理，给予味觉与视觉的双重享受。<br>并于每日提供，源自中国台湾的广禾堂产后护理秘方养生茶。',
    text1: '每日热量控制在',
    text2: '2300-2500',
    text3: '大卡'
  },
  sstl: {
    title: '四阶段膳食调理',
    pai: ['排', '毒素，排废血及废水，轻松又舒畅'],
    tiao: ['调', '阴阳，促进细胞新陈代谢，提升自我修复能力'],
    yang: ['养', '肾元，补充能量帮助恢复元气，美容养颜'],
    bu: ['补', '气血，促进造血功能，补肝肾壮筋骨']
  },
  wdyz: {
    title: '遵循五大原则',
    list: [
      { title: '鲜食现做', value: '食材严格选用当季时令新鲜果蔬进行烹饪制作' },
      { title: '食材可追溯溯源地', value: '星级酒店严格把控食品供应要求，每例菜品和配料来源均可追溯' },
      { title: '标准定制化配餐', value: '国家一级营养师调配月子餐单，所有餐品要求营养均衡，少盐、少糖、少化学调料剂，每日控制在2300-2500卡路里' },
      { title: '独立送餐及严控卫生质检', value: '星级酒店主厨烹饪标准，星级酒店餐厅消毒保障，食物出品符合国家食品卫生要求，并以高级灭菌保温箱全程配送至房' },
      { title: '药食同源', value: '提供广禾堂5款秘制月子茶饮与月乃汤，根据产后不同体质，对天然药食同源成分进行黄金配比，以月子饮品科学平衡内在，温和无负' }
    ]
  },
  yrls: {
    title: '一日六食',
    list: [
      { title: '早餐', timer: '07:00-08:00', value: '快速吸收碳水补充前夜耗尽能量' },
      { title: '早点', timer: '10:00', value: '滋补食材避免午餐前饥饿导致情绪不佳' },
      { title: '午餐', timer: '11:00-12:00', value: '提供蛋白质、氨基酸等多重营养全面摄入' },
      { title: '午点', timer: '15:00', value: '以维生素C和糖分的摄入激发愉悦神经' },
      { title: '晚餐', timer: '17:00-18:00', value: '低热量食材科学料理不给肝脏造成负担' },
      { title: '晚点', timer: '20:00', value: '不含脂肪碳水为午夜的母乳喂养提供续航' }
    ]
  },
  ght: {
    title: '广禾堂',
    value: [
      '月子期提供广禾堂5款秘制月子茶饮，由其管理团队进行月子餐查房、排餐。',
      '作为具备20年传承经验，专注女性孕产滋补的品牌，广禾堂结合传统中医与现代营养学理论，提倡“药食同源，分阶食补”，天然药食同源成分，黄金配比，温和无负。研发团队由中医临床博士、执业药剂师、高级营养保健师、食品工程师等专业人士组成，为妈妈内在调理提供科学及安全保障。',
      '每周月子食养体验时刻，由营养顾问根据妈妈体质提供广禾堂系列及食愈产品品鉴，给予营养知识与调养建议，闲暇午后亦有食之滋养。'
    ]
  },
  ysc: {
    title: '养生茶',
    value: [
      '玫瑰元气茶：益气舒肝 红润暖养',
      '丽身养生茶：健脾祛湿 利水消肿',
      '桑菊决明茶：清热解毒 改善肠躁',
      '益圣养生茶：滋补肝肾 养血安神'
      // '月子回奶茶：回乳消胀 有效退奶'
    ]
  }
}
// 繁体翻译
const tw = {
  banner: {
    title: '月子膳食',
    subTitle: '五星級主廚烹飪，中醫營養師定製月子膳食'
  },
  lddsy: {
    titlen: '28',
    title: '天流動的盛宴',
    desc: '由健康專家和營養師精心製作菜譜，酒店五星主廚烹飪，調配出符合哺乳期產婦所需營養及熱量的四階段調理餐食方案。<br>一日六餐，三餐三點，低油、低鹽、無味素無化學調味品；搭配燉、蒸、熬、煲的調理，給予味覺與視覺的雙重享受。<br>並於每日提供，源自中國臺灣的廣禾堂產後護理秘方養生茶。',
    text1: '每日熱量控製在',
    text2: '2300-2500',
    text3: '大卡'
  },
  sstl: {
    title: '四階段膳食調理',
    pai: ['排', '毒素，排廢血及廢水，輕松又舒暢'],
    tiao: ['調', '陰陽，促進細胞新陳代謝，提升自我修復能力'],
    yang: ['養', '腎元，補充能量幫助恢復元氣，美容養顏'],
    bu: ['補', '氣血，促進造血功能，補肝腎壯筋骨']
  },
  wdyz: {
    title: '遵循五大原則',
    list: [
      { title: '鮮食現做', value: '食材嚴格選用當季時令新鮮果蔬進行烹飪製作' },
      { title: '食材可追溯溯源地', value: '星級酒店嚴格把控食品供應要求，每例菜品和配料來源均可追溯' },
      { title: '標準定製化配餐', value: '國家一級營養師調配月子餐單，所有餐品要求營養均衡，少鹽、少糖、少化學調料劑，每每日控製在2300-2500卡路裏' },
      { title: '獨立送餐及嚴控衛生質檢', value: '星級酒店主廚烹飪標準，星級酒店餐廳消毒保障，食物出品符合國家食品衛生要求，並以高級滅菌保溫箱全程配送至房' },
      { title: '藥食同源', value: '提供廣禾堂5款秘製月子茶飲與月乃湯，根據產後不同體質，對天然藥食同源成分進行黃金配比，以月子飲品科學平衡內在，溫和無負' }
    ]
  },
  yrls: {
    title: '一日六食',
    list: [
      { title: '早餐', timer: '07:00-08:00', value: '快速吸收碳水補充前夜耗盡能量' },
      { title: '早點', timer: '10:00', value: '滋補食材避免午餐前饑餓導致情緒不佳' },
      { title: '午餐', timer: '11:00-12:00', value: '提供蛋白質、氨基酸等多重營養全面攝入' },
      { title: '午點', timer: '15:00', value: '以維生素C和糖分的攝入激發愉悅神經' },
      { title: '晚餐', timer: '17:00-18:00', value: '低熱量食材科學料理不給肝臟造成負擔' },
      { title: '晚點', timer: '20:00', value: '不含脂肪碳水為午夜的母乳餵養提供續航' }
    ]
  },
  ght: {
    title: '廣禾堂',
    value: [
      '月子期提供廣禾堂5款秘製月子茶飲，由其管理團隊進行月子餐查房、排餐。',
      '作為具備20年傳承經驗，專註女性孕產滋補的品牌，廣禾堂結合傳統中醫與現代營養學理論，提倡「藥食同源，分階食補」，天然藥食同源成分，黃金配比，溫和無負。研發團隊由中醫臨床博士、執業藥劑師、高級營養保健師、食品工程師等專業人士組成，為媽媽內在調理提供科學及安全保障。',
      '每周月子食養體驗時刻，由營養顧問根據媽媽體質提供廣禾堂系列及食愈產品品鑒，給予營養知識與調養建議，閑暇午後亦有食之滋養。'
    ]
  },
  ysc: {
    title: '養生茶',
    value: [
      '玫瑰元氣茶：益氣舒肝 紅潤暖養',
      '麗身養生茶：健脾祛濕 利水消腫',
      '桑菊決明茶：清熱解毒 改善腸躁',
      '益聖養生茶：滋補肝腎 養血安神'
      // '月子回奶茶：回乳消脹 有效退奶'
    ]
  }
}
// 英文翻译
const en = {
  banner: {
    title: '', // monthFoodEn
    subTitle: 'Postpartum Meals Prepared by Five-Star Hotel Executive Chefs<br>& Customized by Traditional Chinese Medicine Nutritionists'
  },
  lddsy: {
    titlen: 'A 28-Day',
    title: 'Postpartum Meal Program',
    desc: 'Our 28-day postpartum meal program is designed to provide nursing mothers with the specific nutrition and calories they need to support their babies and themselves. The meals are thoughtfully crafted by health experts and nutritionists and prepared by chefs from five-star hotels. We offer a four-phase meal plan that is tailored to the changing nutritional needs of nursing mothers.<br>Each day, you will enjoy six meals: three meals and three snacks. Our meals are low in oil, low in salt, and free of artificial additives. We combine a variety of stewed, steamed, and braised dishes to create a culinary experience that is both delicious and nutritious.<br>We also provide daily servings of postpartum wellness tea, sourced from Taiwan Province\'s Guang He Tang. This tea is known for its ability to support lactation and promote postpartum recovery.',
    text1: 'Daily calorie intake is controlled within the range of',
    text2: '2300-2500',
    text3: 'kcal'
  },
  sstl: {
    title: 'Four Stages of Postpartum Dietary Requirements',
    pai: ['D', 'etoxification，Eliminate toxins and excess fluids, promoting comfort and well-being.'],
    tiao: ['B', 'alance Yin and Yang，Enhance cellular metabolism and boost self-repair capabilities.'],
    yang: ['N', 'ourishment，Replenish your energy to restore vitality and boost skin rejuvenation'],
    bu: ['T', 'onify Qi and Blood，Improve circulation, promote hematopoietic function, tonify liver and kidney, and strengthen bones and muscles']
  },
  wdyz: {
    title: 'Our Commitment to Excellence Follows the Five Principles',
    list: [
      { title: 'Freshness Guaranteed', value: 'We strictly use only fruits and vegetables in season as ingredients in preparing the meals' },
      { title: 'Traceable Ingredients', value: 'We maintain strict control over our sourcing for food supply, ensuring traceability for all dishes and ingredients' },
      { title: 'Standardized Menu with Customized Nutrition', value: 'Our menus are meticulous planned by top-tier nutritionists to ensure balanced nutrition in every meal. The meals are low in salt, sugar, oil, and additives, keeping each day within 2300-2500 calories' },
      { title: 'Exclusive Meal Delivery and Strict Quality Control', value: 'Our meals are prepared to the same high standards as those served in luxury hotels, adhering to strict food hygiene regulations. We deliver our meals to your room in advance-sterilized insulated boxes to ensure that they arrive hot and fresh' },
      { title: 'TCM Remedies and Foods', value: 'Guang He Tang\'s five exclusive-recipe postpartum teas and soups are carefully formulated with natural homologous ingredients from Chinese herbs and foods in golden ratios, tailored to the specific needs of mothers in different postpartum stages. Our postpartum drinks work gently and effectively to restore balance and promote recovery, without any negative side effects' }
    ]
  },
  yrls: {
    title: '', // SIX MEALS A DAY
    list: [
      { title: 'Breakfast', timer: '07:00-08:00', value: 'A quickly absorbed carbohydrate boost to replenish energy depleted overnight' },
      { title: 'Morning Snack', timer: '10:00', value: 'Nutrient-rich ingredients to prevent pre-lunch hunger' },
      { title: 'Lunch', timer: '11:00-12:00', value: 'Provide a comprehensive intake of protein, amino acids, and other essential nutrients' },
      { title: 'Afternoon Snack', timer: '15:00', value: 'Intake of vitamin C and sugar to stimulate hormones with mood-enhancing properties' },
      { title: 'Dinner', timer: '17:00-18:00', value: 'Nutrient-rich, low-calorie meals that are prepared in a way that is gentle on the liver' },
      { title: 'Evening Snack', timer: '20:00', value: 'Provide easily digestible snacks that are low in fat and carbohydrates to help mothers maintain their energy levels and milk supply during midnight breastfeeding sessions' }
    ]
  },
  ght: {
    title: '', // GUANG HE TANG
    value: [
      'Guang He Tang, a 20-year veteran brand specializing in pregnancy and postpartum nourishment, combines traditional Chinese medicine (TCM) and modern nutrition theory to advocate "medicinal food homology and staged dietary supplementation.",During the confinement period, Guang He Tang provides five exclusive postpartum tea drinks and personalized meal arrangements, managed by a dedicated team.',
      'Guang He Tang\'s research and development team, composed of TCM doctors, pharmacists, nutritionists, food engineers, and other professionals, uses natural medicinal and good homologous ingredients in golden ratios to create gentle and safe products.',
      'Guang He Tang\'s weekly postpartum food and nutrition experience offers mothers a chance to sample Guang He Tang products and food therapy products tailored to their individual recovery needs. Nutrition consultants will provide nutritional knowledge and health maintenance advice, while mothers enjoy the nourishment of food during a leisurely afternoon.'
    ]
  },
  ysc: {
    title: '', // HERBAL TEA
    value: [
      'Rose Vitality Tea: Boosts Qi, soothes the liver, and rejuvenates skin complexion.',
      'Body Rejuvenating Tea: Supports digestion, dispels dampness, reduces water retention, and eliminates swelling.',
      'Mulberry & Chrysanthemum Tea: Reduces heat, detoxifies, and soothes intestinal discomforts.',
      'Sacred Wellness Tea: Nourishes the liver and kidneys, enriches the blood, and calms the spirit.'
      // 'Tonic Tea for Weaning Mothers: Relieves engorgement, and effectively suppresses lactation. '
    ]
  }
}

export { cn, tw, en }
