<template>
  <div class="container" :class="{ onShow: showSidebar }" @click="closeSidebar">
    <div class="main" @click.stop>
      <img class="icon-close" :src="`${$store.state.ASSETSURL}images/icon-close.png`" alt="" @click="closeSidebar">
      <h3 class="title">
        <span style="font-family: 'AvenirNext-Medium';">SAINT BELLA</span>
        {{ $t('footer.sidebar.title1') }}
      </h3>
      <div class="store">
        <div class="item hover" v-for="item in $store.getters.getStoreList" :key="item.id">
          <router-link :to="item.url">
            <h4 class="name">{{ item.addressName }}</h4>
            <h4 class="address line">{{ item.addressValue }}</h4>
          </router-link>
        </div>

      </div>
      <h3 class="title">
        <span style="font-family: 'AvenirNext-Medium';">BELLA VILLA</span>
        {{ $t('footer.sidebar.title2') }}
      </h3>
      <div class="content-container">
        <div class="content hover" v-for="item in $store.getters.getHotelList" :key="item.id">
          <router-link :to="item.url">
            <p class="name">{{ item.addressName }}</p>
            <p class="value line">{{ item.addressValue }}</p>
          </router-link>
        </div>
      </div>

    </div>
  </div>
</template>

<script setup>
import { lockScroll, unlockScroll } from '@/util'
import { watchEffect } from 'vue'
import { useStore } from 'vuex'

const store = useStore()

const porps = defineProps(['showSidebar'])
const emit = defineEmits(['setSidebar'])
const closeSidebar = () => emit('setSidebar', false)

// 监听页面组件打开，锁定页面滚动
watchEffect(() => porps.showSidebar ? lockScroll() : unlockScroll())

</script>
<style lang="less" scoped>
.container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  transition: all 1s;
  box-sizing: border-box;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  z-index: 1000;
  overflow: hidden;
  transform: translateX(100%);

  .main {
    width: 600px;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.85);
    box-sizing: border-box;
    padding: 50px 0 50px 50px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }

    .icon-close {
      width: 60px;
      height: 60px;
      margin: 0 50px 0 auto;
      cursor: pointer;
    }

    .title {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-family: STSongti-SC-Bold, STSongti-SC;
      font-weight: bold;
      color: #FFFFFF;
      line-height: 25px;
      margin-top: 10px;

      span {
        font-family: AvenirNext-Medium, AvenirNext;
        margin-right: 6px;
      }
    }

    .store {
      display: flex;
      flex-wrap: wrap;
      margin: 50px 0 80px;

      .item {
        min-width: 200px;
        margin-right: 30px;
        margin-bottom: 30px;

        .name {
          font-size: 14px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 20px;
        }

        .address {
          font-size: 14px;
          font-family: SourceHanSansCN-ExtraLight, SourceHanSansCN;
          font-weight: 200;
          color: #FFFFFF;
          line-height: 20px;
          margin-top: 6px;
        }
      }
    }

    .content-container {
      margin-top: 50px;

      .content {
        margin-bottom: 20px;

        .name {
          font-size: 14px;
          font-family: SourceHanSansCN-Medium, SourceHanSansCN;
          font-weight: 500;
          color: #FFFFFF;
          line-height: 20px;
        }

        .value {
          font-size: 14px;
          font-family: SourceHanSansCN-ExtraLight, SourceHanSansCN;
          font-weight: 200;
          color: #FFFFFF;
          line-height: 20px;
        }
      }
    }
  }

}

.onShow {
  transform: translateX(0);
}

.hover {
  cursor: pointer;

  .line {
    position: relative;
    width: fit-content;

    &::after {
      content: '';
      position: absolute;
      width: 0;
      bottom: -2px;
      left: 0;
      border-bottom: 1px solid #fff;
      transition: all 0.3s;
    }
  }

  &:hover .line::after {
    width: 100%;
  }
}
</style>

<!-- web端样式 -->
<style lang="less" scoped >
@media screen and (max-width: 751px) {
  .container {
    .main {
      width: 100%;
      padding: 70px 0 100px 60px;

      .icon-close {
        width: 80px;
        height: 80px;
        margin-right: 40px;
      }

      .title {
        display: flex;
        flex-wrap: wrap;
        font-size: 36px;
        line-height: 50px;
      }

      .store {
        margin: 80px 0 100px;
        padding-right: 60px;
        justify-content: space-between;

        .item {
          margin-bottom: 50px;

          .name {
            font-size: 28px;
            font-weight: 400;
            line-height: 44px;
          }

          .address {
            font-size: 24px;
            font-weight: 300;
            line-height: 36px;
          }
        }
      }

      .content-container {
        margin-top: 80px;

        .content {
          margin-bottom: 50px;

          .name {
            font-size: 28px;
            font-weight: 400;
            line-height: 44px;
          }
          .value {
            font-size: 24px;
            line-height: 36px;
          }
        }
      }
    }
  }
}
</style>
