// 中文翻译
const cn = {
  banner: {
    title: '贝康国际'
  },
  bkgj: {
    title: '贝康国际',
    value: '贝康国际是一家专注于服务中高产家庭的新型互联网数字家庭护理品牌公司，业务涵盖母婴护理服务、家庭育儿服务、互联网家庭健康管理和职业教育培训等业务板块。旗下的圣贝拉母婴护理中心、小贝拉母婴护理中心为中国极具规模和影响力的中高端直营月子会所品牌，覆盖了全国主要的一二线城市，在此基础上建立包括贝康护理学校和自研大数据分析平台两大支柱中台布局，从培训人才到线上家庭健康数据管理全面覆盖，打造世界级家庭护理生态链。',
    value2: '成立三年即成为准独角兽企业，贝康国际股东包括了上市公司腾讯、新鸿基金融，以及国内顶尖的股权投资机构高榕资本、唐竹资本、浙商健投等。集团目前已完成C轮融资，市值近亿元资金，是国内家庭护理服务的头部品牌。'
  },
  tdyx: {
    title: '投递邮箱',
    value: 'bkoffer@primecare.group'
  },
  tdcs: {
    title: '投递城市',
    value: '新加坡、北京、太原、成都、重庆、长沙、厦门、海口、上海、杭州、宁波、南京、苏州、香港、深圳、广州、珠海、佛山'
  },
  rpgw: {
    title: '热聘岗位',
    value: '店长、销售顾问、服务体验专家、产后修复顾问、市场策划'
  },
  gwjs: '岗位介绍',
  jobs: ['店长', '销售顾问', '服务体验专家', '产后修复顾问', '市场策划'],
  gwzz: '岗位职责',
  rzyq: '任职要求',
  dz: {
    job: [
      '1、业绩产出：保证会所经营目标的实现，及时、足额地完成董事会下达的利润指标；',
      '2、资源协调：沟通协调酒店相关事宜，确认及争取会所最大利益，负责所属地区的医疗 / 商务相关资源的谈判和搭建；',
      '3、会所管理：日常经营治理会所，建立健全会所统一、高效的组织体系和工作体系，主持会所全面工作，包括协调和管理护理部 / 膳食部 / 市场部 / 销售部 / 行政部等各职能部门的日常工作；',
      '4、上下同欲：执行董事会决议，负责下达总部指令，督促并组织实施、检查和绩效考核，拟定会所发展规划、年度经营计划、财务预算方案以及利润分配和亏损弥补方案；',
      '5、品牌维护：开展企业文化建设和凝聚力工程，提高企业知名度，与客户建立良好沟通关系，为公司维护良好的企业品牌形象，避免出现客诉。',
      '6、危机公关：第一时间处理会所出现的客诉情况，给到客户满意的解决方案，避免舆情扩大扩散。'
    ],
    ask: [
      '1、本科及以上学历，市场营销、酒店管理、企业管理等专业优先，年龄在30-45之间，优秀可放宽条件；',
      '2、具有五年以上从事酒店运营-销售、医疗-医美企业、品牌快消品、市场运营等管理工作的经验，有母婴行业或者酒店运营从业经验者优先；',
      '3、具备熟练的外语交流或商务英文邮件撰写能力优先；',
      '4、具备优秀的沟通能力和团队合作精神，组建和培训团队经验丰富，以往销售业绩良好，且有优质的人际资源和开发人际资源能力，适应初创公司工作节奏；',
      '5、有客诉处理经验优先。'
    ]
  },
  xsgw: {
    job: [
      '1、业务指标：熟知会所全面情况、各类报价和服务特色，建立、维护、扩大客户群体，即时回复线上咨询并安排预约参观，提高会所咨询客人的签单率，客户合同签订、定金与尾款收取、押金收取与退回及各类客户相关的文件单据管理工作，完成公司的各项业务指标，定期编制销售报表向直属上级汇报个人业务完成及开展情况；',
      '2、客户维护：定期建立客户追踪档案，保管客户相关的所有合同及档案资料，积极主动与签约客人定期保持沟通，及时了解客人的动态情况，提供相应的健康咨询与关怀，偕同管家部做好孕期售后服务工作，完成会所内订阅号朋友圈的发布，其中包括节点晒合同、晒活动、宝宝和妈妈相片及月子餐，做好朋友圈的维护，定期探访入住客户并做好客户满意度调查；',
      '3、客户回访：每月一次对签约未入住的客户进行电话回访关心、告知孕期的注意事项，了解客人需求，提供相应的保健咨询和指导，如有活动将电话通知，并将通话内容作记录及反馈；对已退住客户生日及宝宝生日进行回访并安排寄送相关礼物，以及重大节日安排短信告知；'
    ],
    ask: [
      '1、大专以上学历，形象气质佳，有高端品牌及销售经验，年龄在26 - 38岁之间；',
      '2、至少具有1年以上销售或客服工作经验，有护理知识优先；',
      '3、具备洞察力、分析判断能力、优秀的表达能力，善于沟通协调；',
      '4、有比较强客户服务意识。'
    ]
  },
  fwtyzj: {
    job: [
      '1、手续办理：协助客户办理入住、退住手续，待客户到房间后介绍入住服务内容、房间设备使用方法、注意事项等，负责与销售了解客户入住时间及备注事项；',
      '2、活动安排：负责入住客户馆内各类课程与活动的日程安排与筹办，活动包括但不限于入住客户满月宴安排（其中包括制作祝福视频和宝宝相册，全程统筹宝宝宴安排及新生儿拍照安排），对已退住客户生日及宝宝生日进行回访并安排寄送相关礼物，以及重大节日安排短信告知等；',
      '3、客户调研：定期探访入住客户并做好客户满意度调查，负责客户投诉处理、听取客户的意见和建议，并与相关部门解决客诉问题；',
      '4、渠道宣传：负责馆内妈妈小红书、大众点评的按量完成，与护理部进行沟通对接，承担每月KPI责任。'
    ],
    ask: [
      '1、大专以上学历，1年工作以上工作经验，形象气质佳，年龄最好在25 - 32岁之间；',
      '2、有爱心，喜欢宝宝，热爱母婴行业，亲和力高、沟通能力强、有同理心和耐心、乐于替客户解决问题；',
      '3、能主动发掘客户需求，开发馆内活动内容，丰富入住客户的馆内生活；',
      '4、与客户保持良好沟通，实时把握客户需求；为客户提供主动、热情、满意、周到的服务；',
      '5、有母婴行业（高端早教，月嫂服务公司、亲子游泳，产后康复等）、月子会所或其他服务行业相关面对面客户服务经验优先。'
    ]
  },
  yhxfgw: {
    job: [
      '1、身体评估：能够为顾客做产后身体初步评估，并结合门店项目给出具体的项目规划；',
      '2、制定计划：建立和维护客户档案，了解客户的产后修复要求，提供有针对性的康复计划和健康指导；',
      '3、专业服务：服务会所宝妈，通过产后修复手法和技术解决宝妈实际产后问题；',
      '4、订单开发：了解并掌握基本孕产知识和公司产康各项目知识，积极配合完成客户再次开发产康项目订单；',
      '5、自我提升：学习使用公司的产康相关产品设备，积极参与公司内外的产康技能培训，提升自己的专业技能。'
    ],
    ask: [
      '1、中专及以上学历，形象气质较好，年龄在20 - 38岁之间，优秀可放宽条件；',
      '2、亲和力强，谈吐得体， 性格开朗，有责任心，有团队合作意识；',
      '3、有良好的语言表达能力和服务意识，具有顾客客情建立及维护能力，高端项目的开发及服务能力；',
      '4、具有熟练的手法，擅长催乳，有产康行业工作经验优先；',
      '5、有相关的专业证件优先，如催乳师证、产后康复师证等。'

    ]
  },
  scch: {
    job: [
      '1、活动策划：能够独立完成日常品牌渠道合作与开发，有品牌渠道合作和线上线下活动举办经验，能独立完成策划案，并依照集团市场的活动指引，从数据增长、客户服务体验及满意度等方面，做好每场活动的落地执行；',
      '2、品牌运营：自媒体账号的文案编辑和内容发布 - 微信／小红书等，做好市场和品牌公关相关资料、图片、录音、录像、题词等收集、整理、记载存档等工作；',
      '3、竞品调研：负责收集各类月子会所竞品调研和典型案例，深入剖析和横向比较，输出有效的市场策略和方案，提升现阶段业务，；',
      '4、市场分析：熟悉当地市场动态，能够及时从线上线下渠道了解和预判市场发展趋势，对现有市场规划做出相应调整。',
      '5、风控管理：注意收集影响品牌形象、声誉、关系的因素和事件信息，分析其后果，及时向公司提出对策建议。'

    ],
    ask: [
      '1、本科及以上学历，至少有2年以上行业工作经验，年龄在26 - 35岁之间；',
      '2、具备较强的判断能力、逻辑思维力、抗压能力、计划执行能力、团队协作能力和客户沟通能力；',
      '3、具有良好的职业道德和丰富的市场营销和公关理论知识，有一定文案功底，会P图和vlog制作加分；；',
      '4、能熟练运用OFFICE办公系统；',
      '5、具有医院、月子会所、奢侈品牌和母婴品牌渠道的优先考虑，有KOL母婴类资源的优先考虑。'
    ]
  }
}
// 繁体翻译
const tw = {
  banner: {
    title: '貝康國際'
  },
  bkgj: {
    title: '貝康國際',
    value: '貝康國際是一家專註於服務中高產家庭的新型互聯網數字家庭護理品牌公司，業務涵蓋母嬰護理服務、家庭育兒服務、互聯網家庭健康管理和職業教育培訓等業務板塊。旗下的聖貝拉母嬰護理中心、小貝拉母嬰護理中心為中國極具規模和影響力的中高端直營月子會所品牌，覆蓋了全國主要的一二線城市，在此基礎上建立包括貝康護理學校和自研大數據分析平臺兩大支柱中臺布局，從培訓人才到線上家庭健康數據管理全面覆蓋，打造世界級家庭護理生態鏈。',
    value2: '成立三年即成為準獨角獸企業，貝康國際股東包括了上市公司騰訊、新鴻基金融，以及國內頂尖的股權投資機構高榕資本、唐竹資本、浙商健投等。集團目前已完成C輪融資，市值近億元資金，是國內家庭護理服務的頭部品牌。'
  },
  tdyx: {
    title: '投遞郵箱',
    value: 'bkoffer@primecare.group'
  },
  tdcs: {
    title: '投遞城市',
    value: '新加坡、北京、太原、成都、重慶、長沙、廈門、海口、上海、杭州、寧波、南京、蘇州、香港、深圳、廣州、珠海、佛山'
  },
  rpgw: {
    title: '熱聘崗位',
    value: '店長、銷售顧問、服務體驗專家、產後修復顧問、市場策劃'
  },
  gwjs: '崗位介紹',
  jobs: ['店長', '銷售顧問', '服務體驗專家', '產後修復顧問', '市場策劃'],
  gwzz: '崗位職責',
  rzyq: '任職要求',
  dz: {
    job: [
      '1、業績產出：保證會所經營目標的實現，及時、足額地完成董事會下達的利潤名額；',
      '2、資源協調：溝通協調飯店相關事宜，確認及爭取會所最大利益，負責所屬地區的醫療/商務相關資源的談判和搭建；',
      '3、會所管理：日常經營治理會所，建立健全會所統一、高效的組織體系和工作體系，主持會所全面工作，包括協調和管理護理部/膳食部/市場部/銷售部/行政部等各職能部門的日常工作；',
      '4、上下同欲：執行董事會決議，負責下達總部指令，督促並組織實施、檢查和績效考核，擬定會所發展規劃、年度經營計畫、財務預算方案以及利潤分配和虧損彌補方案；',
      '5、品牌維護：開展企業文化建設和凝聚力工程，提高企業知名度，與客戶建立良好溝通關係，為公司維護良好的企業品牌形象，避免出現客訴；',
      '6、危機公關：第一時間處理會所出現的客訴情况，給到客戶滿意的解決方案，避免輿情擴大擴散。'
    ],
    ask: [
      '1、本科及以上學歷，市場行銷、酒店管理、企業管理等專業優先，年齡在30-45之間，優秀可放寬條件；',
      '2、具有五年以上從事飯店運營-銷售、醫療-醫美企業、品牌快消品、市場運營等管理工作的經驗，有母嬰行業或者飯店運營從業經驗者優先；',
      '3、具備熟練的外語交流或商務英文郵件撰寫能力優先；',
      '4、具備優秀的溝通能力和團隊合作精神，組建和培訓團隊經驗豐富，以往銷售業績良好，且有優質的人際資源和開發人際資源能力，適應初創公司工作節奏；',
      '5、有客訴處理經驗優先。'
    ]
  },
  xsgw: {
    job: [
      '1、業務名額：熟知會所全面情况、各類報價和服務特色，建立、維護、擴大客戶群體，即時回復線上諮詢並安排預約參觀，提高會所諮詢客人的簽單率，客戶合同簽訂、定金與尾款收取、押金收取與退回及各類客戶相關的檔案單據管理工作，完成公司的各項業務名額， 定期編制銷售報表向直屬上級彙報個人業務完成及開展情况；',
      '2、客戶維護：定期建立客戶追跡檔案，保管客戶相關的所有契约及檔案資料，積極主動與簽約客人定期保持溝通，及時瞭解客人的動態情况，提供相應的健康諮詢與關懷，偕同管家部做好孕期售後服務工作，完成會所內訂閱號朋友圈的發佈，其中包括節點曬契约、曬活動、寶寶和媽媽相片及月子餐， 做好朋友圈的維護，定期探訪入住客戶並做好客戶滿意度調查；',
      '3、客戶回訪：每月一次對簽約未入住的客戶進行電話回訪關心、告知孕期的注意事項，瞭解客人需求，提供相應的保健諮詢和指導，如有活動將電話通知，並將通話內容作記錄及迴響； 對已退住客戶生日及寶寶生日進行回訪並安排寄送相關禮物，以及重大節日安排簡訊告知；'
    ],
    ask: [
      '1、大專以上學歷，形象氣質佳，有高端品牌及銷售經驗，年齡在26 - 38歲之間；',
      '2、至少具有1年以上銷售或客服工作經驗，有護理知識優先；',
      '3、具備洞察力、分析判斷能力、優秀的表達能力，善於溝通協調；',
      '4、有比較强客戶服務意識。'
    ]
  },
  fwtyzj: {
    job: [
      '1、手續辦理：協助客戶辦理入住、退住手續，待客戶到房間後介紹入住服務內容、房間設備使用方法、注意事項等，負責與銷售瞭解客戶入住時間及備註事項；',
      '2、活動安排：負責入住客戶館內各類課程與活動的排程與籌辦，活動包括但不限於入住客戶滿月宴安排（其中包括製作祝福視頻和寶寶相册，全程統籌寶寶宴安排及新生兒拍照安排），對已退住客戶生日及寶寶生日進行回訪並安排寄送相關禮物，以及重大節日安排簡訊告知等；',
      '3、客戶調研：定期探訪入住客戶並做好客戶滿意度調查，負責客戶投訴處理、聽取客戶的意見和建議，並與相關部門解决客訴問題；',
      '4、通路文宣：負責館內媽媽小紅書、大眾點評的按量完成，與護理部進行溝通對接，承擔每月KPI責任。'
    ],
    ask: [
      '1、大專以上學歷，1年工作以上工作經驗，形象氣質佳，年齡最好在25 - 32歲之間；',
      '2、有愛心，喜歡寶寶，熱愛母嬰行業，親和力高、溝通能力强、有同理心和耐心、樂於替客戶解决問題；',
      '3、能主動發掘客戶需求，開發館內活動內容，豐富入住客戶的館內生活；',
      '4、與客戶保持良好溝通，實时把握客戶需求； 為客戶提供主動、熱情、滿意、周到的服務；',
      '5、有母嬰行業（高端早教，月嫂服務公司、親子游泳，產後康復等）、月子會所或其他服務行業相關面對面客戶服務經驗優先。'
    ]
  },
  yhxfgw: {
    job: [
      '1、身體評估：能够為顧客做產後身體初步評估，並結合門店項目給出具體的專案規劃；',
      '2、製定計畫：建立和維護客戶檔案，瞭解客戶的產後修復要求，提供有針對性的康復計畫和健康指導；',
      '3、專業服務：服務會所寶媽，通過產後修復手法和科技解决寶媽實際產後問題；',
      '4、訂單開發：瞭解並掌握基本孕產知識和公司產康各項目知識，積極配合完成客戶再次開發產康項目訂單；',
      '5、自我提升：學習使用公司的產康相關產品設備，積極參與公司內外的產康技能培訓，提升自己的專業技能。'
    ],
    ask: [
      '1、高職及以上學歷，形象氣質較好，年齡在20 - 38歲之間，優秀可放寬條件；',
      '2、親和力強，談吐得體，性格開朗，有責任心，有團隊合作意識；',
      '3、有良好的語言表達能力和服務意識，具有顧客客情建立及維護能力，高端項目的開發及服務能力；',
      '4、具有熟練的手法，擅長催乳，有產康行業工作經驗優先；',
      '5、有相關的專業證件優先，如催乳師證、產後康復師證等。'
    ]
  },
  scch: {
    job: [
      '1、活動策劃：能够獨立完成日常品牌通路合作與開發，有品牌通路合作和線上線下活動舉辦經驗，能獨立完成策劃案，並依照集團市場的活動指引，從數據增長、客戶服務體驗及滿意度等方面，做好每場活動的落地執行；',
      '2、品牌運營：自媒體帳號的文案編輯和內容發佈-微信／小紅書等，做好市場和品牌公關相關資料、圖片、錄音、錄影、題詞等收集、整理、記載存檔等工作；',
      '3、競品調研：負責收集各類月子會所競品調研和典型案例，深入剖析和橫向比較，輸出有效的市場策略和方案，提升現階段業務；',
      '4、市場分析：熟悉當地市場動態，能够及時從線上線下通路瞭解和預判市場發展趨勢，對現有市場規劃做出相應調整；',
      '5、風控管理：注意收集影響品牌形象、聲譽、關係的因素和事件資訊，分析其後果，及時向公司提出對策建議。'
    ],
    ask: [
      '1、本科及以上學歷，至少有2年以上行業工作經驗，年齡在26 - 35歲之間；',
      '2、具備較强的判斷能力、邏輯思維力、抗壓能力、計畫執行能力、團隊合作能力和客戶溝通能力；',
      '3、具有良好的職業道德和豐富的市場行銷和公關理論知識，有一定文案功底，會P圖和vlog製作加分；',
      '4、能熟練運用OFFICE辦公系統；',
      '5、具有醫院、月子會所、奢侈品牌和母嬰品牌通路的優先考慮，有KOL母嬰類資源的優先考慮。'
    ]
  }
}
// 英文翻译
const en = {
  banner: {
    title: '' // 贝康国际
  },
  bkgj: {
    title: '', // 贝康国际
    value: 'PRIMECARE INTERNATIONAL is a leading digital family care brand that focuses on serving middle to high-income households. Its business encompasses various sectors, including maternity and baby care services, family parenting services, web-based home health management, and professional development education and training. Under its umbrella, PRIMECARE INTERNATIONAL operates two high-end postpartum care brands, SAINT BELLA Maternity And Baby Care Centre and Baby BELLA Maternity And Baby Care Centre with significant presence and substantial influence in China covering all major first- and second-tier cities across the country. Building upon this foundation, PRIMECARE INTERNATIONAL established two pillars as its major plays in China and Taiwan comprised of the PRIMECARE SCHOOL and a proprietary data management and analytics platform. These pillars comprehensively span talent development and web-based family health data management, aiming to create a world-class family care ecosystem.',
    value2: 'Within just three years of its establishment, PRIMECARE INTERNATIONAL has risen to become a quasi-unicorn company. Its shareholders include publicly listed companies such as Tencent and Everbright Sun Hung Kai (formerly known as Sun Hung Kai Financial), as well as top Chinese equity investment firms like Gaorong Capital, Tangzhu Capital, and Zheshang Venture Capital, among others. Having successfully completed a Series C financing round, the company now boasts a valuation approaching 100 million USD, making it the leading brand in China’s family care services sector.'
  },
  tdyx: {
    title: 'Post Box',
    value: 'bkoffer@primecare.group'
  },
  tdcs: {
    title: 'Job Locations',
    value: 'Singapore、Beijing, Taiyuan, Chengdu, Chongqing, Changsha, Xiamen, Haikou, Shanghai, Hangzhou, Ningbo, Nanjing, Suzhou, Hong Kong, Shenzhen, Guangzhou, Zhuhai, Foshan'
  },
  rpgw: {
    title: 'Hot Job Positions',
    value: 'Store Manager, Sales Consultant, Service Experience Specialist, Postpartum Recovery Consultant, Marketing Planner'
  },
  gwjs: 'Job Introduction',
  jobs: ['Store Manager', 'Sales Consultant', 'Guest Experience Specialist', 'Postpartum Wellness and Recovery Consultant', 'Marketing Executive'],
  gwzz: 'Job Responsibilities',
  rzyq: 'Job Requirements',
  dz: {
    job: [
      '1. Overseeing the centre’s financial performance：Ensure the achievement of operating goals of centre, promptly and fully meet revenue targets assigned by the board of directors;',
      '2. Resource Coordination：Communicate and coordinate with hotels on related matters to identify and advocate for centre\'s interests. Responsible for leveraging and establishing medical/business-related resources in the region;',
      '3. Centre Management：Daily governance of centre operations. Establish a unified and highly efficient organizational and work system for the centre. Setting strategic goals for the overall work of the centre, including coordinating, and managing the daily tasks of various functional departments such as nursing, catering, marketing, sales, and administration;',
      '4. Active communications with Headquarters：Implement resolutions from the board of directors, responsible for implementing headquarters overall vision and mission, supervise and organize the implementation of works and inspections as well as performance assessment, and formulate centre\'s development plans, annual business plans, financial budget plans, profit distribution, and loss compensation plans;',
      '5. Brand Maintenance：Work to develop corporate culture and cohesion, enhance corporate visibility, establish good communication with customers, maintain a good corporate brand image, and minimize customer complaints;',
      '6. Proactive Crisis Management：Address customer complaints in real time, provide resolutions to mitigate negative publicity.'
    ],
    ask: [
      '1. Bachelor\'s degree or higher, with major in marketing, hotel management, or business management. Preferred age between 30 and 45. Requirements would be relaxed for exceptional candidates;',
      '2. Possess more than five years of management experience in hotel operation, sales, medical/medical aesthetics company, branded fast-moving consumer goods, or marketing operation. Prior experience in the maternity and infant industry or hotel operation is preferred;',
      '3. Proficiency in foreign language communication or business writing in English is preferred;',
      '4. Possess excellent communication skill, teamwork spirit, and experience in team building and training. Good past sales records and able to manage and develop interpersonal resources. Could adapt to the pace of work in a start-up company;',
      '5. Experience in handling customer complaints is preferred.'
    ]
  },
  xsgw: {
    job: [
      '1. Business Targets：Familiarize yourself with the centre\'s overall situation, various quotations, and service features. Establish, maintain, and expand customer clusters, respond promptly to online inquiries, and arrange appointments for centre visits, improve the sign-up rate of customers. Handle signing of customer contract, collect advance payment and arrears, collect and refund deposit, and manage various customer-related documents and bills. Achieve various business targets of the company and regularly prepare sales charts to report to superiors the completion of one\'s work and how it is done;',
      '2. Customer Maintenance：Regularly establish customer tracking records, maintain all customer-related contracts and case materials, actively keep up regular communication with signed-up customers, understand in time customers\' doings, provide relevant health consultations and care, accompany the Butler Service Department to ensure good after-sales services during pregnancy terms, and carry out publications in centre\'s subscribed accounts on WeChat Moments, including displaying key milestones like seasonal events, baby and mother photos, and postpartum meals. Visit live-in customers regularly and ensure customer satisfaction surveys are done properly;',
      '3. Customer Follow-Up：Conduct once-a-month telephone follow-ups with signed-up but not-yet-checked-in customers to show care, inform them of pregnancy-related matters, and understand customer needs. Provide corresponding health consultations and guidance. If there are any upcoming activities, notify customers by phone and record the content of the call and feedback. On birthdays of customers who have been discharged and of their babies, follow up with calls and arrange to send them related gifts, and send message to inform customers of all major festive events.'
    ],
    ask: [
      '1. College/University degree or above, good appearance, with luxury brand and sales experience, age between 26 and 38 years;',
      '2. At least one year of sales or customer service experience. Knowledge of nursing is a plus;',
      '3. Possess observation skill, capable in analysing and forming judgement, excellent communications, and coordination skills;',
      '4. Committed to providing excellent customer service.'
    ]
  },
  fwtyzj: {
    job: [
      '1. Procedure Handling：Assist customers with check-in and check-out procedures. Introduce live-in service contents, demonstrate the use of room equipment, and highlight procedures to ensure a memorable stay. Responsible for communicating with sales to understand customer check-in times and any special requests to ensure a smooth check in;',
      '2. Event Arrangement：Responsible for scheduling and preparing various lessons and activities for customers. Activities include but are not limited to the full-month celebration b for live-in customers (including making videos and baby photo albums, overall planning of the full month celebration, and arrangement for new-born photography), sending birthday greetings to past customers on their birthday and baby\'s birthday, and sending relevant gifts on major holidays, and message to inform them upcoming events on major festive days;',
      '3. Customer Surveys：Conduct regular visits to live-in customers and perform customer satisfaction surveys. Deal with customer complaints, listen to customer opinions and suggestions, and provide resolutions with relevant departments;',
      '4. Promoting on Social Media Channels：Responsible for achieving the quota posted in the centre\'s subscribed accounts in Xiaohongshu and Dianping. Link up and communicate with the nursing department and be responsible for monthly KPIs.'
    ],
    ask: [
      '1. College degree or above, with at least one year of work experience, good appearance, age between 25 and 32 years;',
      '2. Compassionate, fond of babies, passionate about the maternity and infant industry, very friendly, good communication skills, empathetic, patient, and eager to solve problems for customers;',
      '3. Could proactively identify customer needs, develop contents for in-house activities, and enrich the lives of live-in customers in the centre;',
      '4. Maintain effective communication with customers, understand their needs in real time, and actively provide them with enthusiastic, satisfactory and thoughtful services;',
      '5. Preferably, prior experience in face-to-face customer service in the maternity and infant industry (high-end early education, wet nurse service companies, parent attachment swimming, postpartum rehabilitation, etc.).'
    ]
  },
  yhxfgw: {
    job: [
      '1. Physical Assessment：Conduct preliminary postpartum body assessments for customers and provide tailored plans for recovery programme, tying in with the centre’s offerings;',
      '2. Plan and customize individual needs：establish and maintain customer profiles, understand customers\' postpartum recovery requirements, and provide targeted recovery plans and health guidance;',
      '3. Professional Services：Serve mums in the centre by resolving actual postpartum issues using postpartum recovery techniques and skills;',
      '4. Postpartum Recovery Plan Development：Gain and grasp basic knowledge of pregnancy and childbirth as well as the company\'s various postpartum recovery programmes. Actively cooperate to re-develop postpartum recovery programme orders for clients;',
      '5. Self-Improvement：Learn to use the company\'s postpartum recovery-related products and equipment. Actively participate in both internal and external postpartum recovery skill training to enhance personal professional skills.'
    ],
    ask: [
      '1. Vocational school training or higher, with a good appearance. Age between 20 and 38. Requirements would be relaxed for outstanding candidates;',
      '2. Very friendly and well-mannered speaker, cheerful personality, a sense of responsibility, and strong belief in teamwork;',
      '3. Excellent communication skills and customer centric, with the ability to establish and maintain customer relationships. Able to develop and provide services for high-end programmes;',
      '4. Proficient in techniques, particularly skilled in lactation stimulation. Prior experience in the postpartum recovery industry is preferred;',
      '5. Relevant professional certifications are a plus, such as Certified Lactation Consultant (CLC) or Certified Postpartum Recovery Specialist.'

    ]
  },
  scch: {
    job: [
      '1. Event Planning：Could independently complete daily brand channel cooperation and development, with experience in brand channel cooperation and organizing online and offline activities. Could independently carry through planned activities, with every activity aspect well implemented and executed ranging from data growth, customer service experience to customer satisfaction, following the marketing activity guidance of the Group',
      '2. Brand Operation：Edit writings and publish contents for self-media accounts, including WeChat and Xiaohongshu. Proficient in collecting, organizing, and filing materials, pictures, audio recordings, videos, inscriptions, etc., related to market and brand public relations;',
      '3. Competitor Investigation：Collect rival products from various postpartum care centres and typical cases, analyse and compare them in-depth, and provide effective market strategies and plans to enhance current business;',
      '4. Market Analysis：Familiar with local market dynamics and able to understand and predict market trends in time from online and offline channels. Adjust current market planning accordingly;',
      '5. Risk Management：Pay attention to factors and event information that may affect brand image, reputation, and relationships, analyse the consequences, and propose timely countermeasures to the company.'

    ],
    ask: [
      '1. Bachelor\'s degree or higher, with at least 2 years of work experience in the industry. Age between 26 and 35;',
      '2. Quite strong in forming good judgements, capable of logical thinking, stress tolerance , ability to plan and execute, strengths in teamwork and customer communication skills;',
      '3. Possess excellent professional ethics, rich knowledge of marketing and public relations theories, certain degree of copywriting skill, and ability to edit images and produce vlog is a plus;',
      '4. Proficiency in using the MS Office suite;',
      '5. Candidates with hospital, maternity care institution, luxury brand, and maternal and child brand channel experience would be considered first, and candidates with KOL maternal and child resources are also preferred.'
    ]
  }
}
export { cn, tw, en }
