<template>
  <div class="app-box" :class="isShowCustomer ? 'app-box-hidden' : ''">
    <router-view />
    <Feedback ref='FeedBackRef' />
    <div class="customer" @click.stop="handleToShowCustomer">
      <div class="customer_icon">
        <img class="" :src="`${$store.state.ASSETSURL}images/customer.png`" alt="">
      </div>
    </div>
    <div v-if="isShowCustomer" class="customer-box" @click="isShowCustomer = false">
      <div class="customer-ewm" @click.stop>
        <img :src="`${$store.state.ASSETSURL}images/customer-sbl.png`" alt="" >
      </div>
      <div class="button-box">
        <div class="button" :class="{ close: true, blackBg: !$store.state.headerTheme.textColor }"
             @click="isShowCustomer = false">
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { onBeforeMount, onMounted, ref, provide } from 'vue'
import { useStore } from 'vuex'
import { Feedback } from '@/components'
import { setLang, setCountry, storage } from '@/util'
import { _getAwIpLocation, _getCountryList } from '@/api'
const store = useStore()
const FeedBackRef = ref()
// const hide = ref(false)

const isShowCustomer = ref(false)

onBeforeMount(async () => {
  // 清空缓存
  if (storage('serviceMode') === null && storage('country') !== null) {
    storage('clear')
  }
  // hide.value = true
  const { list: countryList = [] } = await _getCountryList()
  const { data: ipInfo = {} } = await _getAwIpLocation()
  storage('CountryList', JSON.stringify(countryList))
  storage('IpInfo', JSON.stringify(ipInfo))
  // 根据ip选中的信息
  let ipCheckCountry = null
  if (ipInfo.areacode === 'CN') {
    // 中国仅用二字国家无法匹配省份，所以要单独使用name来匹配
    // 查询后台是否有匹配的中国省名 后台设置名字与接口返回不一致会导致无法正确匹配
    ipCheckCountry = countryList.find(v => v.name === ipInfo.prov)
    if (!ipCheckCountry) {
      // 后台没有配置 默认使用中国大陆信息
      ipCheckCountry = countryList.find(v => v.identifier === 'CN')
    }
  } else {
    // 查询后台是否有匹配的国家 使用国家二字代码匹配
    ipCheckCountry = countryList.find(v => v.identifier === ipInfo.areacode)
    if (!ipCheckCountry) {
      // 后台没有配置 默认使用新加坡信息
      ipCheckCountry = countryList.find(v => v.identifier === 'SG')
    }
  }
  const locale = storage('locale')
  const country = storage('country')
  if ((!locale || !country) && ipCheckCountry) {
    // 设置语言
    setLang(ipCheckCountry.defaultLanguage)
    // 设置国家地区
    setCountry(ipCheckCountry.identifier)
    // 缓存客服能力
    storage('serviceMode', ipCheckCountry.serviceMode)
    window.location.reload()
  } else {
    // hide.value = false
  }
})

onMounted(() => {
  document.dispatchEvent(new Event('custom-render-trigger'))
  store.dispatch('init')
})

provide('FeedBackRef', FeedBackRef)

const handleToShowCustomer = () => {
  const customerType = store.getters.getCustomerType
  if (customerType === 0) {
    _YUNQUE.openDialog()
  } else {
    isShowCustomer.value = true
  }
}

</script>

<style lang="less">
@import './assets/css/index.css';
@import 'nprogress/nprogress.css';

#app {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  user-select: none;
  .app-box-hidden {
    height: 100vh;
    overflow: hidden;
  }
}

#yunque_btn-holder {
  display: none !important;
}

.customer {
  background: #1C1E44;
  width: 60px;
  height: 60px;
  position: fixed;
  right: 10px;
  bottom: 10px;
  z-index: 8;
  border-radius: 30px;

  .customer_icon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 30px;
      height: 30px;
    }
  }
}
.customer-box {
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  top: 0;
  background: rgba(0,0,0,.5);
  z-index: 999999;
  .customer-ewm {
    width: 570px;
    height: 399px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .button-box {
    position: absolute;
    width: 40px;
    height: 40px;
    top: 79px;
    right: 69px;

    .button {
      width: 40px;
      height: 40px;
      position: absolute;
      z-index: 999;
      cursor: pointer;
      box-sizing: border-box;

      .line {
        width: 40px;
        // height: 1Px;
        border-top: 1px solid #fff;
        background-color: #fff;
        position: absolute;
        transition: all 0.2s;

        &:nth-child(1) {
          left: 0;
          top: 14px;
        }

        &:nth-child(2) {
          top: 20px;
        }

        &:nth-child(3) {
          top: 26px;
          right: 0;
        }
      }

      &:hover .line {
        &:nth-child(1) {
          top: 12px;
        }

        &:nth-child(2) {
          top: 20px;
        }

        &:nth-child(3) {
          top: 28px;
        }
      }

    }

    .blackBg {
      .line {
        border-top-color: #000;
      }
    }

    .close {
      // position: absolute;
      // right: 0;
      // z-index: 999;

      .line {
        border-top-color: #fff;

        &:nth-child(1) {
          top: 20px;
          width: 20px;
          transform-origin: right bottom;
          transform: rotateZ(45deg);
        }

        &:nth-child(2) {
          top: 20px;
          transform: rotateZ(-45deg);
        }

        &:nth-child(3) {
          top: 20px;
          width: 20px;
          transform-origin: left bottom;
          transform: rotateZ(45deg);
        }
      }

      &:hover .line {
        &:nth-child(1) {
          top: 20px;
        }

        &:nth-child(2) {
          top: 20px;
        }

        &:nth-child(3) {
          top: 20px;
        }
      }
    }

  }
}
</style>
