import { createI18n } from 'vue-i18n'
import { language as _language } from './config'
import { langMerge, valueLang } from '@/util'
import { CN as langCn, EN as langEn, TW as langTw } from './language'
import { CountryLang } from './country'

// 准备翻译的语言环境信息
const messages = {
  'zh-CN': { ...langCn },
  'zh-TW': { ...langTw },
  'en-GB': { ...langEn }
}

// 国家配置语言优先于默认语言 国家语言会覆盖默认语言
langMerge(CountryLang, messages)

const i18n = createI18n({
  locale: localStorage.getItem('locale') || 'zh-CN', // 设置地区
  legacy: false,
  messages // 设置地区信息
})

const language = _language
// 当前版本拥有的国家 由后台配置
const country = JSON.parse(localStorage.getItem('CountryList') || '[]').map(v => ({
  title: v[valueLang('name')],
  value: v.identifier
}))

// console.log('country', country)
export { language, country }

export default i18n
