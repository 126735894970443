// 当前版本支持的国家由后台配置
// 当前版本支持的语言前端写死
//  key   国家语言代码
//  value 通常用于字段后面的后缀 例如：接口返回字段 title_en  国家自定义字段 title_cn
//  getLangValue 获取当前语言的value值   valueLang 根据当前语言拼接字段的后缀
export const language = [
  { title: '简', value: 'cn', key: 'zh-CN' },
  { title: '繁', value: 'tw', key: 'zh-TW' },
  { title: 'English', value: 'en', key: 'en-GB' }
]
