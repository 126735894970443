import i18n from '@/i18n'
import { getCountryLang } from '@/util'
import store from '@/store'
const $t = i18n.global.t
const meun = [
  {
    enTitle: 'BRAND CENTER',
    cnTitle: $t('header.brand.title'),
    url: '/brand',
    child: [
      { name: $t('header.brand.ppgs'), url: '/brand/story' },
      { name: $t('header.brand.ppsj'), url: '/brand/moment' },
      { name: $t('header.brand.mxzx'), url: '/brand/star' },
      { name: $t('header.brand.xwzx'), url: '/brand/news' }
    ]
  },
  {
    enTitle: 'FIND A STORE',
    cnTitle: $t('header.store.title'),
    url: '/store',
    lang: true,
    child: [],
    child2: [],
    child3: [],
    child4: []
  },
  {
    enTitle: 'PROFESSIONAL CARE',
    cnTitle: $t('header.zyhh.title'),
    url: '/major-care',
    child: [
      { name: getCountryLang('content1'), url: '/major-care/24hours#24h护士一对一' },
      { name: $t('header.zyhh.hlms'), url: '/major-care/24hours#护理模式' },
      { name: $t('header.zyhh.mmhh'), url: '/major-care/24hours#妈妈呵护' },
      { name: $t('header.zyhh.bbhh'), url: '/major-care/24hours#宝宝呵护' },

      { name: $t('header.zyhh.ycqzqfw'), url: '/major-care/cycle-service' },
      { name: $t('header.zyhh.ysly'), url: '/major-care/art-convalesce' }
    ]
  },
  {
    enTitle: 'DINING EXPERIENCE',
    cnTitle: $t('header.yzss.title'),
    url: '/month-food',
    child: [
      { name: $t('header.yzss.sjdsstl'), url: '/month-food#四阶段膳食调理' },
      { name: $t('header.yzss.wdssyz'), url: '/month-food#五大膳食原则' }
    ]
  },
  {
    enTitle: 'POSTPARTUM REPAIR',
    cnTitle: $t('header.chxf.title'),
    url: '/postpartum-repair',
    child: [
      { name: $t('header.chxf.chyxs'), url: '/postpartum-repair#产后研修所' },
      { name: $t('header.chxf.zycp'), url: '/postpartum-repair#自研产品' }
    ]
  }
]

export default meun
