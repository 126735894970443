<template>
  <!-- 顶部固定 -->
  <header class="header" :style="{ backgroundColor: showMeun ? 'transparent' : $store.state.headerTheme.bgColor }">
    <h1 class="logo_title">
      <router-link to="/">
        <img class="logo_img" v-if="logo !== null && $store.state.headerTheme.logo"
          :src="`${$store.state.ASSETSURL}images/logo_${$store.state.headerTheme.logo}.png`" alt="圣贝拉母婴护理中心" />
        <img class="logo_img" v-else-if="logo" :src="`${$store.state.ASSETSURL}images/logo_${logo}.png`"
          alt="圣贝拉母婴护理中心" />
      </router-link>
    </h1>

    <ul class="language" :style="{ color: $store.state.headerTheme.textColor ? '#fff' : '#000' }">
      <template v-for="(lang, index) in language" :key="lang.key">
        <li @click="setLanguage(lang.key)">
          <a class="hover-opacity6">{{ lang.title }}</a>
        </li>
        <li v-if="index + 1 < language.length">|</li>
      </template>
      <li class="button-box">
        <div class="button" :class="{ close: showMeun, blackBg: !$store.state.headerTheme.textColor }"
          @click='changeMenuStatus'>
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </li>

      <li class="menu-block" :class="{ 'menu-block-show': showMeun }">
        <img class="bgImage" :src="`${$store.state.ASSETSURL}images/header/bgImage.png`">
        <div class="mask"></div>
        <div class="meun-main">
          <div class="meun-header">
            <router-link to="/">
              <h2 class="h2" @click="onClickUrl()"><img :src="`${$store.state.ASSETSURL}images/logo_white.png`"></h2>
            </router-link>

            <p class="language">
              <template v-for="(lang, index) in language" :key="lang.key">
                <span class="hover-opacity6" @click="setLanguage(lang.key)">
                  {{ lang.title }}
                </span>
                <span class=" line" v-if="index + 1 < language.length">|</span>
              </template>
            </p>
          </div>
          <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index">
              <h4 class="enTitle" v-if="!langHide()">{{ item.enTitle }}</h4>
              <router-link :to="item.url" @click="onClickUrl()">
                <h4 class="cnTitle">{{ item.cnTitle }}</h4>
              </router-link>
              <div class="line"></div>
              <div class="name-block">
                <div class="block">

                  <div class="child" v-for="meun in item.child" :key="meun.name">
                    <router-link :to="meun.url" @click="onClickUrl()">
                      {{ meun.name }}
                    </router-link>
                  </div>

                </div>
                <div class="block" v-if="item.child2">
                  <div class="child" v-for="meun in item.child2" :key="meun.name">
                    <router-link :to="meun.url" @click="onClickUrl()">
                      {{ meun.name }}
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bottom">
            <span class="text">Copyright © 2016-2022 All Right Reserved</span>
          </div>
        </div>

      </li>
    </ul>
  </header>
</template>

<script setup>
import { langHide } from '@/util'
import { language } from '@/i18n'

// eslint-disable-next-line no-undef, vue/valid-define-props
defineProps(['list', 'showMeun'])

// eslint-disable-next-line no-undef
const emits = defineEmits(['onChange', 'setLang', 'onClick'])

// 设置语言
const setLanguage = (lang) => {
  emits('setLang', lang)
}

// 展开控制方法
const changeMenuStatus = () => {
  emits('onChange')
}

// 通过冒泡捕获点击事件  延迟防止跳转与动画重叠
const onClickUrl = () => {
  emits('onClick')
}
</script>

<script>
export default {
  props: {
    logo: {
      type: String || null,
      default: '' // white || black
    }
  }
}
</script>

<style lang="less" scoped>
.hover {
  &-opacity6 {
    transition: all 0.5s;
    cursor: pointer;

    &:hover {
      opacity: .6;
    }
  }
}

.header {
  width: 100%;
  height: 88px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s;

  .logo_title {
    margin-left: 30px;
    width: 162px;
    height: 42px;
    transition: all 0.3s;

    .logo_black {
      display: none;
    }

    .logo_img {
      width: 100%;
      height: 100%;
    }

    .logo_white {
      opacity: 0;
    }

    .hidden_logo {
      opacity: 0;
    }
  }

  >.language {
    margin-right: 60px;
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #fff;

    li {
      margin: 0 5px;
      color: inherit;

      a {
        color: inherit;
        font-weight: 300;
        font-family: PingFangSC-Light;
        position: relative;
        font-size: 24px;
        line-height: 44px;
        transition: all 0.5s;
      }
    }

    .button-box {
      position: relative;
      width: 40px;
      height: 40px;
      margin-left: 20px;

      .button {
        width: 40px;
        height: 40px;
        position: absolute;
        z-index: 999;
        cursor: pointer;
        box-sizing: border-box;

        .line {
          width: 40px;
          // height: 1Px;
          border-top: 1px solid #fff;
          background-color: #fff;
          position: absolute;
          transition: all 0.2s;

          &:nth-child(1) {
            left: 0;
            top: 14px;
          }

          &:nth-child(2) {
            top: 20px;
          }

          &:nth-child(3) {
            top: 26px;
            right: 0;
          }
        }

        &:hover .line {
          &:nth-child(1) {
            top: 12px;
          }

          &:nth-child(2) {
            top: 20px;
          }

          &:nth-child(3) {
            top: 28px;
          }
        }

      }

      .blackBg {
        .line {
          border-top-color: #000;
        }
      }

      .close {
        // position: absolute;
        // right: 0;
        // z-index: 999;

        .line {
          border-top-color: #fff;

          &:nth-child(1) {
            top: 20px;
            width: 20px;
            transform-origin: right bottom;
            transform: rotateZ(45deg);
          }

          &:nth-child(2) {
            top: 20px;
            transform: rotateZ(-45deg);
          }

          &:nth-child(3) {
            top: 20px;
            width: 20px;
            transform-origin: left bottom;
            transform: rotateZ(45deg);
          }
        }

        &:hover .line {
          &:nth-child(1) {
            top: 20px;
          }

          &:nth-child(2) {
            top: 20px;
          }

          &:nth-child(3) {
            top: 20px;
          }
        }
      }

    }

    .menu-block {
      width: 100vw;
      height: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      transition: all 0.3s;

      &-show {
        height: 100vh;
      }

      .bgImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.7;
        top: 0;
        left: 0;
      }

      .meun-main {
        position: absolute;
        overflow: scroll;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        padding: 60px 0 0 60px;
        box-sizing: border-box;

        .meun-header {
          display: flex;
          width: 100%;
          justify-content: space-between;
          padding: 24px 60px 0 0;
          box-sizing: border-box;

          .h2 {
            width: 238px;
            height: 62px;

            img {
              width: 100%;
            }
          }

          >.language {
            font-size: 22px;
            font-family: SourceHanSansCN-Light;
            font-weight: 300;
            color: #FFFFFF;
            line-height: 20px;

            .line {
              margin: 0 12px;
            }
          }
        }

        .list {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          // margin: 60px auto 0;
          .item {
            width: 100%;
            margin-top: 80px;

            // margin-right: 100px;
            &:last-child {
              margin-right: 0;
            }

            .enTitle {
              font-size: 20px;
              line-height: 29px;
              font-family: Branch-Regular;
              font-weight: 400;
              color: #FFFFFF;
              letter-spacing: 1px;
            }

            .cnTitle {
              font-size: 36px;
              font-family: STSongti-SC-Bold;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 50px;
              letter-spacing: 2px;
              margin-top: 6px;
              width: fit-content;
              position: relative;

              &::after {
                transition: all 0.5s;
                content: '';
                position: absolute;
                height: 1px;
                bottom: -2px;
                left: 0;
                background-color: #fff;
                width: 0;
              }

              &:hover::after {
                color: #fff;
                width: 100%;
              }
            }

            .line {
              width: 100%;
              height: 1px;
              background: #FFFFFF;
              opacity: 0.3;
              margin-top: 26px;
            }

            .name-block {
              display: flex;
              margin-top: 18px;

              .block {

                .child {
                  font-size: 12px;
                  font-family: SourceHanSansCN-Regular;
                  font-weight: 400;
                  color: #CCCCCC;
                  line-height: 20px;
                  margin-top: 14px;
                  position: relative;
                  width: fit-content;

                  &::after {
                    transition: all 0.5s;
                    content: '';
                    position: absolute;
                    height: 1px;
                    bottom: -2px;
                    left: 0;
                    background-color: #fff;
                    width: 0;
                  }

                  &:hover::after {
                    width: 100%;
                  }

                  &:hover {
                    color: #fff;
                  }
                }

                &:nth-child(2) .child {
                  margin-left: 62px;
                }
              }
            }
          }
        }

        .bottom {
          margin-top: 125px;
          display: flex;
          justify-content: space-between;

          .text {
            font-size: 22px;
            font-family: SourceHanSansCN-ExtraLight;
            font-weight: 200;
            color: #FFFFFF;
            line-height: 33px;
            letter-spacing: 2px;
            margin-bottom: 60px;
          }
        }
      }
    }
  }
}

.text_color_white {
  color: #fff !important;
}

.text_color_black {
  color: #000 !important;
}
</style>
