// 中文翻译
const cn = {
  banner: {
    title: '24小时护士一对一',
    subTitle: '拥有护士资格证，经ACI美国母婴护理师资格培训'
  },
  hlms: {
    title: '护理模式',
    subTitle: '24小时护士一对一',
    subDesc: ['圣贝拉坚持24小时护士一对一的科学护理模式，采用100%护士团队，每一位都经过ACI美国母婴护理师资格培训，拥有国内护士资格证书。', '月子期间为每对母婴配备2名护士，为其提供24小时护士一对一不间断的照护，用近乎奢侈的人员配置避免服务断层。'],
    endTitle: ['360°贴身细致的服务', '量身定制个性化护理方案'],
    myts: '母婴同室',
    mytsDesc: [
      '圣贝拉秉承西尔斯的亲密育儿理念，贯彻“24小时母婴同室”的科学护理理念，让“初来乍到”的宝宝能够时刻感到妈妈的温暖，也让初为人母的妈妈能尽情体会新生命带来的悸动与柔软。',
      '在圣贝拉母婴同室的28天，得到的不仅是专业周到的服务，更能让每一位新手妈妈快速熟悉与宝宝的科学相处模式以及科学育儿的方式。'
    ]
  },
  mom: {
    title: '妈妈护理',
    zyhl: {
      title: '专业护理',
      desc: ['24小时护士1对1产妇照护', '入馆身体健康评估', '产褥期妈妈健康动态信息观测与评估', '(体温、心跳、血压、伤口)', '产褥期腹部及伤口常规护理', '(侧切伤口护理、剖腹伤口照护重点)', '产褥期恶露观察及常规护理'],
      desc2: ['产褥期会阴部常规护理', '子宫复旧观察与按摩', '爱丁堡产后抑郁量表评估', '产后抑郁心理疏导', '教导执行凯格尔式运动', '离馆身体健康评估']
    },
    zjzx: {
      title: '1v1专家咨询',
      desc: ['资深专家定期1v1健康咨询']
    },
    rfhl: {
      title: '乳房护理',
      desc: ['提供专业乳房护理', '每日乳房健康评估', '乳房日常护理及宣导', '个性化母乳喂养方案', '正确母乳喂养姿势指导', 'IBCLC国际认证泌乳顾问服务']
    },
    yycytl: {
      title: '营养餐饮调理',
      desc: ['高功效四阶段三餐三点月子餐', '五星级酒店主厨团队现食现做质量保障食材安全', '每日提供养生月子饮'],
      desc2: ['资深营养师定期1对1膳食咨询', '定制体质调理方案']
    },
    shfw: {
      title: '生活服务',
      desc: ['客服管家多对一生活起居协助服务', '产妇哺乳服杀消清洗及宝宝衣物手洗服务', '每日房间清洁与消毒'],
      desc2: ['每日护士夜床服务', '护士中药擦身及足浴服务', '沉浸式艺术美学活动']
    },
    ysly: {
      title: '艺术疗养',
      desc: ['BELLA Arts沉浸式艺术疗愈', '音乐疗愈、艺史赏析', '漫谈影记、夜读诗选']
    },
    cwmq: {
      title: '「成为母亲」系列讲座',
      desc: ['早早教课堂', '亲子互动课程', '新生儿常见护理问题', '母乳喂养课程', '月子里常见问题']
    },
    yzxj: {
      title: '28 天月子宣教',
      tips: '根据妈妈的恢复情况及宝宝生长状态，分阶段式护理宣教<br>采用“零”距离实践搭配护理操作实时讲解，让新手妈妈在28天内快速掌握育儿技巧。',
      one: {
        title: '第一周',
        desc: ['预防产妇跌倒与婴儿跌落', '尿片显湿线及记录方法', '正确含乳和摆位', '拍背排气的方法和注意事项', '哺乳枕使用介绍', '奶粉冲泡方式和瓶喂技巧'],
        desc2: ['哺乳时婴儿的衣着和保暖', '会阴伤口与恶露量的评估', '黄疸的注意事项和处理', '乳头疼痛及皲裂处理', '如何掌握母乳哺喂时间']
      },
      two: {
        title: '第二周',
        desc: ['束腹带使用注意事项', '如何判断婴儿是否吃到奶水', '溢奶与吐奶处理方式', '胀奶的处理及排乳技巧'],
        desc2: ['母乳的收集与储存', '奶瓶的消毒方法', '教导执行凯格尔式运动']
      },
      three: {
        title: '第三周',
        desc: ['使用安抚奶嘴的注意事项', '乳腺管阻塞及乳腺炎的处理方式', '配方奶的婴儿该如何增加奶量'],
        desc2: ['婴儿行为状态的传递讯息', '婴儿体温监测', '药物与母乳喂养的关系']
      },
      four: {
        title: '实操指南',
        desc: ['婴儿肠绞痛和安抚方法', '婴儿穿脱衣技巧', '婴儿游泳'],
        desc2: ['婴儿洗臀、晾臀操作', '婴儿沐浴', '婴儿抚触']
      }
    },
    mrfw: {
      title: 'IBCLC 泌乳服务',
      header: ['圣贝拉泌乳服务', '传统泌乳服务'],
      key: ['人员资质', '操作原理', '泌乳功效 ', '体验感受', '专业指导', '适用时间'],
      value1: ['专业泌乳顾问<br>持 IBCLC 国际证书', '以解剖学原理<br>对特定组织进行提拉、剥离', '及时发现乳房深层问题<br>易于乳汁疏通婴儿吸吮', '全程无痛', '完善记录+系统跟踪<br>每日关注乳房变化+指导含接姿势', '产后即可开始<br>持续护理整个哺乳期'],
      value2: ['通常为月嫂<br>部分无催乳师执照', '在乳房硬块部分按摩', '无科学理论支撑<br>易对乳腺造成隐性损伤', '剧痛难忍', '/', '/']
    }
  },
  baby: {
    title: '宝宝护理',
    zyhl: {
      title: '专业护理',
      desc: ['24 小时一对一母婴照护', '入馆身体健康评估', '新生儿每日成长曲线及动态观察（Online）', '新生儿黄疸观察及护理', '新生儿肠绞痛护理', '新生儿脐部及生殖器官护理', '新生儿红臀状况评估及护理措施', '新生儿眼、耳、鼻、口护理措施'],
      desc2: ['新生儿皮肤状况评估及护理', '新生儿拍嗝、拍背、排气等护理照顾', '新生儿消化、大小便观察及护理', '新生儿行为状态(睡眠&警觉)的传递讯息观察', '新生儿每日沐浴', '采用IAIM国际婴儿抚触方法', '护理长、组长每日进房健康咨询', '离馆身体健康评估']
    },
    zjzx: {
      title: '1v1专家咨询',
      desc: ['资深专家定期1v1健康咨询']
    },
    tszh: {
      title: '特殊照护',
      desc: ['双胞胎护理', '低体重儿（矫正周数已满36周）']
    },
    kxwy: {
      title: '科学喂养',
      desc: ['喂养方式选择：母乳喂养、人工喂养、混合喂养', '正确喂养姿势及知识指导', '提供个性化喂养方案'],
      desc2: ['依照新生儿饥饿线索-按需喂养方案', '母乳收集及存放指导']
    },
    zzjxl: {
      title: '早早教训练',
      desc: ['新生儿定期游泳', '新生儿俯卧抬头训练', '新生儿音乐听力训练'],
      desc2: ['新生儿黑白卡视觉训练', '新生儿KMC袋鼠护理触觉训练']
    },
    zhtx: {
      title: '先进科学的差异化照护体系',
      keys: ['科学喂养', '袋鼠式护理', '黄疸护理', '肠绞痛护理', '脐部护理', '坐姿拍嗝', '竖抱方式', '趴玩时间', '宝宝游泳'],
      values: ['根据妈妈的乳房类型及宝宝成长阶段，定制精细化喂养方案。', '通过母婴肌肤接触的方式，稳定宝宝的体温、心跳及情绪，增加母乳亲喂成(KMC)功率，满足妈妈和宝宝的心理需求。', '区别于传统方式(茵栀黄、晒太阳)，倡导多吃多排。', '5S安抚法(Swaddling-Side or Stomach-Shushing-Swinging-Sucking)', '遵循世界卫生组织(WHO)倡导的自然干燥法。 ', '让宝宝坐在腿上，用一手支撑其头颈部、脸颊，不对喉部施压，另一手轻拍或按摩背部，将宝宝的肚子和背保持直立，将嗝排出。', '美国儿科学会(AAP)强调任何月龄的宝宝都可以竖抱，但是一定要确保头、颈椎、脊椎在同一垂直水平线上。', '源于美国儿科学会(AAP)提出的“Back to sleep，Tummy to play"倡导在宝宝清醒的时候，尽量让宝宝趴着玩，促进宝宝觉、运动、感官的协同发展，同时助于预防早期运动迟缓。', '区别于颈圈，采用腋下圈，避免宝宝颈椎损伤、窒息、休克险。']
    }
  }
}
// 繁体翻译
const tw = {
  banner: {
    title: '24小時護士一對一',
    subTitle: '擁有護士資格證，經ACI美國母嬰護理師資格培訓'
  },
  hlms: {
    title: '護理模式',
    subTitle: '24小時護士一對一',
    subDesc: ['聖貝拉堅持24小時護士一對一的科學護理模式，采用100%護士團隊，每一位都經過ACI美國母嬰護理師資格培訓，擁有國內護士資格證書。', '月子期間為每對母嬰配備2名護士，為其提供24小時護士一對一不間斷的照護，用近乎奢侈的人員配置避免服務斷層。'],
    endTitle: ['360°貼身細致的服務，', '量身定製個性化護理方案。'],
    myts: '母嬰同室',
    mytsDesc: [
      '聖貝拉秉承西爾斯的親密育兒理念，貫徹「24小時母嬰同室」的科學護理理念，讓「初來乍到」的寶寶能夠時刻感到媽媽的溫暖，也讓初為人母的媽媽能盡情體會新生命帶來的悸動與柔軟。',
      '在聖貝拉母嬰同室的28天，得到的不僅是專業周到的服務，更能讓每一位新手媽媽快速熟悉與寶寶的科學相處模式以及科學育兒的方式。'
    ]
  },
  mom: {
    title: '媽媽護理',
    zyhl: {
      title: '專業護理',
      desc: ['24小時護士1對1產婦照護', '入館身體健康評估', '產褥期媽媽健康動態信息觀測與評估', '(體溫、心跳、血壓、傷口)', '產褥期腹部及傷口常規護理', '(側切傷口護理、剖腹傷口照護重點)', '產褥期惡露觀察及常規護理'],
      desc2: ['產褥期會陰部常規護理', '子宮復舊觀察與按摩', '愛丁堡產後抑郁量表評估', '產後抑郁心理疏導', '教導執行凱格爾式運動', '離館身體健康評估']
    },
    zjzx: {
      title: '1v1專家咨詢',
      desc: ['資深專家定期1v1健康咨詢']
    },
    rfhl: {
      title: '乳房護理',
      desc: ['提供專業乳房護理', '每日乳房健康評估', '乳房日常護理及宣導', '個性化母乳餵養方案', '正確母乳餵養姿勢指導', 'IBCLC國際認證泌乳顧問服務']
    },
    yycytl: {
      title: '營養餐飲調理',
      desc: ['高功效四階段三餐三點月子餐', '五星級酒店主廚團隊現食現做質量保障食材安全', '每日提供養生月子飲'],
      desc2: ['資深營養師定期1對1膳食咨詢', '定製體質調理方案']
    },
    shfw: {
      title: '生活服務',
      desc: ['客服管家多對一生活起居協助服務', '產婦哺乳服殺消清洗及寶寶衣物手洗服務', '每日房間清潔與消毒'],
      desc2: ['每日護士夜床服務', '護士中藥擦身及足浴服務', '沈浸式藝術美學活動']
    },
    ysly: {
      title: '藝術療養',
      desc: ['BELLA Arts沈浸式藝術療愈', '音樂療愈、藝史賞析', '漫談影記、夜讀詩選']
    },
    cwmq: {
      title: '「成為母親」系列講座',
      desc: ['早早教課堂', '親子互動課程', '新生兒常見護理問題', '母乳餵養課程', '月子裏常見問題']
    },
    yzxj: {
      title: '28 天月子宣教',
      tips: '根據媽媽的恢復情況及寶寶生長狀態，分階段式護理宣教<br>采用「零」距離實踐搭配護理操作實時講解，讓新手媽媽在28天內快速掌握育兒技巧。',
      one: {
        title: '第一周',
        desc: ['預防產婦跌倒與嬰兒跌落', '尿片顯濕線及記錄方法', '正確含乳和擺位', '拍背排氣的方法和註意事項', '哺乳枕使用介紹', '奶粉沖泡方式和瓶餵技巧'],
        desc2: ['哺乳時嬰兒的衣著和保暖', '會陰傷口與惡露量的評估', '黃疸的註意事項和處理', '乳頭疼痛及皸裂處理', '如何掌握母乳哺餵時間']
      },
      two: {
        title: '第二周',
        desc: ['束腹帶使用註意事項', '如何判斷嬰兒是否吃到奶水', '溢奶與吐奶處理方式', '脹奶的處理及排乳技巧'],
        desc2: ['母乳的收集與儲存', '奶瓶的消毒方法', '教導執行凱格爾式運動']
      },
      three: {
        title: '第三周',
        desc: ['使用安撫奶嘴的註意事項', '乳腺管阻塞及乳腺炎的處理方式', '配方奶的嬰兒該如何增加奶量 '],
        desc2: ['嬰兒行為狀態的傳遞訊息', '嬰兒體溫監測', '藥物與母乳餵養的關系']
      },
      four: {
        title: '實操指南',
        desc: ['嬰兒腸絞痛和安撫方法', '嬰兒穿脫衣技巧', '嬰兒遊泳'],
        desc2: ['嬰兒洗臀、晾臀操作', '嬰兒沐浴', '嬰兒撫觸']
      }
    },
    mrfw: {
      title: 'IBCLC 泌乳服務',
      header: ['聖貝拉泌乳服務', '傳統泌乳服務'],
      key: ['人員資質', '操作原理', '泌乳功效', '體驗感受', '專業指導', '適用時間'],
      value1: ['專業泌乳顧問<br>持 IBCLC 國際證書', '以解剖學原理<br>對特定組織進行提拉、剝離', '及時發現乳房深層問題<br>易於乳汁疏通嬰兒吸吮', '全程無痛', '完善記錄+系統跟蹤<br>每日關註乳房變化+指導含接姿勢', '產後即可開始<br>持續護理整個哺乳期'],
      value2: ['通常為月嫂<br>部分無催乳師執照', '在乳房硬塊部分按摩', '無科學理論支撐<br>易對乳腺造成隱性損傷', '劇痛難忍', '/', '/']
    }
  },
  baby: {
    title: '寶寶護理',
    zyhl: {
      title: '專業護理',
      desc: ['24 小時一對一母嬰照護', '入館身體健康評估', '新生兒每日成長曲線及動態觀察（Online）', '新生兒黃疸觀察及護理', '新生兒腸絞痛護理', '新生兒臍部及生殖器官護理', '新生兒紅臀狀況評估及護理措施', '新生兒眼、耳、鼻、口護理措施'],
      desc2: ['新生兒皮膚狀況評估及護理', '新生兒拍嗝、拍背、排氣等護理照顧', '新生兒消化、大小便觀察及護理', '新生兒行為狀態(睡眠&警覺)的傳遞訊息觀察', '新生兒每日沐浴', '采用IAIM國際嬰兒撫觸方法', '護理長、組長每日進房健康咨詢', '離館身體健康評估']
    },
    zjzx: {
      title: '1v1專家咨詢',
      desc: ['資深專家定期1v1健康咨詢']
    },
    tszh: {
      title: '特殊照護',
      desc: ['雙胞胎護理', '低體重兒（矯正周數已滿36周）']
    },
    kxwy: {
      title: '科學餵養',
      desc: ['餵養方式選擇：母乳餵養、人工餵養、混合餵養', '正確餵養姿勢及知識指導', '提供個性化餵養方案'],
      desc2: ['依照新生兒饑餓線索-按需餵養方案', '母乳收集及存放指導']
    },
    zzjxl: {
      title: '早早教訓練',
      desc: ['新生兒定期遊泳', '新生兒俯臥擡頭訓練', '新生兒音樂聽力訓練'],
      desc2: ['新生兒黑白卡視覺訓練', '新生兒KMC袋鼠護理觸覺訓練']
    },
    zhtx: {
      title: '先進科學的差異化照護體系',
      keys: ['科學餵養', '袋鼠式護理', '黃疸護理', '腸絞痛護理', '臍部護理', '坐姿拍嗝', '豎抱方式 ', '趴玩時間', '寶寶遊泳'],
      values: ['根據媽媽的乳房類型及寶寶成長階段，定製精細化餵養方案。', '通過母嬰肌膚接觸的方式，穩定寶寶的體溫、心跳及情緒，增加母乳親餵成(KMC)功率，滿足媽媽和寶寶的心理需求。', '區別於傳統方式(茵梔黃、曬太陽)，倡導多吃多排。', '5S安撫法(Swaddling-Side or Stomach-Shushing-Swinging-Sucking)', '遵循世界衛生組織(WHO)倡導的自然幹燥法。', '讓寶寶坐在腿上，用一手支撐其頭頸部、臉頰，不對喉部施壓，另一手輕拍或按摩背部，將寶寶的肚子和背保持直立，將嗝排出。', '美國兒科學會(AAP)強調任何月齡的寶寶都可以豎抱，但是一定要確保頭、頸椎、脊椎在同一垂直水平線上。', '源於美國兒科學會(AAP)提出的「Back to sleep，Tummy to play"倡導在寶寶清醒的時候，盡量讓寶寶趴著玩，促進寶寶視覺、運動、感官的協同發展，同時助於預防早期運動遲緩。', '區別於頸圈，採用腋下圈，避免寶寶頸椎損傷、窒息、休克險。']
    }
  }
}

// 英文翻译
const en = {
  banner: {
    title: '', // 24-HOUR NURSE ONE ON ONE
    subTitle: 'Hold a nurse qualification certificate and<br/>have completed ACI American Maternal and Child Care Qualification Training.'
  },
  hlms: {
    title: '', // NURSING MODE
    subTitle: '', // 24-HOUR NURSE ONE-ON-ONE
    subDesc: ['SAINT BELLA upholds a 24-hour one-on-one scientific nursing model and deploys an entirely nurse-composed team. The team of nurses have all undergone ACI American Maternal and Child Care Qualification Training and hold a local nurse qualification certificate.', 'During the confinement period, two nurses are assigned to each mother-infant pair to ensure 24hr round the clock, one-on-one nursing care. Using this nearly generous staff allocation is to provide the best care possible and to prevent any potential service interruption.  '],
    endTitle: ['360° Thoughtful And Well-rounded Service', 'Customized And Personalized Care Plans '],
    myts: 'Mother And Baby Rooming-In Approach',
    mytsDesc: [
      'Upholding Sears\' attachment parenting philosophy, SAINT BELLA implements the scientific nursing concept of "24-hour mother-infant rooming-in". This allows the new-borns to always feel the warmth of their mothers while enabling first-time mothers to fully experience the tremendous joy and tenderness that a new life brings.',
      'During their 28-day stay at SAINT BELLA, mothers and babies will room together and receive professional and thoughtful round-the-clock care from a team of experienced nurses. New mothers will also have the opportunity to learn about the latest scientific methods for bonding with their babies and caring for them.'
    ]
  },
  mom: {
    title: '', // MOM CARE
    zyhl: {
      title: '', // PROFESSIONAL NURSING
      desc: ['Comprehensive 24/7 Care for Mothers by nurses', 'Health Assessment upon Arrival', 'Real-time Monitoring: Throughout your postpartum journey, we closely monitor and assess dynamic vital signs', '(such as body temperature, heart rate, blood pressure, and wound healing.)', 'Specialized care for abdominal and incision wounds', '(paying special attention to care for episiotomy and caesarean section incisions)', 'Lochia Management: Our attentive staff observes and manages postpartum lochia discharge'],
      desc2: ['Perineal Care: routine perineal care during postpartum period', 'Uterine Involution: We monitor uterine recovery and provide soothing massages', 'Edinburgh Postnatal Depression Scale Assessment', 'Postpartum Psychological Counselling', 'Instructions on proper Kegel exercises', 'Health Assessment upon Leaving Centre']
    },
    zjzx: {
      title: '', // 1V1 EXPERT CONSULTATION
      desc: ['Routine one-on-one health consultations by senior obstetricians and health experts']
    },
    rfhl: {
      title: '', // BREAST CARE
      desc: ['Professional Breast Care ', 'Daily Breast Health Assessment', 'Routine Breast Care and Education', 'Personalized Breastfeeding Plan', 'Guidance for Proper Breastfeeding Positions', 'IBCLC International Certified Lactation Consultant Service']
    },
    yycytl: {
      title: '', // 营养餐饮调理
      desc: ['Nutritious and meticulously curated postpartum cuisine for each phase of postpartum recovery ', 'Three meals and three snacks are prepared fresh daily by our five-star hotel culinary team', 'Nourishing postpartum drinks provided daily'],
      desc2: ['Regular one-on-one dietary consultations with our senior nutritionists', 'Customized dietary plans for optimal postpartum recovery']
    },
    shfw: {
      title: '', // 生活服务
      desc: ['Butler service to assist with mother\'s daily living', 'Disinfection and cleaning services for breastfeeding attire and hand-washing service for baby\'s clothes', 'Daily housekeeping and disinfection'],
      desc2: ['Nightly nurse turndown service', 'Nurse-Assisted Traditional Chinese Medicine (TCM) Body Rub and Foot Bath', 'Immersive Mommy\'s Workshops']
    },
    ysly: {
      title: '', // 艺术疗养
      desc: ['BELLA Arts Immersive Art Therapy', 'Engage in music therapy and art history education', 'Indulge in movie appreciation and poetry reading']
    },
    cwmq: {
      title: '', // 「成为母亲」系列讲座
      desc: ['Early Education for Infants', 'Parent-Child Interactive Courses', 'New-born Care and Education', 'Breastfeeding Classes', 'Common Postpartum Concerns']
    },
    yzxj: {
      title: '', // 28 天月子宣教
      tips: 'Postpartum care and education is split into phases based on mother\'s recovery rate and the baby\'s growth<br>using hands-on practice and real-time explanations of nursing operations at "zero" distance to enable new mothers to quickly master parenting skills in 28 days.',
      one: {
        title: 'Week 1',
        desc: ['Prevent New Mothers and Babies from Falling', 'Tracking and Recording of Wet and Soiled Diapers', 'Proper Latching and Positions', 'Burping Techniques and What to Look Out For', 'Introducing the Use of Breastfeeding Pillows ', 'Formula Preparation and Bottle Feeding Techniques '],
        desc2: ['Baby\'s Proper Clothing and Ideal Temperature While Breastfeeding ', 'Perineal Incision and Lochia Assessment', 'Jaundice Awareness and Management', 'Nipple Pain and Cracking Management', 'Mastering Breastfeeding Schedule']
      },
      two: {
        title: 'Week 2',
        desc: ['Proper Use of Belly Bands', 'Evaluating whether Infant Feeding Amount is Adequate', 'Ways to deal with Spit-Up and Regurgitation', 'Handling Engorgement and Relief Techniques'],
        desc2: ['Expressing and Storing Breast Milk', 'Bottle Sterilization Techniques', 'Proper Kegel Exercises to Strengthen Pelvic Floor']
      },
      three: {
        title: 'Week 3',
        desc: ['The Pros and Cons of Pacifier', 'Ways to Manage Blocked Milk Ducts and Mastitis', 'Formula Feeding: How Much and How Oftern'],
        desc2: ['Interpreting Cues from Infant Behaviours', 'Monitoring Infant Temperature', 'The safety and efficacy of medications on Breastfeeding']
      },
      four: {
        title: 'Practical Guidelines',
        desc: ['Infant Colic and Soothing Techniques', 'Dressing and Un-dressing Infant Techniques', 'Infant Swimming'],
        desc2: ['How to Wash and Dry Infant\'s Bottom', 'Infant Bathing', 'Infant Massage']
      }
    },
    mrfw: {
      title: '', // IBCLC 泌乳服务
      header: ['SAINT BELLA Lactation Consultation', 'Traditional Lactation Consultation'],
      key: ['Qualifications', 'How to Principles', 'Effectiveness', 'Experience', 'Professional Guidance', 'Application Time'],
      value1: ['Professional Lactation Consultants<br>holding IBCLC Certificates', 'Anatomy-based approach:<br>use a combination of tissue manoeuvring techniques to relieve engorgement', 'Timely detection of breast issues to improve milk flow and to relieve pain', 'Painless', 'Comprehensive records and systematic tracking/monitoring of changes<br> in breast and guidance on latching techniques', 'Starts immediately after birth<br>and continues throughout the entire breastfeeding journey'],
      value2: ['Confinement Nannies<br>some of whom do not have lactation specialisation credentials', 'Massaging where the breast hardens', 'May use methods lacking scientific support', 'Extreme discomfort', '/', '/']
    }
  },
  baby: {
    title: '', // BABY CARE
    zyhl: {
      title: '', // PROFESSIONAL NURSING
      desc: ['24-Hour One-on-One Exclusive Mother and Baby Care', 'New-born Physical Assessment upon Admission', 'Daily Tracking of Infant Growth Curve and Continuing Observation of New-borns（Online）', 'Jaundice Level Monitoring in New-borns with Corresponding Nursing Measures', 'New-born Colic Care', 'Nursing Care for New-born Umbilical Cord and Genital Organs', 'Assessment of New-born diaper rash and corresponding nursing measures', '(nasal cavity, oral cavity, etc.)'],
      desc2: ['Assessment and Nursing Care of Newborn Skin Condition', 'Newborn Care Techniques Such as Burping, Patting, and Gas Discharge', 'Newborn Digestive System, Urination, and Bowel Movement Observation and Nursing Care', 'Observation of Newborn Cues via Behavioral States (Sleep and Alertness)', 'Newborn Bath', 'IAIM Infant Massage', 'Daily Room Visit and Health Consultation by Chief Nurse and Care Team Leaders ', 'Physical Health Assessment Upon Discharge']
    },
    zjzx: {
      title: '', // 1V1 EXPERT CONSULTATION
      desc: ['Routine 1v1 Health Consultation with Senior Experts']
    },
    tszh: {
      title: '', // SPECIAL CARE
      desc: ['Care for Twins', 'Low Birth Weight Infants (Corrected gestational age over 36 weeks)']
    },
    kxwy: {
      title: '', // SCIENTIFIC FEEDING
      desc: ['Feeding Method Selection: Breastfeeding, Artificial Feeding, Mixed Feeding', 'Proper Feeding Posture and Guidance', 'Personalized Feeding Plan Provided'],
      desc2: ['Support for On-Demand Feeding Plan to Follow Infant Hunger Cues', 'Guidance on Breast Milk Collection and Proper Storage']
    },
    zzjxl: {
      title: '', // EARLY INFANT EDUCATION TRAINING
      desc: ['Regular Swimming Sessions for New-borns', 'Prone Posture Head Lifting Training for New-borns', 'New-born Music Listening Training'],
      desc2: ['New-born Black and White Card Visual Stimulation Training', 'New-born Kangaroo Mother Care (KMC) Tactile Sensory Training']
    },
    zhtx: {
      title: '', // ADVANCED CARE SYSTEM BACKED BY SCIENCE
      keys: ['Scientific Feeding', 'Kangaroo Mother Care (KMC)', 'Jaundice Care', 'Colic Care', 'Umbilical Cord Care', 'Burping in an Upright Position', 'Carrying in an Upright Position', 'Tummy Time', 'Baby Swimming'],
      values: ['Refined customized feeding plans based on the mother\'s breast type and the baby\'s growth stage.', 'Stabilizing the baby\'s temperature, heart rate, and emotions through skin-to-skin contact between the mother and baby, increasing Kangaroo Mother Care (KMC) effectiveness, and meeting the psychological needs of both mother and baby.', 'Promoting increased feeding and bowel movements, as opposed to traditional methods such as sun exposure or herbal remedies.  ', 'Utilizing the 5S Soothing Method (Swaddling, Side or Stomach, Shushing, Swinging, Sucking).', 'Comply with the World Health Organization\'s (WHO) recommended natural drying method. ', 'Supporting the baby\'s neck and jaw with one hand while gently patting or massaging the back with the other hand to enable release of gas.', 'The American Academy of Paediatrics (AAP) emphasizes that babies of any age can be carried in an upright position, but must make sure that their head, neck, and spine are aligned along a vertical axis.', 'Originating from the American Academy of Paediatrics (AAP) initiative “Back to Sleep, Tummy to Play”, it encourages placing the babies on their stomach for them to frolic when they are awake. This promotes coordinated development in the baby\'s vision, movement, and sensory perception while helping to prevent motor delays at this early developmental stage.', 'Use an armpit ring instead of a neck ring to avoid potential risks such as cervical spine injury, choking, and shock.']
    }
  }
}

export { cn, tw, en }
