import { createApp } from 'vue'
import MetaInfo from 'vue-meta-info'
import BaiduMap from 'vue-baidu-map-3x'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import 'animate.css'
import './util/rem.js'
import '@/assets/font/font.css'

const app = createApp(App)

app.use(i18n).use(store).use(router).use(MetaInfo).use(BaiduMap, {
  ak: process.env.VUE_APP_BAIDUCLIENTAk
}).mount('#app')
