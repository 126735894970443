/* eslint-disable no-undef */
import { _getWxJssdkConfig } from '@/api'
import { ref } from 'vue'

// 默认分享信息
const shareUrl = ref('')
// 是否更新成功分享参数
const isUpdate = ref(false)
// 重新更新次数
let NUM_SETUPDATE = 0
// 需要使用的微信api列表
const jsApiList = ['checkJsApi', 'updateAppMessageShareData']

// 获取签名参数
const getWxConfig = async (url) => {
  shareUrl.value = url
  _getWxJssdkConfig({ url }).then(res => {
    // 获取配置成功，设置注入参数
    setWxConfig(res)
  }).catch(err => {
    console.log('【微信参数获取失败】', err)
  })
}

// 注册微信签名
const setWxConfig = async (config) => {
  wx.config({
    debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    appId: config.appId, // 必填，公众号的唯一标识
    timestamp: config.timestamp, // 必填，生成签名的时间戳
    nonceStr: config.nonceStr, // 必填，生成签名的随机串
    signature: config.signature, // 必填，签名
    jsApiList // 必填，需要使用的JS接口列表
  })

  // 初始化默认值
  wx.ready(function () {
    // 设置默认分享信息
    wx.updateAppMessageShareData({
      title: '圣贝拉母婴护理中心',
      desc: shareUrl.value,
      link: shareUrl.value,
      success: (res) => { isUpdate.value = true }
    })
  })

  wx.error(function (res) {
    console.log('【微信jssdk注册失败】', res)
  })
}

/**
 * 更新微信中分享信息
 * @param {string} title 分享标题
 * @param {string} desc  分享描述  默认为当前页地址
 * @param {string} link  分享连接  默认为当前页地址
 */
const updateAppMessageShareData = (title, desc = shareUrl.value, link = shareUrl.value) => {
  if (isUpdate.value === false) {
    // 防止被默认参数覆盖, 请求10次
    ++NUM_SETUPDATE
    if (NUM_SETUPDATE < 10) setTimeout(updateAppMessageShareData.bind(this, title, desc, link), 1000)
  } else {
    // 初始化回去
    isUpdate.value = false
    NUM_SETUPDATE = 0
    // 更新分享参数
    wx.updateAppMessageShareData({ title, desc, link, imgUrl: '' })
  }
}

export {
  updateAppMessageShareData as updateShareDate,
  getWxConfig as updateWxConfig
}
