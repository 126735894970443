<template>
  <HeaderWeb v-if="$store.state.isMobile" :showMeun="showMeun" @onChange="changeMenuStatus" @setLang="setLanguage"
    @onClick="onClickUrl" :list="list" />
  <HeaderPc v-else :showMeun="showMeun" @onChange="changeMenuStatus" @setLang="setLanguage" @setCountry="setCountry"
    @onClick="onClickUrl" :list="list" :countryState="countryState" />
</template>

<script setup>
import HeaderWeb from './index.web.vue'
import HeaderPc from './index.pc.vue'
import { country } from '@/i18n'
import { ref, reactive, computed, watchEffect, onMounted } from 'vue'
import meunList from './meunData'
import { useNav, lockScroll, unlockScroll, getCountry } from '@/util'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

// eslint-disable-next-line no-undef, vue/valid-define-props
const props = defineProps(['hiddenCountry'])

const route = useRouter()
const store = useStore()
// 设置语言
const setLanguage = (language) => {
  localStorage.setItem('locale', language)
  useNav(0, 'go')
}
// 设置国家
const setCountry = (country) => {
  localStorage.setItem('country', country)
  useNav(0, 'go')
}
// 国家信息
const countryState = reactive({
  curCountry: [{}],
  otherCountry: []
})
onMounted(() => {
  if (!props.hiddenCountry) {
    countryState.curCountry = country.filter(v => v.value === getCountry())
  } else {
    countryState.curCountry = []
  }
  countryState.otherCountry = country.filter(v => v.value !== getCountry())
})
// 菜单栏数据
const list = computed(() => {
  const value = meunList
  value[1].child = store.getters.getStoreListDate.storeLeftList
  value[1].child2 = store.getters.getStoreListDate.storeRightList
  value[1].child3 = store.getters.getStoreListDate.hotelLeftList
  value[1].child4 = store.getters.getStoreListDate.hotelRightList
  return value
})

// 是否展开
const showMeun = ref(false)

// 展开控制方法
const changeMenuStatus = () => {
  showMeun.value = !showMeun.value
}

// 刷新加入微任务防止原地跳转
route.beforeEach((to, form) => {
  if (to.path === form.path && form.path === '/store/detail') {
    setTimeout(() => useNav(0, 'go'))
  }
})
// 通过冒泡捕获点击事件  延迟防止跳转与动画重叠
const onClickUrl = () => {
  setTimeout(() => (showMeun.value = false), 100)
}
// 弹框打开自动锁定页面滚动
watchEffect(() => showMeun.value ? lockScroll() : unlockScroll())
</script>
