
/**
 * path  浏览器访问路径
 * url   页面指向路径 index可以忽略
 * child 子页面信息
 * meta
 *   backScroll  返回上一级，是否固定定位到上次浏览位置
 */
const router = [
  {
    path: '/', // 首页
    url: '/home/index',
    meta: {
      backScroll: true
    },
    name: 'home'
  },
  {
    path: '/brand', // 品牌中心
    meta: {
      backScroll: true
    },
    child: [
      {
        path: '/star' // 品牌中心-明星之选
      },
      {
        path: '/moment' // 品牌中心-品牌瞬间
      },
      {
        path: '/news', // 品牌中心-新闻中心
        child: [
          {
            path: '/intro'
          },
          {
            path: '/minne'
          },
          {
            path: '/danny'
          }
        ]
      },
      {
        path: '/story' // 品牌中心-品牌故事
      }
    ]
  },
  {
    path: '/store', // 门店探索-首页
    child: [

      {
        path: '/detail' // 门店探索-详情
      }
    ]
  },
  {
    path: '/major-care', // 专业呵护
    meta: {
      backScroll: true
    },
    child: [
      {
        path: '/art-convalesce', // 专业呵护-艺术疗养
        meta: {
          backScroll: true
        }
      },
      {
        path: '/24hours' // 专业呵护-24小时1v1

      },
      {
        path: '/cycle-service' // LTV孕产全周期服务
      },
      {
        path: '/join-manual' // 妈妈入住指南
      },
      {
        path: '/timer'
      }
    ]
  },
  {
    path: '/month-food', // 月子膳食
    meta: {
      backScroll: true
    }
  },
  {
    path: '/postpartum-repair', // 产后修复
    meta: {
      backScroll: true
    }
  },
  {
    path: '/vip' // 产后修复
  },
  {
    path: '/school' // 贝康护理学校
  },
  {
    path: '/yujia' // 予家育婴师
  },
  {
    path: '/job-hunt' // 工作机会
  },
  {
    path: '/feedback' // 工作机会
  }
]

const pages = []
const createPages = (list, dir = '') => {
  pages.unshift(list.map(i => {
    if (i.child) createPages(i.child, dir + i.path)
    const path = `${dir}${i.path}`
    const name = i.name || path.substring(1)
    const component = () => import(`@/views${i.url || `${path}${!dir ? '/index' : ''}`}.vue`)
    return { path, name, component, meta: i.meta }
  }))
  return pages
}

export default createPages(router).reduce((pre, cur) => [...pre, ...cur], [])
