// 中文翻译
const cn = {
  banner: { title: '予家' },
  ppgs: {
    title: '品牌故事',
    subTitle: '呵护新生成长',
    desc: '予家是贝康集团孵化的驻家母婴服务品牌，通过科学培训体系让予家育婴师掌握标准化、专业化、人性化护理技能，致力于让新生代家庭享受更美好的健康护理服务。'
  },
  fwln: {
    title: '服务理念', value: ['予家用心融入家庭，为不同的家庭，设计不同的呵护方案，让您在呵护新生的过程中更踏实，更从容。呵护新生，予家传递着“女性帮助女性“的美好，用爱温暖每一个新生代家庭。']
  },
  pxnr: { title: '培训内容', value: ['技能培养', '素质提升', '场景实践'] },
  aqbg: { title: '安全把关', value: ['1、MBTI 职业性格测试助力育婴师的筛选，精准匹配家庭个性化需求。', '2、岗前育婴师背景调查，保障育儿安全与专业性。'] },
  yysys: {
    title: '育婴师优势',
    nqyhl: {
      title: '年轻有活力',
      value: '25 - 40 年龄占比 67%'
    },
    pxszj: {
      title: '品行素质佳',
      value: '大专及以上 23%, 高中学历 43%'
    },
    gzsjx: {
      title: '高知上进心',
      value: '耐心、爱心、细心，可靠安全，善于听意见不老派，牢记嘱咐执行强'
    }
  },
  fntx: {
    title: '予家领先的运营赋能体系',
    tabs: ['筛选', '评估', '培训', '带教', '考核', '定级', '实习', '服务', '再深造', '定制化方案'],
    py: {
      title: '培养',
      value: '从招聘到派遣上户服务，除了为育婴师提供专业技能培训外，我们还从素质提升、价值认同，从内核到外核全方位赋能育婴师，只为提供高品质的服务。'
    },
    szsg: {
      title: '三证上岗',
      value: '高级育婴师证、高级早教师证、高级营养配餐师证'
    },
    yfw: {
      title: '母婴专家团队云服务',
      value: ['1. 婴幼儿护理顾问', '2. 婴幼儿营养顾问', '3. 婴幼儿早教顾问', '4. 母乳指导顾问', '5. 产后康复顾问']
    }
  },
  hhbz: {
    title: '不同月龄宝宝的特质，予家呵护备至',
    yekx: '予家以护理学为基础，融合五大育儿学科',
    zhtx: '建立科学专业的婴幼儿照护体系',
    desc: ['关注婴幼儿生长发育、疾病预防、疾病诊疗和保健', '遵循婴幼儿发展心理学，关注婴儿期和幼儿期不同阶段的发展任务和品质', '婴儿接受环境刺激容易增加脑神经细胞的分支和突触', '遵循尊重、回应式的照护原则，我们了解最好的教育就发生在每一次的日常照护之中'],
    xue: ['儿科学', '脑科学', '护理学', '心理学', '教育学'],
    center: ['ACI 护理体系', '关注生活护理、饮食<br>护理、和环境护理'],
    yue: '个月',
    1: [
      ['监测宝宝生长发育，定期体格测量', '判断和护理宝宝的常见疾病', '判断宝宝的辅食添加的时机', '照护宝宝衣食起居及清洁消毒', '指导家长接种疫苗'],
      ['锻炼宝宝抬头、翻身、独坐、抓、握、大把抓等月龄基本动作', '通过交谈、音乐、儿歌，为宝宝', '在多场景建立倾听环境', '通过多感官认知“世界”，促进宝宝各方面潜能开发', '建立宝宝稳定的安全依恋']
    ],
    2: [
      ['监测宝宝生长发育，定期体格测量', '为宝宝提供营养配餐', '指导家长为宝宝接种疫苗', '逐步养成宝宝的规律作息和良好睡眠习惯', '判断和护理宝宝的常见疾病', '照护宝宝衣食起居及清洁消毒'],
      ['锻炼宝宝爬、走基本动作', '培养宝宝的语言倾听能力，养成阅读的兴趣和习惯', '让宝宝认识亲人、物品，感知事物之间的因果关系', '建立宝宝的客体永存概念，缓解分离焦虑']
    ],
    3: [
      ['对宝宝定期体格测量', '为宝宝提供均衡营养和多样化食物', '宝宝的疾病预防', '防止宝宝受到意外伤害'],
      ['让宝宝掌握走、跑、跳的运动能力，发展手部精细动作', '在探索中成长，在活动中启蒙宝宝的智慧', '学会大胆表达，体验多种艺术形式，找到属于宝宝自己的表达方式', '提升宝宝自理、规则、探索等能力']
    ],
    4: [
      ['对宝宝定期体格保健测量', '宝宝的疾病预防', '防止宝宝受到意外伤害', '培养宝宝社交礼仪，如西餐礼仪、中餐礼仪', '宝宝的入园适应培养，如情绪控制、生活自理'],
      ['提高宝宝灵敏、协调、稳定等关键运动经验，培养良好意志品格', '培养宝宝良好的阅读和表达习惯，塑造大方、自信、勇敢的个性', '创设家庭的多彩环境，设计启发宝宝认知的专业游戏', '尊重宝宝学习特点，情景化榜样式学习']
    ]
  }
}
// 繁体翻译
const tw = {
  banner: { title: '予家' },
  ppgs: {
    title: '品牌故事',
    subTitle: '呵護新生成長',
    desc: '予家是貝康集團孵化的駐家母嬰服務品牌，通過科學培訓體系讓予家育嬰師掌握標準化、專業化、人性化護理技能，致力於讓新生代家庭享受更美好的健康護理服務。'
  },
  fwln: {
    title: '服務理念', value: ['予家用心融入家庭，為不同的家庭，設計不同的呵護方案，讓您在呵護新生的過程中更踏實，更從容。 呵護新生，予家傳遞著“女性幫助女性”的美好，用愛，溫暖每一個新生代家庭。 ']
  },
  pxnr: { title: '培訓內容', value: ['技能培養', '素質提升', '場景實踐'] },
  aqbg: { title: '安全把關', value: ['1、MBTI職業性格測試助力育嬰師的篩選，精準匹配家庭個性化需求。', '2、崗前育嬰師背景調查，保障育兒安全與專業性。'] },
  yysys: {
    title: '育嬰師優勢',
    nqyhl: {
      title: '年輕有活力',
      value: '25 - 40年齡占比 67%'
    },
    pxszj: {
      title: '品行素質佳 ',
      value: '大專及以上 23% ，高中學歷 43%'
    },
    gzsjx: {
      title: '高知上進心',
      value: '耐心、愛心、細心，可靠安全，善於聽意見不老派，牢記囑咐執行強'
    }
  },
  fntx: {
    title: '予家領先的運營賦能體系',
    tabs: ['篩選', '評估', '培訓', '帶教', '考核', '定級', '實習', '服務', '再深造', '定製化方案'],
    py: {
      title: '培養',
      value: '從招聘到派遣上戶服務，除了為育嬰師提供專業技能培訓外，我們還從素質提升、價值認同，從內核到外核全方位賦能育嬰師，只為提供高品質的服務。'
    },
    szsg: {
      title: '三證上崗',
      value: '高級育嬰師證、高級早教師證、高級營養配餐師證'
    },
    yfw: {
      title: '母嬰專家團隊雲服務',
      value: ['1. 嬰幼兒護理顧問', '2. 嬰幼兒營養顧問', '3. 嬰幼兒早教顧問', '4. 母乳指導顧問', '5. 產後康復顧問']
    }
  },
  hhbz: {
    title: '不同月齡寶寶的特質，予家呵護備至',
    yekx: '予家以護理學為基礎，融合五大育兒學科',
    zhtx: '建立科學專業的嬰幼兒照護體系',
    desc: ['關註嬰幼兒生長發育、疾病預防、疾病診療和保健', '遵循嬰幼兒發展心理學，關註嬰兒期和幼兒期不同階段的發展任務和品質', '嬰兒接受環境刺激容易增加腦神經細胞的分支和突觸', '遵循尊重、回應式的照護原則，我們了解最好的教育就發生在每一次的日常照護之中'],
    xue: ['兒科學', '腦科學', '護理學', '心理學', '教育學'],
    center: ['ACI 护理体系', '關註生活護理、飲食<br>護理和環境護理'],
    yue: '個月',
    1: [
      ['監測寶寶生長發育，定期體格測量', '判斷和護理寶寶的常見疾病', '判斷寶寶的輔食添加的時機', '照護寶寶衣食起居及清潔消毒', '指导家长接种疫苗'],
      ['鍛煉寶寶擡頭、翻身、獨坐、抓、握、大把抓等月齡基本動作', '通過交談、音樂、兒歌，為寶寶', '在多場景建立傾聽環境', '通過多感官認知“世界”，促進寶寶各方面潛能開發', '建立寶寶穩定的安全依戀']
    ],
    2: [
      ['監測寶寶生長發育，定期體格測量', '為寶寶提供營養配餐', '指導家長為寶寶接種疫苗', '逐步養成寶寶的規律作息和良好睡眠習慣', '判斷和護理寶寶的常見疾病', '照護寶寶衣食起居及清潔消毒'],
      ['鍛煉寶寶爬、走基本動作', '培養寶寶的語言傾聽能力，養成閱讀的興趣和習慣', '讓寶寶認識親人、物品，感知事物之間的因果關系', '建立寶寶的客體永存概念，緩解分離焦慮']
    ],
    3: [
      ['對寶寶定期體格測量', '為寶寶提供均衡營養和多樣化食物', '寶寶的疾病預防', '防止寶寶受到意外傷害'],
      ['讓寶寶掌握走、跑、跳的運動能力，發展手部精細動作', '在探索中成長，在活動中啟蒙寶寶的智慧', '學會大膽表達，體驗多種藝術形式，找到屬於寶寶自己的表達方式', '提升寶寶自理、規則、探索等能力']
    ],
    4: [
      ['對寶寶定期體格保健測量', '寶寶的疾病預防', '防止寶寶受到意外傷害', '培養寶寶社交禮儀，如西餐禮儀、中餐禮儀', '寶寶的入園適應培養，如情緒控製、生活自理'],
      ['提高寶寶靈敏、協調、穩定等關鍵運動經驗，培養良好意誌品格', '培養寶寶良好的閱讀和表達習慣，塑造大方、自信、勇敢的個性', '創設家庭的多彩環境，設計啟發寶寶認知的專業遊戲', '尊重寶寶學習特點，情景化榜樣式學習']
    ]
  }
}

// 中文翻译
const en = {
  banner: { title: '' }, // PRIMECARE FAMILY
  ppgs: {
    title: '', // 品牌故事
    subTitle: 'Nurturing Newly-Minted Lives ',
    desc: 'PrimeCare Family is an in-home maternity and baby care service brand developed by PrimeCare. PrimeCare Family prepares its baby caregivers with standardized, professional, and human-centered care-giving skills through a science-based training system. We are dedicated to ensuring that newly minted families enjoy the best possible health care services.'
  },
  fwln: {
    title: 'SERVICE PHILOSOPHY', value: ['Primecare Family is a dedicated team of professionals who provide personalized care plans to families with newborns. Our tailor-made plans are designed to meet the unique needs of each household, giving families peace of mind and confidence during this precious time. ']
  },
  pxnr: { title: 'TRAINING CONTENT', value: ['Skill Development', 'Quality Enhancement', 'Real-life Practice'] },
  aqbg: { title: 'SECURITY CHECK', value: ['1. The MBTI career personality test aids in the selection of infant care specialist, ensuring precise matchings with the personal needs of each family.', '2. Pre-employment background check on infant care specialist to ensure the safety and professionalism of childcare.'] },
  yysys: {
    title: 'Infant Care Specialist',
    nqyhl: {
      title: 'Young and Energetic',
      value: 'Age Group of 25-40 makes up 67%'
    },
    pxszj: {
      title: 'Good Moral Character',
      value: '23% with College Degree or Higher, 43% with High School Education'
    },
    gzsjx: {
      title: 'Highly Educated and High-Aspiring',
      value: 'Patient, compassionate, attentive, reliable, open to feedback, mindful of instructions and able to execute tasks with efficiency'
    }
  },
  fntx: {
    title: 'PRIMECARE’s leading operation system',
    tabs: ['Screening', 'Evaluation', 'Training', 'Mentorship', 'Assessment', 'Grading', 'Internship', 'Service', 'Continuing Education', 'Customized Solutions'],
    py: {
      title: 'Training',
      value: 'From recruitment to deployment, we carefully select and train our infant care specialist to provide high-quality in-home service. In addition to professional skills training, we also focus on character development and instilling good values. We believe that empowered infant care specialists provide the best care for babies.'
    },
    szsg: {
      title: 'Three Certifications for Employment',
      value: 'Advanced Baby Caregiver Certificate, Advanced Early Childhood Educator Certificate, Advanced Nutrition and Menu Planning Certificate'
    },
    yfw: {
      title: 'Cloud Services by Maternal and Infant Expert Team',
      value: ['1. Infant and Toddler Care Consultant', '2. Infant and Toddler Nutrition Consultant', '3. Infant and Toddler Early Education Consultant', '4. Breastfeeding Guidance Consultant', '5. Postpartum Recovery Consultant']
    }
  },
  hhbz: {
    title: 'BABY CARE FOR DIFFERENT STAGES',
    yekx: 'PrimeCare Family is based on nursing science, integrating five major childcare disciplines and',
    zhtx: 'setting up a science-based professional infant and toddler care system',
    desc: ['Focus on infant and toddler growth and development; prevention of diseases; proper diagnosis and treatment of illnesses, and overall health maintenance', 'Follow infant and toddler developmental psychology, mindful of the developmental tasks and qualities specific to the different stages of infancy and toddlerhood', 'Early exposure to environmental stimuli promotes the growth and development of neural connections in the brain', 'We believe that the best education occurs in the context of respectful and responsive care.'],
    xue: ['Paediatrics', 'Neuroscience', 'Nursing', 'Psychology', 'Pedagogy'],
    center: ['ACI 护理体系', 'Focus on diet, lifestyle, and environment to support <br>overall health and well-being'],
    yue: 'Months',
    1: [
      ['· Monitor baby\'s growth and development, conduct regular physical assessment', '· Newborn health screenings to help diagnose and treat common diseases early', '· Determine the time for introduction of solid food to baby', '· Take care of baby\'s clothing, food, daily routines, and maintain cleanliness and carry out disinfections', '· Provide guidance to parents on vaccinations'],
      ['· Help baby develop basic age-appropriate skills such as lifting the head, rolling over, sitting independently, grasping, holding objects and gathering them in', '· Through conversation, music, and nursery rhymes', 'to create environment of sounds of various scenarios for baby to tune in to', '· Promote development of baby\'s potentials in various aspects through multi-sensory perceptions of the world', '· Establish a stable and secure attachment feel for the baby']
    ],
    2: [
      ['· Monitor baby\'s growth and development, conduct regular physical measurements', '· Provide balanced nutrition for baby', '· Guide parents in vaccinating baby', '· Gradually enable baby to develop regular routines and good sleep habits', '· Identify and provide care for common illnesses in baby', '· Take care of the baby\'s clothing, food, daily routines, and maintain cleanliness and carry out disinfections'],
      ['· Drill baby in basic movements such as crawling and walking', '· Cultivate baby\'s listening skill, foster an interest in and habit of reading', '· Help baby recognize family members, objects, and sense the cause-and-effect correlations between things', '· Enhance the awareness of object permanence and reduce the distress of separation anxiety in babies']
    ],
    3: [
      ['· Regularly track and measure baby\'s physical growth', '· Provide baby with balanced nutrition and diversity of food', '· Prevent baby from contracting disease', '· Protect baby from accidental injuries'],
      ['· Enable baby to grasp the motor skill of walking, running and jumping, and develop intricate movements of the hands', '· Grow up exploring around and nurture baby\'s intelligence through activities', '· Learn to express themselves boldly, experience various art forms, and find the way of expression that is the baby\'s own', '· Enhance baby\'s ability to self-care, to practise discipline, and carry out explorations']
    ],
    4: [
      ['· Regularly take physical measurements of baby’s health', '· Prevent baby from contracting disease', '· Protect baby from accidental injuries', '· Cultivate social etiquette in the baby, such as Western and Chinese dining manners', '· Help babies to develop and understand social skills and cultural norms, such as Western and Chinese dining etiquette.', '· Develop baby\'s adaptability to preschool, such as emotional stability and self-care'],
      ['· Enhance baby\'s key motor experience like agility, coordination, and stability, foster good will power and character ', '· Cultivate good reading and communication skills in baby, build a generous, confident and courageous personality', '· Create a lively home environment, design professional games that stimulate baby\'s cognitive capacity', '· Respect baby\'s learning style, use scenario-based and role-model learning']
    ]
  }
}
export { cn, tw, en }
