import { request } from '@/util/request'

/**
 * 获取门店列表
 */
export const _getStroeList = (params) => {
  return request({
    method: 'get',
    url: '/Index/storeList',
    params
  })
}

/**
 * 获取门店详情
 */
export const _getStroeDetail = (params) => {
  return request({
    method: 'get',
    url: '/Index/storeDetails',
    params
  })
}

/**
 * 获取门店对应套餐列表
 */
export const _getStoreMealList = (params) => {
  return request({
    method: 'get',
    url: '/Index/setMealList',
    params
  })
}

/**
 * 获取门店对应图片列表
 */
export const _getStoreImageList = (params) => {
  return request({
    method: 'get',
    url: '/Index/getStoreImgs',
    params
  })
}
