import NProgress from 'nprogress'
import store from '@/store'
import { KeySuffix } from '@/i18n/country'
import { language } from '@/i18n/config'
/**
 * 锁定滚动条
 */
const lockScroll = () => {
  let widthBar = 17
  const root = document.documentElement
  if (typeof window.innerWidth === 'number') {
    widthBar = window.innerWidth - root.clientWidth
  }
  root.style.overflow = 'hidden'
  root.style.borderRight = widthBar + 'px solid transparent'
}

/**
 * 解锁滚动条
 */
const unlockScroll = () => {
  const root = document.documentElement
  root.style.overflow = ''
  root.style.borderRight = ''
}

/**
 * 顶部加载动画
 * @param {Boolean} load 加载动画状态 true-开始 false-结束 null-移除
 * @param {Boolean} load 是否展示加载动画
 */
const loading = (load = true) => {
  // console.log(NProgress.start().set(0.2), load)
  if (load === true) {
    NProgress.start()
  }
  if (load === false) {
    NProgress.done()
  }
  if (load === null) {
    NProgress.remove()
  }
}

const getIsMobile = () => store.state.isMobile

/**
 * 拼接图片前缀
 */
const getAssetsSrc = (src) => /http[s]{0,1}:\/\/([\w.]+\/?)\S*/.test(src) ? src : `${store.state.REQASSETSURL}${src}`

/**
 * 自动拼接手机端后缀 xxx.png => xxx_web.png
 */
const getAssetsWeb = src => getIsMobile() ? src.replace(/\.png/, '_web.png').replace(/\.webp/, '_web.webp').replace(/\.jpg/, '_web.jpg') : src

// 行类样式的px转换rem
const stylePxToRem = (px) => {
  if (/%/ig.test(px)) { // 有百分号%，特殊处理，表述pc是一个有百分号的数，比如：90%
    return px
  } else {
    return ((parseFloat(px) / 192).toFixed(5) + 'rem')
  }
}

/**
 * 获取当前翻译语言
 */
const getLang = () => {
  return localStorage.getItem('locale') || 'zh-CN'
}
/**
 * 获取当前翻译语言的value值
 */
const getLangValue = () => {
  const lang = getLang()
  return language.find(v => v.key === lang)?.value || 'cn'
}

/**
 * 设置当前翻译语言
 */
const setLang = locale => {
  return localStorage.setItem('locale', locale || 'zh-CN')
}

/**
 * 根据当前语言，返回增加后缀的key值  key_en || key_tw || cn默认不加
 * @param {String} key 字段key
 * @param {Boolean} cnAdd cn是否需要加后缀
 * @returns 处理完后的 key 值
 */
const valueLang = (key = '', cnAdd = false) => {
  const value = getLangValue()
  if (cnAdd) {
    return key + '_' + value
  } else {
    return key + (value !== 'cn' ? '_' + value : '')
  }
}

/**
 * 在什么语言下不展示，如果传入语言等于当前语言，返回false
 * @param {any} value 渲染的文本
 * @param {String} key 默认在en语言下隐藏此文本
 * @returns {Boolean}
 */
const langHide = (value, key = 'en-GB') => {
  const lang = getLang()
  const isHide = lang === key // 等于传入语言则隐藏
  if (value) {
    return !isHide ? value : ''
  } else {
    return isHide
  }
}

/**
 * 获取当前所在国家
 */
const getCountry = () => {
  return localStorage.getItem('country') || 'CN'
}

/**
 * 获取当前所在国家信息
 */
const getCountryInfo = () => {
  const Country = store.state.countryList.find(v => v.identifier === getCountry())
  return Country || {}
}

/**
 * 设置当前所在国家
 */
const setCountry = (value = 'CN') => {
  return localStorage.setItem('country', value)
}

// 翻译字段映射表 与后台顺序对应
const langInIndex = { 'zh-CN': 0, 'zh-TW': 1, 'en-GB': 2 }
/**
 * 获取国家语言字段 目前想法用于替换 langMerge 功能
 * @param {String} contentKey 使用 Index/getCountryList 接口的字段名
 * @param {Object} config toBr 由Br标签替换换行 toArray 由数组进行换行
 * @returns {any}
 */
const getCountryLang = (contentKey = '', config = {}) => {
  // 当前选中国家信息
  const CountryInfo = store.state.countryList.find(v => v.identifier === getCountry()) || {}
  // 当前选中翻译下标
  const langIndex = langInIndex[getLang()]
  // 当前选中字段
  const CountryValue = CountryInfo[contentKey]
  if (!CountryValue) return ''
  // ### 三#号语言切换
  let value = CountryValue.replaceAll('\n', '').split('###')[langIndex] || ''
  // if (contentKey === 'content7') {
  //   console.log('value =>>>>>>>>>>>>>>', value)
  // }
  if (value) {
    if (value.search('##') >= 0) {
      if (config.toBr) {
        // ## 切换为Br标签实现换行
        value = value.replaceAll('##', '<br/>')
      } else {
        // ## 切换为数组用于换行
        value = value.split('##')
      }
    }

    // 仅妈妈护理,宝宝护理有效 解析数组中双*号包裹内容,增加一个类名
    if (Array.isArray(value)) {
      value.forEach((v, i) => {
        if (v.startsWith('**') && v.endsWith('**')) {
          value[i] = v.slice(2, -2)
          value[i] = `<p class='tips'>${value[i]}<p>`
        }
      })
    } else {
      if (config.toArray) {
        value = [value]
      }
    }
    return value
  } else {
    console.log('字段选择有误')
    return ''
  }
}

/**
 * 国家语言融入原语言系统
 * @param {Object} country 国家语言集合
 * @param {Object} lang 原语言集合
 * @returns {Object} 融入后的语言系统
 */
const langMerge = (country = {}, lang = {}) => {
  // 当前所在国家的语言 新国家没配置 默认使用中国的文案
  const countryLang = country
  // 当前使用的语言
  const currentlang = lang[getLang()]
  const paths = getObjectPaths(countryLang)
  paths.forEach(keys => {
    const parent = selectValues(currentlang, keys, true)
    const endKey = keys[keys.length - 1].replace('_' + getLangValue(), '')
    if (endKey === 'subDesc') {
      // debugger
    }
    parent[endKey] = selectValues(countryLang, keys, false)
  })
  return currentlang
}

// 获取当前语言对应的翻译路径
const getObjectPaths = (obj = {}, currentPath = '', result = []) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const newPath = [...currentPath, key]
      if (
        typeof obj[key] === 'object' &&
        obj[key] !== null &&
        !key.includes('_cn') &&
        !key.includes('_en') &&
        !key.includes('_tw')
      ) {
        getObjectPaths(obj[key], newPath, result)
      } else {
        if (key.search('_' + getLangValue()) >= 0 || key.search('_def') >= 0) {
          result.push(newPath)
        }
      }
    }
  }
  return result
}

// 选中 对象 || 对象的值
const selectValues = (obj, paths = [], includeParentObject = false) => {
  const length = includeParentObject ? paths.length - 1 : paths.length
  let currIndex = 0
  let res = obj
  while (currIndex < length) {
    // debugger
    let key = paths[currIndex]
    if (!includeParentObject && currIndex === length) {
      KeySuffix.forEach(suffix => (key = key.replace(suffix, '')))
    }
    res = res[key]
    currIndex = currIndex + 1
  }
  return res
}

/**
 * 判断js是否加载过
 * @param {String} src 加载js的路径
 * @returns {Boolean} 是否加载过该js
 */
const isIncludeSrc = src => [...document.getElementsByTagName('script')].some(v => v.src === src)

/**
 * 加载指定js
 * @param {String} src 加载js的路径
 * @param {Object} data 其余额外的属性
 * @returns {Boolean} 是否加载过该js
 */
const loadJS = (src, data = {}) => {
  return new Promise((resolve, reject) => {
    if (isIncludeSrc(src)) return resolve({ message: '重复加载' })
    const script = document.createElement('script')
    script.type = 'text/javascript'
    Object.keys(data).forEach(key => script.setAttribute(key, data[key]))
    script.src = src
    // script.attributes
    document.body.appendChild(script)
    script.onload = resolve
    script.onerror = reject
  })
}

/**
 * 获取系统配置
 * @param {Boolean} update 是否获取最新配置
 */
const getConfig = async (update = false) => {
  if (update) {
    return await store.dispatch('updateSystemInfo')
  } else {
    return store.state.system
  }
}

// 缓存
const storage = (key = '', value = null) => {
  if (key === 'delect') {
    localStorage.removeItem(key)
  } else if (key === 'clear') {
    localStorage.clear()
  } else if (value !== null) {
    localStorage.setItem(key, value)
  }
  return localStorage.getItem(key)
}

/** 计算两个点(经纬度)的距离
 * @param startDot 开始点的经纬度(lng.经度 lat.纬度)
 * @param endDot 结束点的经纬度(lng.经度 lat.纬度)
 * @returns {{res: string, m: Number, km: Number}} originVal.原始值单位为米
 */
const getDistance = (startDot = { lat: 0, lng: 0 }, endDot = { lat: 0, lng: 0 }) => {
  if (!startDot || !endDot) {
    return { mVal: '', kmVal: '', originVal: '两点的经纬度为必传' }
  }
  const lngDiffVal = Math.round((startDot.lng - endDot.lng) * 100000) // 经度的距离差(单位m)
  const latDiffVal = Math.round((startDot.lat - endDot.lat) * 111320) // 纬度的距离差(单位m)
  const result = Math.sqrt(Math.pow(lngDiffVal, 2) + Math.pow(latDiffVal, 2)) // 结果值
  const mUnit = result | 0 // 单位米
  const kmUnit = (result / 1000) | 0 // 单位千米
  return { m: mUnit, km: kmUnit, res: result.toString() }
}

// 时间戳防抖 首次不会执行
function timerDebounce(fun, delay = 500) {
  let timer
  return function _debounce() {
    const _this = this
    const args = arguments
    if (timer) clearTimeout(timer)
    timer = setTimeout(() => {
      fun.apply(_this, args)
      timer = null
    }, delay)
  }
}

export {
  lockScroll, // 锁定页面滚动
  unlockScroll, // 解锁页面滚动
  loading, // 给页面顶部加载动画
  getAssetsSrc, // 拼接后端的图片路径
  stylePxToRem, // 行类样式px转换自适应rem
  getLang, // 获取当前使用语言
  setLang, // 设置当前使用语言
  valueLang, // 给字段增加当前语言的后缀
  langHide, // 在指定语言下返回true
  getAssetsWeb, // 拼接默认的图片路径
  getIsMobile, // 获取当前是不是web端
  getCountry, // 获取当前使用国家
  getCountryInfo, // 获取所在国家信息
  setCountry, // 设置国家
  getCountryLang, // 获取指定国家语言
  langMerge, // 国家语言与本地语言合并
  isIncludeSrc, // 判断是否加载过指定js脚本
  loadJS, // 加载指定js脚本
  getConfig, // 获取系统配置
  storage, // 操作缓存本地localStorage缓存
  getDistance, // 通过经纬度计算两地距离
  timerDebounce // 时间戳防抖
}
