// 中文翻译
const cn = {
  lookDetail: '查看详情',
  ppjs: {
    title: '品牌介绍',
    content: [
      '简称圣贝拉 (SAINT BELLA)，高奢月子护理品牌',
      '成立于2017年，自主研创艺术疗养，源自中国台湾资深产后护理团队，遵循ISO9001母婴认证护理标准，秉承西尔斯医学博士亲密育儿理念，坚持24小时护士一对一照护。',
      '圣贝拉肩负着颠覆和创新的使命，仅入驻全球奢华酒店，通过LTV孕产全周期的奢享服务，开启女性月子之旅，打造由内至外蜕变的至美圣地。 ',
      '2021年，圣贝拉匠心开拓「旅居式月子」独栋系列BELLA VILLA，在全球寻觅奢华酒店旗下的独栋式别墅，将身体与心灵的双重疗愈极致糅合，让女性在静谧安逸的度假般环境中，奢享28天月子旅居。'
    ]
  },
  chxf: {
    title: '产后修复',
    chyxs: 'STB 产后研修所',
    content: '简称STB。潜心十年精研孕产女性体质，致力于打磨科技与匠心融合的修复治愈体系。以科学为源，特聘来自日、韩、新加坡、英国等国家的医学博士及美学形体专家。引入国内外殿堂级技术与仪器，研发创新修复产品，淬炼古法手法，三维一体重愈新生。以美学为引，私人定制内在调理与外在修复方案，精准塑造身形黄金比例。以服务为基，提供始于孕期的全周期服务，把握180天产后修复黄金期，锻造每一位女性的肌体复元。'
  },
  yzc: {
    title: '月子膳食',
    lddsy: '流动的盛宴',
    fjdtlyzss: '分阶段调理月子膳食',
    content: '由健康专家和营养师精心制作菜谱，酒店五星主厨烹饪，调配出符合哺乳期产妇所需营养及热量（每日热量控制在2300-2500大卡）的四阶段调理餐食方案。一日六餐，三餐三点，低油、低盐、无味素无化学调味品；搭配炖、蒸、熬、煲的调理，给予味觉与视觉的双重享受。并于每日提供，源自中国台湾的广禾堂产后护理秘方养生茶。',
    pai: ['排', '毒素，排废血及废水，轻松又舒畅'],
    tiao: ['调', '阴阳，促进细胞新陈代谢，提升自我修复能力'],
    yang: ['养', '肾元，补充能量帮助恢复元气，美容养颜'],
    bu: ['补', '气血，促进造血功能，补肝肾壮筋骨']
  },
  ysly: {
    title: '艺术疗养',
    ycyslykc: '研创艺术疗养课程',
    desc: '圣贝拉艺术疗养，联合《时尚芭莎艺术》与武汉音乐学院，共同甄选适合产后聆听的28首古典音乐。馆内绘画课程、3分钟电影解读，配合画作雕塑赏析解说，让月子生活充满丰富的艺史趣闻。此外，与《ELLE》倾情定制「夜读诗选」书册，每夜爸爸轻声朗读，增加育儿参与度。'
  },
  ltv: {
    title: '以全周期护理服务，陪你启程月子之旅',
    list: [
      { name: '第一幕', value: '探索' }, { name: '第二幕', value: '孕期' }, { name: '第三幕', value: '月子期' }, { name: '第四幕', value: '过渡期' }, { name: '第五幕', value: '育儿期' }
    ]
  },
  ppsj: {
    title: '品牌瞬间',
    lookAll: '浏览锦集'
  }
}
// 繁体翻译
const tw = {
  lookDetail: '查看詳情',
  ppjs: {
    title: '品牌介紹',
    content: [
      '簡稱聖貝拉 (SAINT BELLA)，高奢月子護理品牌',
      '成立於 2017年，自主研創藝術療養，源自中國臺灣資深產後護理團隊，遵循ISO9001母嬰認證護理標準，秉承西爾斯醫學博士親密育兒理念，堅持24小時護士一對一照護。',
      '聖貝拉肩負著顛覆和創新的使命，僅入駐全球奢華酒店，通過LTV孕產全周期的奢享服務，開啟女性月子之旅，打造由內至外蛻變的至美聖地。 ',
      '2021年，聖貝拉匠心開拓「旅居式月子」獨棟系列BELLA VILLA，在全球尋覓奢華酒店旗下的獨棟式別墅，將身體與心靈的雙重療愈極致糅合，讓女性在靜謐安逸的度假般環境中，奢享28天月子旅居。'
    ]
  },
  chxf: {
    title: '產後修復',
    chyxs: 'STB 產後研修所',
    content: '簡稱STB。潛心十年精研孕產女性體質，致力於打磨科技與匠心融合的修復治愈體系。以科學為源，特聘來自日、韓、新加坡、英國等國家的醫學博士及美學形體專家。引入國內外殿堂級技術與儀器，研發創新修復產品，淬煉古法手法，三維一體重愈新生。以美學為引，私人定製內在調理與外在修復方案，精準塑造身形黃金比例。以服務為基，提供始於孕期的全周期服務，把握180天產後修復黃金期，鍛造每一位女性的肌體復元。'
  },
  yzc: {
    title: '月子膳食',
    lddsy: '流動的盛宴',
    fjdtlyzss: '分階段調理月子膳食',
    content: '由健康專家和營養師精心製作菜譜，酒店五星主廚烹飪，調配出符合哺乳期產婦所需營養及熱量（每日熱量控製在2300-2500大卡）的四階段調理餐食方案。一日六餐，三餐三點，低油、低鹽、無味素無化學調味品；搭配燉、蒸、熬、煲的調理，給予味覺與視覺的雙重享受。並於每日提供，源自中國臺灣的廣禾堂產後護理秘方養生茶。',
    pai: ['排', '毒素，排廢血及廢水，輕松又舒暢'],
    tiao: ['調', '陰陽，促進細胞新陳代謝，提升自我修復能力'],
    yang: ['養', '腎元，補充能量幫助恢復元氣，美容養顏'],
    bu: ['補', '氣血，促進造血功能，補肝腎壯筋骨']
  },
  ysly: {
    title: '藝術療養',
    ycyslykc: '研創藝術療養課程',
    desc: '聖貝拉藝術療養，聯合《時尚芭莎藝術》與武漢音樂學院，共同甄選適合產後聆聽的28首古典音樂。館內繪畫課程、3分鐘電影解讀，配合畫作雕塑賞析解說，讓月子生活充滿豐富的藝史趣聞。此外，與《ELLE》傾情定製「夜讀詩選」書冊，每夜爸爸輕聲朗讀，增加育兒參與度。'
  },
  ltv: {
    title: '以全周期護理服務，陪你啟程月子之旅',
    list: [
      { name: '第一幕', value: '探索' }, { name: '第二幕', value: '孕期' }, { name: '第三幕', value: '月子期' }, { name: '第四幕', value: '過渡期' }, { name: '第五幕', value: '育兒期' }
    ]
  },
  ppsj: {
    title: '品牌瞬間',
    lookAll: '瀏覽錦集'
  }
}
// 中文翻译
const en = {
  lookDetail: 'view the details',
  ppjs: {
    title: 'Brand Introduction',
    content: [
      'Known in short as SAINT BELLA',
      'we were established in 2017, a luxury maternity and baby care brand originating from a team of senior postpartum care professionals in Taiwan Province\'s. SAINT BELLA developed its own Art & Nursing programme, We have also followed ISO9001 certification nursing standards for our maternal and baby nursing system. Upholding Dr. William Sears\' attachment parenting concept, we offer 24-hour, one-to-one nursing care.',
      'Stationed exclusively within luxury hotels worldwide, SAINT BELLA is on a mission to revolutionize and innovate. Through our Long-Term Value Service, which offers luxury services throughout the entire pregnancy periods, SAINT BELLA embarks on a journey to support women in their postpartum recovery, creating a beautiful haven for both inner and outer transformative changes.',
      'In 2021, with meticulous dedication, SAINT BELLA pioneered the luxury-resort style, stand-alone series named BELLA VILLA. Across the globe, we scoured for exclusive villas under luxury hotel brands to seamlessly integrate the dual healing of body and soul in a tranquil and vacation like environment, allowing moms to enjoy a luxurious 28-day postpartum journey.'
    ]
  },
  chxf: {
    title: '', // S TREATMENT BEAUTY
    chyxs: 'S Treatment Beauty',
    content: 'Ten years of intensive research on the postpartum female physique has culminated into the development of our innovative beauty and recovery brand S Treatment Beauty (STB). STB was developed combining leading technology and expertise from Japan, South Korea, Singapore, the UK, and more. Guided by aesthetics, we customize internal conditioning and external repair plans to accurately restore the golden proportions of your body shape. STB provides full-cycle services starting from pregnancy, seizing the 180-day postpartum recovery golden period, to forge every woman\'s peak physical recovery.'
  },
  yzc: {
    title: '', // DINING EXPERIENCE
    lddsy: 'A Moveable Feast',
    fjdtlyzss: 'Four stages of dietary requirements',
    content: 'The menu is carefully prepared by health experts and nutritionists, cooked by five-star hotel chefs, and formulated a four-stage postpartum recovery meal plan that meets the nutritional and calorie needs of nursing mothers (daily calories are controlled between 2300-2500 kcal). Six meals a day, three meals and three snacks which are low in oil and salt, and free of artificial seasonings. We employ a mix of cooking techniques, with foods stewed, steamed, simmered, and braised, to offer a culinary experience that delights both the palate and the eyes. Additionally, we provide daily servings of postpartum wellness tea, sourced from Taiwan Province\'s Guang He Tang.',
    pai: ['D', 'etoxification Eliminate toxins and excess fluids, promoting comfort and well-being'],
    tiao: ['B', 'alance Yin and Yang,Enhance cellular metabolism and boost self-repair capabilities'],
    yang: ['N', 'ourishment,Replenish your energy to restore vitality and boost skin rejuvenation '],
    bu: ['T', 'onify Qi and Blood,Improve circulation, promote hematopoietic function, tonify liver and kidney, and strengthen bones and muscles ']
  },
  ysly: {
    title: '', // BELLA ARTS
    ycyslykc: 'Art Therapy Courses',
    desc: 'SAINT BELLA Art Therapy collaborates with "Harper\'s Bazaar Art" and Wuhan Conservatory of Music in curating 28 classical music pieces suitable for postpartum tuning-in. We also offer painting classes and 3-minute film reviews that go with guided appreciation and elucidation of artworks and sculptures which enriches postpartum lives with art history and interesting snippets. In addition, we zestfully custom-created the "Night Poetry Reading" booklet in partnership with "ELLE," which encourages fathers to recite softly every night to their babies to enhance their involvement in parenting.'
  },
  ltv: {
    title: 'With full-term care services, we accompany you on your postpartum journey.',
    list: [ // APPOINTMENT  PREGANCY  CONFINEMENT  TRANSITION  PARENTING
      { name: 'Act 1', value: '' }, { name: 'Act 2', value: '' }, { name: 'Act 3', value: '' }, { name: 'Act 4', value: '' }, { name: 'Act 5', value: '' }
    ]
  },
  ppsj: {
    title: '', // BRAND MOMENTS
    lookAll: 'View All'
  }
}

export { cn, tw, en }
