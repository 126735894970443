import { cn as homeCn, tw as homeTw, en as homeEn } from './home'
import { cn as brandStoryCn, tw as brandStoryTw, en as brandStoryEn } from './brandStory'
import { cn as majorCn, tw as majorTw, en as majorEn } from './major'
import { cn as majorHoursCn, tw as majorHoursTw, en as majorHoursEn } from './majorHours'
import { cn as majorCycleCn, tw as majorCycleTw, en as majorCycleEn } from './majorCycle'
import { cn as majorArtCn, tw as majorArtTw, en as majorArtEn } from './majorArt'
import { cn as majorJoinCn, tw as majorJoinTw, en as majorJoinEn } from './majorJoin'
import { cn as majorTimerCn, tw as majorTimerTw, en as majorTimerEn } from './majorTimer'
import { cn as monthFoodCn, tw as monthFoodTw, en as monthFoodEn } from './monthFood'
import { cn as schoolCn, tw as schoolTw, en as schoolEn } from './school'
import { cn as storeDetailCn, tw as storeDetailTw, en as storeDetailEn } from './storeDetail'
import { cn as postpartumRepairCn, tw as postpartumRepairTw, en as postpartumRepairEn } from './postpartumRepair'
import { cn as vipCn, tw as vipTw, en as vipEn } from './vip'
import { cn as jobHuntCn, tw as jobHuntTw, en as jobHuntEn } from './jobHunt'
import { cn as brandCn, tw as brandTw, en as brandEn } from './brand'
import { cn as storeCn, tw as storeTw, en as storeEn } from './store'
import { cn as yujiaCn, tw as yujiaTw, en as yujiaEn } from './yujia'

export default {
  homeCn,
  homeTw,
  homeEn,
  brandStoryCn,
  brandStoryTw,
  brandStoryEn,
  majorCn,
  majorTw,
  majorEn,
  majorHoursCn,
  majorHoursTw,
  majorHoursEn,
  majorCycleCn,
  majorCycleEn,
  majorCycleTw,
  majorArtCn,
  majorArtTw,
  majorArtEn,
  majorJoinCn,
  majorJoinTw,
  majorJoinEn,
  majorTimerCn,
  majorTimerTw,
  majorTimerEn,
  monthFoodCn,
  monthFoodTw,
  monthFoodEn,
  schoolCn,
  schoolTw,
  schoolEn,
  storeDetailCn,
  storeDetailTw,
  storeDetailEn,
  postpartumRepairCn,
  postpartumRepairTw,
  postpartumRepairEn,
  vipCn,
  vipTw,
  vipEn,
  jobHuntCn,
  jobHuntTw,
  jobHuntEn,
  brandCn,
  brandTw,
  brandEn,
  storeCn,
  storeTw,
  storeEn,
  yujiaCn,
  yujiaTw,
  yujiaEn
}
