<template>
  <!-- 顶部固定 -->
  <header class="header" :style="{ backgroundColor: showMeun ? 'transparent' : $store.state.headerTheme.bgColor }">
    <!-- logo -->
    <h1 class="logo_title">
      <router-link to="/">
        <img class="logo_img" v-if="logo !== null && $store.state.headerTheme.logo"
          :src="`${$store.state.ASSETSURL}images/logo_${$store.state.headerTheme.logo}.png`" alt="圣贝拉母婴护理中心" />
        <img class="logo_img" v-else-if="logo" :src="`${$store.state.ASSETSURL}images/logo_${logo}.png`"
          alt="圣贝拉母婴护理中心" />
      </router-link>
    </h1>

    <ul class="language" :style="{ color: $store.state.headerTheme.textColor ? '#fff' : '#000' }">
      <!-- 国家切换 -->
      <div class="country" v-if="countryState.curCountry?.[0]">
        <li>
          {{ countryState.curCountry[0].value }}
          <div class="country__box">
            <span v-for="item in countryState.otherCountry" :key="item.title" @click="setCountry(item.value)">{{
              item.title }}</span>
          </div>
        </li>
        <img class='icon' :src="`${$store.state.ASSETSURL}images/icon-jiantou.png`" alt="">
      </div>

      <!-- 语言切换 -->
      <template v-for="(lang, index) in language" :key="lang.key">
        <li @click="setLanguage(lang.key)">
          <a class="hover-opacity6">{{ lang.title }}</a>
        </li>
        <li v-if="index + 1 < language.length">|</li>
      </template>

      <!-- 菜单栏按钮 -->
      <li class="button-box">
        <div class="button" :class="{ close: showMeun, blackBg: !$store.state.headerTheme.textColor }"
          @click='changeMenuStatus'>
          <div class="line"></div>
          <div class="line"></div>
          <div class="line"></div>
        </div>
      </li>

      <!-- 菜单栏 -->
      <li class="menu-block" :class="{ 'menu-block-show': showMeun }">
        <img class="bgImage" :src="`${$store.state.ASSETSURL}images/header/bgImage.png`">
        <div class="mask"></div>
        <div class="meun-main">
          <!-- 菜单栏logo信息 -->
          <router-link to="/">
            <h2 class="h2" @click="onClickUrl()"><img :src="`${$store.state.ASSETSURL}images/logo_white.png`"></h2>
          </router-link>
          <!-- 菜单栏内容 -->
          <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index">
              <h4 class="enTitle" v-if="!langHide()">{{ item.enTitle }}</h4>
              <router-link :to="item.url" @click="onClickUrl()">
                <h4 class="cnTitle">{{ item.cnTitle }}</h4>
              </router-link>
              <div class="line"></div>
              <!-- 模块菜单 -->
              <div class="name-block" v-if="index !== 1">
                <div class="block">
                  <div class="child" v-for="meun in item.child" :key="meun.name">
                    <router-link :to="meun.url" @click="onClickUrl()">
                      {{ meun.name }}
                    </router-link>
                  </div>
                </div>
              </div>
              <!-- 门店探索---模块菜单 -->
              <div class="name-block storeInfo" v-else>
                <div class="block-box">
                  <div class="title">
                    <span>SAINT BELLA</span> {{ $t("header.store.rzjd.title1") }}
                  </div>
                  <div class="content">
                    <div class="block">
                      <div class="child" v-for="meun in item.child" :key="meun.name">
                        <router-link :to="meun.url" @click="onClickUrl()">
                          {{ meun.name }}
                        </router-link>
                      </div>
                    </div>
                    <div class="block ml62" v-if="item.child2">
                      <div class="child" v-for="meun in item.child2" :key="meun.name">
                        <router-link :to="meun.url" @click="onClickUrl()">
                          {{ meun.name }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="block-box">
                  <div class="title">
                    <span>BELLA VILLA</span> {{ $t("header.store.rzjd.title2") }}
                  </div>
                  <div class="content">
                    <div class="block" v-if="item.child3">
                      <div class="child" v-for="meun in item.child3" :key="meun.name">
                        <router-link :to="meun.url" @click="onClickUrl()">
                          {{ meun.name }}
                        </router-link>
                      </div>
                    </div>
                    <div class="block ml62" v-if="item.child4">
                      <div class="child" v-for="meun in item.child4" :key="meun.name">
                        <router-link :to="meun.url" @click="onClickUrl()">
                          {{ meun.name }}
                        </router-link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- 菜单栏底部信息 -->
          <div class="bottom">
            <span class="text">Copyright © 2016-2022 All Right Reserved</span>
            <div style="flex: 1;"></div>
            <!-- 国家切换 -->
            <div class="country" style="color: #fff;" v-if="countryState.curCountry?.[0]">
      <li>
        {{ countryState.curCountry[0].value }}
        <div class="country__box reverse">
          <span v-for="item in countryState.otherCountry" :key="item.title" @click="setCountry(item.value)">{{
            item.title }}</span>
        </div>
      </li>
      <img class='icon' :src="`${$store.state.ASSETSURL}images/icon-jiantou.png`" alt="">
      </div>
      <!-- 语言切换 -->
      <p class="language">
        <template v-for="(lang, index) in language" :key="lang.key">
          <span class="hover-opacity6" @click="setLanguage(lang.key)">
            {{ lang.title }}
          </span>
          <span class=" line" v-if="index + 1 < language.length">|</span>
        </template>
      </p>
      </div>
      </div>
      </li>
    </ul>
  </header>
</template>

<script setup>
import { langHide } from '@/util'
import { language } from '@/i18n'

// eslint-disable-next-line no-undef, vue/valid-define-props
defineProps(['list', 'showMeun', 'countryState'])

// eslint-disable-next-line no-undef
const emits = defineEmits(['onChange', 'setLang', 'setCountry', 'onClick'])

// 设置语言
const setLanguage = (lang) => {
  emits('setLang', lang)
}

// 设置国家
const setCountry = (country) => {
  emits('setCountry', country)
}

// 展开控制方法
const changeMenuStatus = () => {
  emits('onChange')
}

// 通过冒泡捕获点击事件  延迟防止跳转与动画重叠
const onClickUrl = () => {
  emits('onClick')
}

</script>

<script>
export default {
  props: {
    logo: {
      type: String || null,
      default: '' // white || black
    }
  }
}
</script>

<style lang="less" scoped>
.hover {
  &-opacity6 {
    transition: all 0.5s;
    cursor: pointer;

    &:hover {
      opacity: .6;
    }
  }
}

.header {
  width: 100%;
  height: 88px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s;

  .logo_title {
    margin-left: 60px;
    width: 150px;
    height: 40px;
    transition: all 0.3s;

    .logo_black {
      display: none;
    }

    .logo_img {
      width: 100%;
      height: 100%;
    }

    .logo_white {
      opacity: 0;
    }

    .hidden_logo {
      opacity: 0;
    }
  }

  >.language {
    margin-right: 60px;
    display: flex;
    align-items: center;
    font-size: 16px;
    color: #fff;

    li {
      margin: 0 5px;
      color: inherit;

      a {
        color: inherit;
        font-weight: 300;
        font-family: PingFangSC-Light;
        position: relative;
        height: 50px;
        width: 40px;
        transition: all 0.5s;
      }
    }

    .country {
      margin-right: 16px;
      display: flex;
      cursor: pointer;

      &:hover {
        .country__box {
          opacity: 1;
          // opacity: 0;
          // display: flex;
        }
      }

      li {
        position: relative;
        font-family: PingFangSC-Light;
        font-weight: 300;
        margin: 0;
      }

      .icon {
        width: 20px;
        height: 20px;
      }

      &__box {
        // display: none;
        display: flex;
        opacity: 0;
        position: absolute;
        top: 28px;
        padding: 0 22px 14px;
        left: 50%;
        border-radius: 2px;
        transform: translateX(-50%);
        background-color: rgba(0, 0, 0, 0.7);
        flex-direction: column;
        text-align: center;

        span {
          position: relative;
          font-family: PingFangSC-Light;
          font-weight: 300;
          margin: 0;
          margin-top: 14px;
          color: #fff;
          white-space: nowrap;
        }

        &::before {
          content: '';
          border: 5px solid transparent;
          border-top-color: #000;
          border-left-color: #000;
          display: block;
          position: absolute;
          left: 50%;
          opacity: 0.7;
          top: -1px;
          transform: rotateZ(45deg) translateX(-50%);
        }
      }

      .reverse {
        transform: translate(-50%, -100%);
        top: -18px;
        // opacity: 1;
        // padding: 14px 22px 0;
        &::before {
          opacity: 0.7;
          top: auto;
          border-bottom-color: #000;
          border-right-color: #000;
          bottom: -7px;
        }
      }
    }

    .button-box {
      position: relative;
      width: 40px;
      height: 40px;
      margin-left: 20px;

      .button {
        width: 40px;
        height: 40px;
        position: absolute;
        z-index: 999;
        cursor: pointer;
        box-sizing: border-box;

        .line {
          width: 40px;
          // height: 1Px;
          border-top: 1px solid #fff;
          background-color: #fff;
          position: absolute;
          transition: all 0.2s;

          &:nth-child(1) {
            left: 0;
            top: 14px;
          }

          &:nth-child(2) {
            top: 20px;
          }

          &:nth-child(3) {
            top: 26px;
            right: 0;
          }
        }

        &:hover .line {
          &:nth-child(1) {
            top: 12px;
          }

          &:nth-child(2) {
            top: 20px;
          }

          &:nth-child(3) {
            top: 28px;
          }
        }

      }

      .blackBg {
        .line {
          border-top-color: #000;
        }
      }

      .close {
        // position: absolute;
        // right: 0;
        // z-index: 999;

        .line {
          border-top-color: #fff;

          &:nth-child(1) {
            top: 20px;
            width: 20px;
            transform-origin: right bottom;
            transform: rotateZ(45deg);
          }

          &:nth-child(2) {
            top: 20px;
            transform: rotateZ(-45deg);
          }

          &:nth-child(3) {
            top: 20px;
            width: 20px;
            transform-origin: left bottom;
            transform: rotateZ(45deg);
          }
        }

        &:hover .line {
          &:nth-child(1) {
            top: 20px;
          }

          &:nth-child(2) {
            top: 20px;
          }

          &:nth-child(3) {
            top: 20px;
          }
        }
      }

    }

    .menu-block {
      width: 100vw;
      height: 0;
      overflow: hidden;
      position: absolute;
      top: 0;
      left: 0;
      margin: 0;
      transition: all 0.3s;

      &-show {
        height: 100vh;
      }

      .bgImage {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .mask {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: 0.7;
        top: 0;
        left: 0;
      }

      .meun-main {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        padding: 210px 200px 0;
        box-sizing: border-box;

        .h2 {
          width: 238px;
          height: 62px;

          img {
            width: 100%;
          }
        }

        .list {
          margin-top: 60px;
          display: flex;
          justify-content: space-between;

          // margin: 60px auto 0;
          .item {
            width: fit-content;

            // margin-right: 100px;
            &:last-child {
              margin-right: 0;
            }

            .enTitle {
              font-size: 12px;
              font-family: Branch-Regular;
              font-weight: 400;
              color: #FFFFFF;
              line-height: 17px;
              letter-spacing: 1px;
            }

            .cnTitle {
              font-size: 16px;
              font-family: STSongti-SC-Bold;
              font-weight: bold;
              color: #FFFFFF;
              line-height: 22px;
              letter-spacing: 2px;
              margin-top: 6px;
              width: fit-content;
              position: relative;

              &::after {
                transition: all 0.5s;
                content: '';
                position: absolute;
                height: 1px;
                bottom: -2px;
                left: 0;
                background-color: #fff;
                width: 0;
              }

              &:hover::after {
                color: #fff;
                width: 100%;
              }
            }

            .line {
              width: 120px;
              height: 1px;
              background: #FFFFFF;
              opacity: 0.3;
              margin-top: 26px;
            }

            &:nth-child(2) .line {
              width: 100%;
            }

            .name-block {
              display: flex;
              margin-top: 30px;
              flex-wrap: wrap;
              max-width: 600px;

              .block {

                .child {
                  font-size: 14px;
                  font-family: SourceHanSansCN-Regular;
                  font-weight: 400;
                  color: #CCCCCC;
                  line-height: 20px;
                  margin-bottom: 14px;
                  position: relative;
                  width: fit-content;

                  &::after {
                    transition: all 0.5s;
                    content: '';
                    position: absolute;
                    height: 1px;
                    bottom: -2px;
                    left: 0;
                    background-color: #fff;
                    width: 0;
                  }

                  &:hover::after {
                    width: 100%;
                  }

                  &:hover {
                    color: #fff;
                  }
                }

              }

              .ml62 {
                margin-left: 62px;
              }
            }

            .storeInfo {
              justify-content: space-between;
              width: 600px;

              .block-box {
                &:nth-child(1) {
                  margin-bottom: 16px;
                }

                .title {
                  font-size: 14px;
                  font-family: STSongti-SC-Regular;
                  font-weight: 600;
                  color: #CCCCCC;
                  line-height: 20px;
                  margin-bottom: 14px;

                  span {
                    font-family: Branch-Regular;
                    font-size: 14px;
                    font-weight: 600;
                    line-height: 20px;
                    margin-right: 4px;

                  }
                }

                .content {
                  display: flex;
                }

              }
            }
          }
        }

        .bottom {
          position: absolute;
          bottom: 40px;
          padding: 0 400px 0 0;
          box-sizing: border-box;
          width: 100%;
          display: flex;

          .text {
            font-size: 12px;
            font-family: SourceHanSansCN-ExtraLight;
            font-weight: 200;
            color: #FFFFFF;
            line-height: 18px;
            letter-spacing: 2px;
          }

          >.language {
            font-size: 14px;
            font-family: SourceHanSansCN-Light;
            font-weight: 300;
            color: #FFFFFF;
            line-height: 20px;

            .line {
              margin: 0 12px;
            }
          }
        }
      }
    }
  }
}

.text_color_white {
  color: #fff !important;
}

.text_color_black {
  color: #000 !important;
}
</style>
