// 中文翻译
const cn = {
  banner: {
    title: '贝康护理学校'
  },
  xxjs: {
    title: '学校介绍',
    descTitle: '贝康国际旗下教育品牌<br>专注于母婴护理的护理培训学校',
    desc: '贝康护理学校获得了由国家人力资源和社会保障部授予的民办学校办学许可证 4404023000164，其课程获得美国麻省理工、英国牛津大学等国际名校的医疗专家背书，并由港澳台及内地权威护理博士等专家担任教学顾问及讲师。<br>目前，学校在香港、珠海、深圳、杭州、北京均成立课程研究中心及培训中心，并拥有“全国保健服务标准化技术委员会”授予的“保健服务国家标准培训基地”资格。<br>同时，学校也得到美国国际认证协会 (ACI) 大中华区“国际母婴护理师”的独家培训和运营权，专注于培养达到国际一流水准的母婴护理人才。发展至今、已涵盖了专业护士培训、高级育儿师和高级早教师培训。'
  },
  zykc: {
    title: '专业课程',
    subTitle1: '自有体系培训内部护士输出',
    desc1: '涵盖产妇及新生儿护理 / 母乳哺养护理 / 产后康复及营养 / 婴儿早教等专业课程<br>每年3000名护士通过3轮面试，历经2个月理论及3个月的实操，最终仅筛选20%的<br>护士为会所妈妈进行一对一的服务。',
    subTitle2: '国内独家ACI(美国认证协会) 注册国际母婴护理课 ',
    desc2: '全球163个国家认证，高薪求职绿卡<br>全球专业的母婴护理课程体系<br>贝康护理学校拥有该项目全国独家授权，全权定制培训、教材、师资等体系'
  }
}

// 繁体翻译
const tw = {
  banner: {
    title: '貝康護理學校'
  },
  xxjs: {
    title: '學校介紹',
    descTitle: '貝康國際旗下教育品牌<br>專註於母嬰護理的護理培訓學校',
    desc: '貝康護理學校獲得了由國家人力資源和社會保障部授予的民辦學校辦學許可證4404023000164，其課程獲得美國麻省理工、英國牛津大學等國際名校的醫療專家背書，並由港澳臺及內地權威護理博士等專家擔任教學顧問及講師。<br>現時，學校在香港、珠海、深圳、杭州、北京均成立課程研究中心及培訓中心，並擁有“全國保健服務標準化技術委員會”授予的“保健服務國家標準培訓基地”資格。<br>同時，學校也得到美國國際認證協會（ACI）大中華區“國際母嬰護理師”的獨家培訓和運營權，專注於培養達到國際一流水準的母嬰護理人才。 發展至今、已涵蓋了專業護士培訓、高級育兒師和高級早教師培訓。'
  },
  zykc: {
    title: '專業課程',
    subTitle1: '自有體系培訓內部護士輸出',
    desc1: '涵蓋產婦及新生兒護理 / 母乳哺養護理 / 產後康復及營養 / 嬰兒早教等專業課程<br>每年3000名護士通過3輪面試，歷經2個月理論及3個月的實操，最終僅篩選20%的<br>護士為會所媽媽進行一對一的服務。',
    subTitle2: '國內獨家ACI（美國認證協會）注册國際母嬰護理課',
    desc2: '全球163個國家認證，高薪求職綠卡<br>全球專業的母嬰護理課程體系<br>貝康護理學校擁有該項目全國獨家授權，全權定制培訓、教材、師資等體系'
  }
}
// 英文翻译
const en = {
  banner: {
    title: '' // PRIMECARE SCHOOL
  },
  xxjs: {
    title: '', // 学校介绍
    descTitle: 'The Educational Brand under PRIMECARE INTERNATIONAL<br>A Specialized Nursing Training School Focused on Maternal and Baby Care',
    desc: 'PRIMECARE SCHOOL holds a private school operating license issued by the Ministry of Human Resources and Social Security of the People’s Republic of China, with license number 4404023000164. Our courses are endorsed by medical experts from internationally renowned institutions such as MIT and Oxford University. Our educational consultants and lecturers consist of experts who are authoritative nursing PhD holders hailing from Hong Kong, Macao, Taiwan, and mainland China. <br>Currently, the School has established curricula research centres and training facilities in key locations including Hong Kong, Zhuhai, Shenzhen, Hangzhou, and Beijing. We also hold the designation of “National Health Service Standard Training Base”, which is bestowed by the “National Health Service Standardization Technical Committee”.<br>Furthermore, PRIMECARE SCHOOL holds exclusive training and operational rights for “International Maternal and Child Care Nurse” in the Greater China region, which are granted by the American Certification Institute (ACI). Our primary focus is on nurturing maternal and baby care professionals to meet the highest international standards. Our offers cover training for professional nurses, advanced parenting educators, and senior early childhood education experts.'
  },
  zykc: {
    title: '', // SPECIALIZED COURSES
    subTitle1: 'A Proprietary Scheme for In-house Nurse Training and External Employment',
    desc1: 'Our specialized courses encompass Maternal and Neonatal Care, Breastfeeding Care, Post-partum Health Recovery and Nutrition, and Infant Early Education.<br>Each year, 3,000 nurses undergo three rounds of interviews, two months of theoretical training, and three months of practical training. At the end, only the top 20% of candidate nurses are selected to provide one-on-one services to mothers in our centre.',
    subTitle2: 'Exclusive Local ACI (American Certification Institute) Registered International Maternal and Child Care Course', // 国内独家ACI(美国认证协会) 注册国际母婴护理课
    desc2: 'Recognized in 163 countries globally, a green card to high-paying jobs<br>An international professional scheme for maternal and child care course.<br>PRIMECARE SCHOOL holds the project\'s exclusive license in China, with full authorization to develop systems such as training, textbooks, and teaching qualifications.'
  }
}
export { cn, tw, en }
