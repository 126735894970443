<template>
  <div class="page-prev">
    <div class="line-x"></div>
    <div class="line-y"></div>
  </div>
  <div class="page-next">
    <div class="line-x"></div>
    <div class="line-y"></div>
  </div>
</template>

<script setup>
</script>
<style lang="less" scoped>
.page-prev,
.page-next {
  border: 1px solid #fff;
  width: 66px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.5s;
  // margin: 0 30px;

  .line {

    &-x {
      width: 12px;
      height: 1px;
      background-color: #fff;
      position: absolute;
      transform-origin: bottom left;
      transform: rotateZ(-45deg);
    }

    &-y {
      width: 12px;
      height: 1px;
      background-color: #fff;
      position: absolute;
      transform-origin: top left;
      transform: rotateZ(45deg);
    }
  }

  &:hover {
    background-color: #fff;

    .line {

      &-x {
        background-color: #000;
      }

      &-y {
        background-color: #000;
      }
    }
  }

}

.swiper-button-disabled {
  opacity: 0.5;
  cursor: auto;

  &:hover {
    background-color: #000;

    .line {

      &-x {
        background-color: #fff;
      }

      &-y {
        background-color: #fff;
      }
    }
  }
}

.page-next {
  transform: rotateZ(180deg);
  margin-left: -1px;
}
</style>
