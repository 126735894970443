import Language from '../pages'
export default {
  message: {
    hello: '繁体你好'
  },
  // 头部
  header: {
    brand: {
      title: '品牌中心',
      ppgs: '品牌故事',
      mxzx: '明星之選',
      ppsj: '品牌瞬間',
      xwzx: '新聞中心'
    },
    store: {
      title: '門店探索',
      list: [
        '杭州柏悅', '北京半島', '成都華爾道夫', '北京瑰麗', '深圳萊佛士', '上海鏞舍公寓', '廣州瑰麗府邸', '北京璞瑄', '香港HUMANSA', '深圳文華東方', '上海外灘華爾道夫', '上海蘇寧寶麗嘉', '廈門七尚', '杭州秋水山莊', '上海愚園獨棟洋房', '香港四季'
      ],
      rzjd: {
        title1: '聖貝拉母嬰護理中心入駐酒店',
        title2: '母嬰護理中心獨棟系列'
      }
    },
    zyhh: {
      title: '專業呵護',
      hs1d1: '24h護士一對一',
      hlms: '護理模式',
      mmhh: '媽媽呵護',
      bbhh: '寶寶呵護',
      ycqzqfw: 'LTV孕產全周期服務',
      ysly: '藝術療養'
    },
    yzss: {
      title: '月子膳食',
      sjdsstl: '四階段膳食調理',
      wdssyz: '五大膳食原則'
    },
    chxf: {
      title: '產後修復',
      chyxs: 'STB產後研修所',
      zycp: 'STB自研產品'
    },
    country: {
      cn: '中國大陸',
      hk: '中國香港',
      sg: '新加坡'
    }
  },
  // 底部
  footer: {
    qgmd: '全球門店',
    ppzx: '品牌中心',
    hyqy: '會員權益',
    zyhh: '專業呵護',
    yjyys: '予家',
    bkhlxx: '貝康護理學校',
    lxwm: {
      title: '聯系我們',
      name: '杭州貝康健康科技集團有限公司（總部）',
      address: '地址：杭州市蕭山區建設二路666號信息港六期6幢1層',
      tel: '電話：',
      email: '商務合作：',
      mtlx: '媒體聯系：'
    },
    zskf: {
      title: '專屬客服',
      time: '週一至周日 ',
      rzzx: '入住咨詢:'
    },
    gzjh: '工作機會',
    sidebar: {
      title1: '聖貝拉母嬰護理中心入駐酒店',
      title2: '母嬰護理中心獨棟系列'
    }
  },
  comp: {
    xxgd: '向下滾動'
  },
  // 首页
  home: Language.homeTw,
  brand: {
    story: Language.brandStoryTw,
    ...Language.brandTw
  },
  major: {
    hours: Language.majorHoursTw,
    cycle: Language.majorCycleTw,
    art: Language.majorArtTw,
    join: Language.majorJoinTw,
    timer: Language.majorTimerTw,
    ...Language.majorTw
  },
  month: Language.monthFoodTw,
  school: Language.schoolTw,
  store: {
    detail: Language.storeDetailTw,
    ...Language.storeTw
  },
  repair: Language.postpartumRepairTw,
  vip: Language.vipTw,
  job: Language.jobHuntTw,
  yujia: Language.yujiaTw
}
