// 中文翻译
const cn = {
  fjxj: { title: '房间细节' },
  tcjg: {
    title: '套餐价格',
    price: 'RMB',
    yuan: '',
    tian: '天'
  },
  gl: '概览',
  tc: '套餐',
  pt: '配套',
  zsdz: {
    title: '专属定制',
    value: ['24小时护士1v1照护', 'LTV孕产全周期服务', '酒店主厨现食现做月子餐', '广禾堂秘制月子茶饮', '研创艺术疗养课程']
  },
  lzfw: {
    title: '礼赠服务',
    value: ['一对一私人专属服务群', '孕期营养膳食及调养定制/咨询', '专家定期孕期健康指导', '产后护士陪产照护服务', '豪华专车免费用车2次(限上海地区)'],
    value2: ['新生儿拍摄', '艺术疗养课程', '1V1瑜伽私教课', '宝宝满月派对', '宝宝满月胎发'],
    value3: ['离馆后护理指导&营养指南', '会员积分兑换商城礼', '会员限定节日回馈礼赠']
  },
  gdyp: {
    title: '固定用品',
    value: ['StadlerForm加湿器(瑞士) 1台', 'Fossflakes哺乳枕(丹麦) 1个', 'Paramount婴儿床(日本) 1套', 'Rotho洗澡盆架(德国) 1个', 'SnowBear恒温水壶(韩国) 1个', 'PhilipsAvent温奶器(英国) 1个', 'Haier消毒烘干锅1个'],
    value2: ['Omron智能加压血压仪(日本) 1个', 'Braun耳温枪(德国)1个', 'Yuwell听诊器 1个', 'SENSSUN婴儿秤1个', 'Benetree抚触床1台', 'MI米兔早教机 1个', 'Yottoy月子瑜伽垫(英国) 1个'],
    value3: ['Tiger月子茶保温杯(日本) 2个', 'SAINT BELLA定制婴儿枕 1对', 'SAINT BELLA定制黑白卡 1盒', 'SAINT BELLA定制宝宝衣架 2个', 'SAINT BELLA x UCCA新生儿腋下游泳圈 1个', 'SAINT BELLA艺术疗养手册 1本', 'SAINT BELLA夜读诗选手册 1本']
  },
  bbyp: {
    title: '宝宝用品',
    value: ['The Little Butterfly新生儿洗发沐浴露 1瓶', 'The Little Butterfly新生儿抚触油1瓶', 'The Little Butterfly新生儿身体乳1瓶', 'Desitin新生儿护臀膏(美国) 1支 ', 'Bondi Wash洗衣液(澳洲) 1瓶', 'Wickle奶瓶清洁剂 1 瓶', 'Pigeon细轴棉签(日本) 1盒'],
    value2: ['Pigeon奶瓶(日本) 1个', 'Dr Browns奶瓶刷(美国)1只', 'Nest Designs新生儿抱被 1 条', 'Nest Designs新生儿口水巾 2 条', 'Nest Designs新生儿浴巾 2 条', 'Nest Designs新生儿洗澡方巾 3 条', 'GOON大王光羽纸尿裤 NB(日本)4包'],
    value3: ['Soondoong顺顺儿新生儿湿巾(韩国) 2 包', 'Nest Designs棉柔巾 2包', 'Babycare隔尿垫 2 包', 'Rotho宝宝浴盆(德国) 1个', 'SAINT BELLA定制亲肤哈衣 2套', 'SAINT BELLA定制宝宝护理日志本 1本', 'SAINT BELLA定制手足印泥 1套']
  },
  mmyp: {
    title: '妈妈用品',
    value: ['LeonorGreyl洗发水(法国) 1瓶', 'Acca Kappa沐浴露(意大利) 1瓶', 'Marvis护齿牙膏(意大利) 1支', 'Lion月子超软牙刷(日本) 1支', 'Always日用卫生巾27cm(美国) 2包'],
    value2: ['Always夜用卫生巾34cm(美国) 1包', 'Lansinoh防溢乳垫(英国) 1盒', 'Babycare一次性产褥垫 1包', 'SAINT BELLA定制亲肤哺乳服2套', 'SAINT BELLA定制月子毛巾1条'],
    value3: ['SAINT BELLA定制月子浴巾1条', 'SAINT BELLA月子鞋2双', 'SAINT BELLA定制满月相册 1本', '*限定入住礼遇', '*限定离馆礼遇']
  }
}
// 繁体翻译
const tw = {
  fjxj: { title: '房間細節' },
  tcjg: {
    title: '套餐價格',
    price: 'RMB',
    yuan: '',
    tian: '天'
  },
  gl: '概覽',
  tc: '套餐',
  pt: '配套',
  zsdz: {
    title: '專屬定製',
    value: ['24小時護士1v1照護', 'LTV孕產全周期服務', '酒店主廚現食現做月子餐', '廣禾堂秘製月子茶飲', '研創藝術療養課程']
  },
  lzfw: {
    title: '禮贈服務',
    value: ['一對一私人專屬服務群', '孕期營養膳食及調養定製/咨詢', '專家定期孕期健康指導', '產後護士陪產照護服務', '豪華專車免費用車2次(限上海地區)'],
    value2: ['新生兒拍攝', '藝術療養課程', '1V1瑜伽私教課', '寶寶滿月派對', '寶寶滿月胎發'],
    value3: ['離館後護理指導&營養指南', '會員積分兌換商城禮', '會員限定節日回饋禮贈']
  },
  gdyp: {
    title: '固定用品',
    value: ['StadlerForm加濕器(瑞士) 1臺', 'Fossflakes哺乳枕(丹麥) 1個', 'Paramount嬰兒床(日本) 1套', 'Rotho洗澡盆架(德國) 1個', 'SnowBear恒溫水壺(韓國) 1個', 'PhilipsAvent溫奶器(英國) 1個', 'Haier消毒烘幹鍋1個'],
    value2: ['Omron智能加壓血壓儀(日本) 1個', 'Braun耳溫槍(德國)1個', 'Yuwell聽診器 1個', 'SENSSUN嬰兒秤1個', 'Benetree撫觸床1臺', 'MI米兔早教機 1個', 'Yottoy月子瑜伽墊(英國) 1個'],
    value3: ['Tiger月子茶保溫杯(日本) 2個', 'SAINT BELLA定製嬰兒枕 1對', 'SAINT BELLA定製黑白卡 1盒', 'SAINT BELLA定製寶寶衣架 2個', 'SAINT BELLA x UCCA新生兒腋下遊泳圈 1個', 'SAINT BELLA藝術療養手冊 1本', 'SAINT BELLA夜讀詩選手冊 1本']
  },
  bbyp: {
    title: '寶寶用品',
    value: ['The Little Butterfly新生兒洗發沐浴露 1瓶', 'The Little Butterfly新生兒撫觸油1瓶', 'The Little Butterfly新生兒身體乳1瓶', 'Desitin新生兒護臀膏(美國) 1支', 'Bondi Wash洗衣液(澳洲) 1瓶', 'Wickle奶瓶清潔劑 1 瓶', 'Pigeon細軸棉簽(日本) 1盒'],
    value2: ['Pigeon奶瓶(日本) 1個', 'Dr Browns奶瓶刷(美國)1只', 'Nest Designs新生兒抱被 1 條', 'Nest Designs新生兒口水巾 2 條', 'Nest Designs新生兒浴巾 2 條', 'Nest Designs新生兒洗澡方巾 3 條', 'GOON大王光羽紙尿褲 NB(日本)4包'],
    value3: ['Soondoong順順兒新生兒濕巾(韓國) 2 包', 'Nest Designs棉柔巾 2包', 'Babycare隔尿墊 2 包', 'Rotho寶寶浴盆(德國) 1個', 'SAINT BELLA定製親膚哈衣 2套', 'SAINT BELLA定製寶寶護理日誌本 1本', 'SAINT BELLA定製手足印泥 1套']
  },
  mmyp: {
    title: '妈妈用品',
    value: ['LeonorGreyl洗發水(法國) 1瓶', 'Acca Kappa沐浴露(意大利) 1瓶', 'Marvis護齒牙膏(意大利) 1支', 'Lion月子超軟牙刷(日本) 1支', 'Always日用衛生巾27cm(美國) 2包'],
    value2: ['Always夜用衛生巾34cm(美國) 1包', 'Lansinoh防溢乳墊(英國) 1盒', 'Babycare一次性產褥墊 1包', 'SAINT BELLA定製親膚哺乳服2套', 'SAINT BELLA定製月子毛巾1條'],
    value3: ['SAINT BELLA定製月子浴巾1條', 'SAINT BELLA月子鞋2雙', 'SAINT BELLA定製滿月相冊 1本', '*限定入住禮遇', '*限定離館禮遇']
  }
}

// 英文翻译
const en = {
  fjxj: { title: 'Room Details' },
  tcjg: {
    title: 'Package Price',
    price: 'HKD',
    yuan: ' thousand',
    tian: ' days'
  },
  gl: 'overview',
  tc: 'package',
  pt: 'facilities',
  zsdz: {
    title: 'Exclusive Customization',
    value: ['· 24-hour 1v1 nursing care24-hour 1v1 nursing care', '· LTV full-term pregnancy and labour preparation services', '· Postpartum meals freshly prepared by hotel Executive Chef', '· Guang He Tang postpartum tea from proprietary recipe', '· Art therapy course ']
  },
  lzfw: {
    title: 'Gift Service',
    value: ['· Dedicated one-on-one care team', '· Customized nutritional consultation to support your pregnancy diet', '· Regular pregnancy health guidance from a team of specialists', '· Accompanying nursing care for postpartum check-up service', '· Complimentary use of premium car transport x 2 (restricted to Shanghai area)'],
    value2: ['· Infant photography', '· Art therapy course', '· 1v1 private yoga lessons', '· Full month celebration for your baby', '· Preserving of baby\'s one-month-old fetal hair as keepsake'],
    value3: ['· Post-discharge care instructions and nutrition guidance', '· Redemption of accumulated member points for gifts at malls', '· Seasonal holiday reward gifts for members']
  },
  gdyp: {
    title: 'Fixed Supplies',
    value: ['· Stadler Form Humidifier (Switzerland) - 1 unit', '· FossFlakes Nursing Pillow (Denmark) - 1 piece', '· Paramount Baby Crib (Japan) - 1 set', '· Rotho Bathtub Stand (Germany) - 1 unit', '· SnowBear Thermostatic Kettle (South Korea) - 1 unit', '· Philips Avent Milk Bottle Warmer (United Kingdom) - 1 unit', '· Haier Sterilizing and Drying Pot - 1 unit'],
    value2: ['· Omron Smart Blood Pressure Monitor (Japan) - 1 unit', '· Braun Ear Thermometer (Germany) - 1 unit', '· Yuwell Stethoscope - 1 unit', '· SENSSUN Baby Weighing Scale - 1 unit', '· Benetree Baby Soothing Bed - 1 unit', '· Xiaomi MI Mitu Early Education Machine - 1 unit', '· Yottoy Confinement Yoga Mat (United Kingdom) - 1 unit'],
    value3: ['· Tiger Confinement Tea Thermo Cups (Japan) - 2 units', '· SAINT BELLA Custom Baby Pillows - 1 pair', '· SAINT BELLA Custom Black and White Cards - 1 box', '· SAINT BELLA Custom Baby Wear Hangers - 2 pieces', '· SAINT BELLA x UCCA New-born Underarm Swimming Ring - 1 unit', '· SAINT BELLA Art Therapy Handbook - 1 copy', '· SAINT BELLA A Book of Selected Night-Time Poetries - 1 copy']
  },
  bbyp: {
    title: 'Baby Supplies',
    value: ['· The Little Butterfly New-born Shampoo and Bath Gel - 1 bottle', '· The Little Butterfly New-born Massage Oil - 1 bottle', '· The Little Butterfly New-born Body Lotion - 1 bottle', '· Desitin New-born Diaper Rash Cream (USA) - 1 tube', '· Bondi Wash Laundry Detergent (Australia) - 1 bottle', '· Wickle Milk Bottle Cleaner - 1 bottle', '· Pigeon Thin-Stick Cotton Swabs (Japan) - 1 box'],
    value2: ['· Pigeon Baby Bottle (Japan) - 1 unit', '· Dr. Brown\'s Bottle Brush (USA) - 1 unit', '· Nest Designs New-born Swaddle Blanket - 1 piece', '· Nest Designs New-born Drool Bibs - 2 pieces; ', '· Nest Designs New-born Bath Towels - 2 pieces', '· Nest Designs New-born Hand Towels - 3 pieces', '· GOO.N NB Baby Diapers (Japan) - 4 packs'],
    value3: ['· Soondoong Baby Wipes for Newborns (South Korea) - 2 packs', '· Nest Designs Cotton Towels - 2 packs', '· Babycare Diaper Pads - 2 packs', '· Rotho Baby Bathtub (Germany) - 1 unit', '· SAINT BELLA Custom Skin-Friendly Babysuit - 2 sets', '· SAINT BELLA Custom Baby Care Journal - 1 book', '· SAINT BELLA Custom Hand-print and Footprint Clay Kit - 1 set']
  },
  mmyp: {
    title: 'Mom Supplies',
    value: ['· Leonor Greyl Shampoo (France) - 1 bottle', '· Acca Kappa Shower Gel (Italy) - 1 bottle', '· Marvis Teeth-Shielding Toothpaste (Italy) - 1 tube', '· Lion Confinement Super Soft Toothbrush (Japan) - 1 piece', '· Always Day-Use Sanitary Pads 27cm (USA) - 2 packs'],
    value2: ['· Always Overnight Sanitary Pads 34cm (USA) - 1 pack', '· Lansinoh Overflow-Proof Nursing Pads (UK) - 1 box', '· Babycare Disposable Maternity Pads - 1 pack', '· SAINT BELLA Custom Skin-Friendly Skin-Friendly Nursing Wear - 2 sets', '· SAINT BELLA Custom Confinement Towel - 1 piece'],
    value3: ['· SAINT BELLA Custom Confinement Bath Towel - 1 piece', '· SAINT BELLA Confinement Shoes - 2 pairs', '· SAINT BELLA Custom One-Month-Old Photo Album - 1 piece', '*Exclusive Check-in Treats', '*Exclusive Discharge Perks']
  }
}
export { cn, tw, en }
