import store from '../store/index'

/**
 * 动态设置页面 根元素的 fontSize
 * 页面宽度 <= 750 默认为它是手机端
 */
(function (doc, win) {
  const docEl = doc.documentElement
  const resizeEvt = 'orientationchange' in window ? 'orientataionchange' : 'resize'
  const recalc = function () {
    const clientWidth = docEl.clientWidth
    if (!clientWidth) return
    store.commit('setMobile', Math.floor(docEl.getBoundingClientRect().width) <= 750)
    if (Math.floor(docEl.getBoundingClientRect().width) <= 750) {
      // docEl.style.fontSize = 100 * (clientWidth / 750) + 'px'
      docEl.style.fontSize = clientWidth * 0.256 + 'px'
    } else {
      docEl.style.fontSize = docEl.clientWidth / 10 + 'px'
    }
  }

  if (!doc.addEventListener) return
  win.addEventListener(resizeEvt, recalc, false)
  doc.addEventListener('DOMContentLoaded', recalc, false)
})(document, window)
