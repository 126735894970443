<template>
  <footer class="footer">
    <div class="wrap">
      <div class="block1 hoverLine">
        <div class="block">
          <h3 class="titleCn" @click="setSidebar">
            {{ $t('footer.qgmd') }}
          </h3>
          <h3 class="titleEn" v-show="!langHide()">FIND A STORE</h3>
        </div>
        <div class="block">
          <router-link to="/vip">
            <h3 class="titleCn">
              {{ $t('footer.hyqy') }}
            </h3>
          </router-link>
          <h3 class="titleEn" v-show="!langHide()">SAPPHIRE UNION</h3>
        </div>
      </div>
      <div class="block1 hoverLine">
        <div class="block">
          <router-link to="/brand">
            <h3 class="titleCn">
              {{ $t('footer.ppzx') }}
            </h3>
          </router-link>
          <h3 class="titleEn" v-show="!langHide()">BRAND CENTER</h3>
        </div>
        <div class="block">
          <router-link to="/major-care">
            <h3 class="titleCn">
              {{ $t('footer.zyhh') }}
            </h3>
          </router-link>
          <h3 class="titleEn" v-show="!langHide()">PROFESSIONAL CARE</h3>
        </div>
      </div>
      <div class="block1 hoverLine">
        <div class="block">
          <router-link to="/yujia">
            <h3 class="titleCn"> {{ $t('footer.yjyys') }}</h3>
          </router-link>
          <h3 class="titleEn" v-show="!langHide()">PRIMECARE FAMILY</h3>
        </div>

        <!-- <div class="block">
          <router-link to="/school">
            <h3 class="titleCn">
              {{ $t('footer.bkhlxx') }}
            </h3>
          </router-link>
          <h3 class="titleEn" v-show="!langHide()">PRIMECARE SCHOOL</h3>
        </div> -->
      </div>
      <div class="block3">
        <div class="block">
          <h3 class="titleCn"> {{ $t('footer.lxwm.title') }}</h3>
          <h3 class="titleEn" v-show="!langHide()">CONTACT US</h3>
        </div>
        <p class="value"> {{ $t('footer.lxwm.name') }}</p>
        <p class="value"> {{ $t('footer.lxwm.address') }}</p>
        <p class="value"> {{ $t('footer.lxwm.tel') }} {{ $store.state.system.companyTel }} </p>
        <p class="value"> {{ $t('footer.lxwm.email') }} {{ $store.state.system.companyEmail }} </p>
        <p class="value"> {{ $t('footer.lxwm.mtlx') }} {{ $store.state.system.mediaEmail }} </p>
      </div>
      <div class="block4">
        <div class="block" @click="openFeedback">
          <h3 class="titleCn" :class="{ divHoverLine: NonChina }">{{ $t('footer.zskf.title') }}</h3>
          <h3 class="titleCn" :class="{ divHoverLine: NonChina }"><span style="font-family: Branch-Regular;">{{
            $store.state.system.serviceTel
          }}</span></h3>
          <h3 class="desc" :class="{ divHoverLine: NonChina }">{{ $t('footer.zskf.time') }} {{
            $store.state.system.serviceTime }}</h3>
          <h3 class="desc divHoverLine" v-if="NonChina">{{ $t('footer.zskf.rzzx') }} info@saintbella.com</h3>
        </div>

        <div class="block hoverLine">
          <router-link to="/job-hunt">
            <h3 class="titleCn">{{ $t('footer.gzjh') }}</h3>
            <h3 class="titleEn">
              {{ $store.state.system.jobEmail }}
            </h3>
          </router-link>
        </div>
      </div>
      <div class="block5">
        <img class="logo" :src="`${$store.state.ASSETSURL}images/pricemare-sbl.png`" alt="">
        <!-- <div class="line"></div> -->
        <div class="wrap">
          <div class="item" :style="{ cursor: item.link ? 'pointer' : '' }" v-for="item in icons" :key="item.value"
            @click="item.link && useNav(item.link)">
            <img class="img-hover" :src="`${$store.state.ASSETSURL}images/${item.src}${item.hover}${item.suffix}`" alt="">
            <img class="img" :src="`${$store.state.ASSETSURL}images/${item.src}${item.suffix}`" alt="">
            <img class="after" :src="`${$store.state.ASSETSURL}images/${item.code}`" alt="">
            <h4 class="name">{{ item.value }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <p class="domain">
      <span @click="useNav('https://beian.miit.gov.cn/')" style="cursor: pointer">浙ICP备20027399号</span>
      <span>Copyright © 2016-2022 All Right Reserved</span>
    </p>
  </footer>
  <Sidebar :showSidebar="showSidebar" @setSidebar="setSidebar" />
</template>
<script setup>
import { ref, inject, computed } from 'vue'
import { langHide, useNav, getCountry } from '@/util'
import Sidebar from './sidebar.vue'
const showSidebar = ref(false)
const icons = [
  { src: '/xhs', hover: '-hover', suffix: '.png', value: '小红书', code: '/xhs-code.png' },
  { src: '/gzh', hover: '-hover', suffix: '.png', value: '公众号', code: '/gzh-code.jpg' },
  { src: '/xcx', hover: '-hover', suffix: '.png', value: '小程序', code: '/xcx-code.jpg' },
  { src: '/ins', hover: '-hover', suffix: '.png', value: 'Instagram', code: '/ins-code.png', link: 'https://www.instagram.com/saintbella.official/' }
]

const FeedBackRef = inject('FeedBackRef')

const openFeedback = () => {
  FeedBackRef.value.openFeedback()
}

// 不在中国
const NonChina = computed(() => getCountry() !== 'CN' && getCountry() !== 'HK')
const setSidebar = (status = false) => {
  showSidebar.value = status
}
</script>

<style lang="less" scoped>
.footer {
  background-color: #fff;
  z-index: 9;
}

.divHoverLine {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    bottom: -1px;
    width: 0;
    border-bottom: 1px solid #000;
    transition: all 0.5s;
  }

  &:hover {
    &::before {
      width: 100%;
    }

    cursor: pointer;
  }
}

.footer {
  padding: 100px 120px 112px;
  border-top: 1px solid #000;

  .wrap {
    display: flex;
    justify-content: space-between;

    .block {
      .titleCn {
        font-size: 18px;
        font-family: STSongti-SC-Bold, STSongti-SC;
        font-weight: 400;
        color: #000000;
        line-height: 25px;
        width: fit-content;
        position: relative;
      }

      .titleEn {
        font-size: 12px;
        font-family: Branch-Regular, Branch;
        font-weight: 400;
        color: #000000;
        line-height: 17px;
        margin-top: 6px;
        letter-spacing: 1px;
      }

      .desc {
        font-size: 14px;
        font-family: SourceHanSansCN-ExtraLight, SourceHanSansCN;
        font-weight: 200;
        color: #000000;
        line-height: 20px;
        margin-top: 10px;
      }
    }

    .hoverLine {
      .titleCn {
        &::before {
          content: '';
          position: absolute;
          bottom: -1px;
          width: 0;
          border-bottom: 1px solid #000;
          transition: all 0.5s;
        }

        &:hover {
          &::before {
            width: 100%;
          }

          cursor: pointer;
        }
      }
    }

    .block1 {
      >.block {
        margin-bottom: 50px;
      }
    }

    .block2 {
      >.block {
        margin-bottom: 20px;
      }
    }

    .block3 {
      .desc {
        margin-bottom: 16px;
        font-weight: 300;
        color: #000;
      }

      .value {
        font-size: 14px;
        font-family: SourceHanSansCN-ExtraLight;
        font-weight: 200;
        color: #000000;
        line-height: 20px;
        margin-bottom: 6px;
        max-width: 340px;

        &:nth-child(2) {
          margin-top: 16px;
          font-size: 14px;
          font-weight: 300;
          color: #000000;
          line-height: 20px;
        }
      }
    }

    .block4 {
      .block {
        margin-bottom: 20px;

        .titleCn:nth-child(2) {
          margin-top: 4px;
        }

        .desc {
          margin-top: 4px;
        }

        .email {
          font-size: 14px;
          font-family: Branch-Regular, Branch;
          font-weight: 400;
          color: #000000;
          line-height: 20px;
          letter-spacing: 1px;
          margin-top: 6px;
          transition: all 0.5s;

          &:hover {
            opacity: 0.7;
          }
        }
      }

    }

    .block5 {
      margin-left: 50px;
      // display: flex;
      // flex-direction: column;
      // align-items: center;

      .logo {
        width: 220px;
        margin: 0 18px 0 2px;
      }

      // .line {
      //   width: 220px;
      //   height: 1px;
      //   background: #BFBFBF;
      //   margin: 30px 0 40px;
      // }

      .item {
        // cursor: pointer;
        position: relative;
        margin-top: 50px;

        .after {
          transition: all 0.3s;
          transform-origin: right center;
          width: 120px;
          height: 120px;
          transform: scale(0);
          position: absolute;
          background-size: 120px 120px;
          z-index: 9;
          left: -120px;
          top: -42px;
        }

        &:hover .after {
          transform: scale(1);
        }

        .img {
          width: 35px;
          height: 35px;
          margin: 0 auto;
          transition: all 0.3s;

          &-hover {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 35px;
            height: 35px;
            opacity: 0;
            transition: all 0.3s;
            z-index: 9;

            &:hover {
              opacity: 1;

              &+.img {
                opacity: 0;
              }
            }
          }
        }

        .name {
          font-size: 12px;
          font-family: SourceHanSansCN-Light, SourceHanSansCN;
          font-weight: 300;
          color: #000000;
          line-height: 18px;
          text-align: center;
          margin-top: 8px;
        }
      }
    }
  }

  .line {
    width: 100%;
    // height: 1px;
    margin: 100px 0 13px;
    border-bottom: 1px solid #000;
    opacity: 0.4;
    // background: #000;
  }

  .domain {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 12px;
      font-family: SourceHanSansCN-ExtraLight, SourceHanSansCN;
      font-weight: 200;
      color: #000000;
      line-height: 18px;
      &:nth-child(1):hover{
        cursor: pointer;
      }
    }
  }
}

@media screen and (max-width: 751px) {
  .footer {
    padding: 100px 40px 70px;

    .wrap {
      flex-wrap: wrap;

      .block {

        .titleCn {
          color: rgb(0, 0, 0);
          font-family: STSongti-SC-Regular;
          font-size: 30px;
          line-height: 42px;
          letter-spacing: 2px;
          font-weight: bold;

          span {
            font-size: 24px;
          }
        }

        .titleEn {
          color: rgb(0, 0, 0);
          font-family: Branch-Regular;
          font-size: 20px;
          font-weight: 400;
          line-height: 29px;
          margin-top: 6px;
        }

        .desc {
          font-size: 24px;
          font-family: SourceHanSansCN-ExtraLight, SourceHanSansCN;
          font-weight: 200;
          color: #000000;
          line-height: 24px;
          margin-top: 14px;
        }
      }

      .block1 .block {
        margin-bottom: 80px !important;
        color: rgb(0, 0, 0);
        font-size: 24px;
        font-weight: 400;
      }

      .block3 {
        margin-bottom: 80px;

        .value {
          color: rgb(0, 0, 0);
          font-size: 24px;
          margin-bottom: 20px;
          line-height: 24px;
          max-width: none;

          &:nth-child(2) {
            font-weight: 400;
            margin-top: 20px;
            font-size: 24px;
          }
        }
      }

      .block4 {
        .block {
          margin-bottom: 80px;
        }
      }

      .block5 {
        border-top: 1px solid rgb(198, 198, 198);
        padding: 121px 85px 88px;
        margin: 0;

        .logo {
          margin: 0 auto;
          width: 400px;
          height: 104px;
        }

        .wrap {
          .item {
            // flex: 1;
            width: fit-content;
            justify-content: space-between;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            &::after {
              // left: 0;
              // right: 0;
              // margin: 0 auto;
              // top: -120px;
              // transform-origin: top center;
              // display: none;
            }

            .img {
              width: 54px;
              height: 54px;
              margin: 0;
            }

            .img-hover {
              width: 54px;
              height: 54px;
            }

            .name {
              font-size: 22px;
              line-height: 33px;
              margin-top: 23px;
            }
          }
        }
      }

      >div {
        width: 100%;
      }
    }

    >.line {
      width: 100%;
      width: 670px;
      height: 0.5px;
      background: rgb(0, 0, 0);
      margin: 0 0 30px;
    }

    .domain span {
      color: rgb(0, 0, 0);
      font-size: 22px;
      font-weight: 100;
      line-height: 33px;
    }
  }
}
</style>
