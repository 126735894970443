// 中文翻译
const cn = {
  xxgd: '向下滚动',
  jkts: '即刻探索'
}
// 繁体翻译
const tw = {
  xxgd: '向下滾動',
  jkts: '即刻探索'
}
// 英文翻译
const en = {
  xxgd: 'DOWN',
  jkts: 'Explore Now'
}
export { cn, tw, en }
