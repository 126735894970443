import router from '@/router'
import store from '@/store'
import { ref, onUnmounted, watch } from 'vue'
/**
 * @param {string|number} url 跳转路径
 * @param {string} type 跳转类型 nav | replace | go
 */
const useNav = (url = '', type = 'nav') => {
  let isHttp
  if (typeof url === 'string') {
    isHttp = url.search('https://') >= 0 || url.search('http://') >= 0
  }
  if (isHttp) {
    return window.open(url)
  }
  if (type === 'nav') {
    router.push(url)
  }
  if (type === 'go') {
    router.go(url)
  }
}

/**
 * 根据banner设置头部样式
 * @param {HTMLElement} bannerRef 监听的dom元素
 * @param {object} viewTheme 在视口内的样式
 * @param {object} updateTheme 离开视口的样式
 */
const useBannerTheme = (bannerRef, viewTheme = { textColor: true, logo: 'white' }, updateTheme = { bgColor: '#1e2046', textColor: true, logo: 'white' }) => {
  const dom = ref(bannerRef.value)
  const { isView, residueRatio } = useIsView(dom.value)
  store.commit('setHeaderTheme', viewTheme)
  watch(() => isView.value, (isView) => isView ? store.commit('setHeaderTheme', viewTheme) : store.commit('setHeaderTheme', updateTheme))
  return {
    process: residueRatio
  }
}

/**
 * 监听dom元素是否出现在视口内
 * @param {HTMLElement} element 监听的element元素
 * @returns {boolean} 监听结果
 * @returns {number} 离开进度
 */
const useIsView = (element) => {
  const isView = ref(false)
  const residueRatio = ref(0)
  const dom = ref(element)

  const io = new IntersectionObserver((entries) => {
    isView.value = entries[0].isIntersecting
    residueRatio.value = entries[0].intersectionRatio
  }, {
    threshold: [0, 0.25, 0.5, 0.75, 1]
  })
  io.observe(dom.value)
  onUnmounted(() => io.unobserve(dom.value))
  return { isView, residueRatio }
}

export {
  useNav,
  useBannerTheme,
  useIsView
}
