// 中文翻译
const cn = {
  title: '圣贝拉妈妈入住指南',
  desc: '欢迎来到圣贝拉女王修炼学院，作为萌新妈妈的你，请认真看完以下内容',
  value1: ['入学第一天，请您来到前台办理入学手续，当天还需要办理两份押金', '进入学院的每一个行李，我们都会经过360度紫外线消毒，您寄存的每一件物品，我们都会用心记录', '入住期间，我们会为您和宝宝提供全套世界顶级母婴用品', '入住以后，我们每日都会进行客房清洁，每周更换床单，每周对客房紫外线消毒。当日访客人数多于5人时，将增加消毒次数', '请注意，馆内人员，谢绝一切现金、红包、礼品', '室内禁止吸烟，包含电子烟，也禁止使用外带大功率电器', '入住过程中，请不要将垃圾堆放在门口，也不要将餐盘直接放在地面上', '如果您需要房间清洁，可以拨打酒店前台电话，酒店提供24小时服务', '我们的访客开放时间是10:00-17:00，一次探访人数不能超过3人，探访时间40分钟，可以有一人陪住', '上午9点，我们将提供衣服免费清洗服务，房务管家将哺乳衣进行清洗、烘干、消毒；宝宝衣物由护士手工清洗，下午6点前送回客房；其他生活衣物或家属衣物请自行清洗或联系酒店客房服务有偿清洗，避免交叉感染及衣物损坏染色'],
  value2: ['萌新妈妈外出还需要请假，家属在入住期间可以免费使用酒店健身房和泳池，可以享受餐食部分的优惠待遇', '作为萌新妈妈的你，如果还有任何问题，都可以拨打女王服务热线400-106-1080，在线时间是上午 9 点至晚上 9 点', '我们将安排护士为您和宝宝提供护理服务，每日一次护理长查房，客服管家提供日常生活服务，产康顾问提供产后修复服务，每周一次儿科专家查房，产科专家查房，中医师查房', '每日护士中餐时间30分钟，晚餐及洗澡时间一小时，护士交接班时间为8点半，时长一小时，离开客房会主动告知原因', '入住 3日内，产康顾问将对您进行身体评估，并量身定制产后修复方案', '入住第一周，每日护士为您提供免费姜水泡脚及擦身服务', '在馆期间，每日上午10点前，管家将为您提供新鲜养生茶饮，护士会在房内每日为您播放音乐疗养', '如果您有任何忌口，可以在入住当天告知我们。为了保证饮食安全，馆内不建议食用外带餐食，餐食在送达后15 分钟内请尽快食用', '我们还为您精心准备了超 ins 满月party、新生儿写真拍摄、宝宝理胎发服务各一次']
}
// 繁体翻译
const tw = {
  title: '聖貝拉媽媽入住指南',
  desc: '歡迎來到聖貝拉女王修煉學院，作為萌新媽媽的你，請認真看完以下內容',
  value1: ['入學第一天，請您來到前臺辦理入學手續，當天還需要辦理兩份押金', '進入學院的每一個行李，我們都會經過360度紫外線消毒，您寄存的每一件物品，我們都會用心記錄', '入住期間，我們會為您和寶寶提供全套世界頂級母嬰用品', '入住以後，我們每日都會進行客房清潔，每周更換床單，每周對客房紫外線消毒。當日訪客人數多於5人時，將增加消毒次數', '請註意，館內人員，謝絕一切現金、紅包、禮品', '室內禁止吸煙，包含電子煙，也禁止使用外帶大功率電器', '入住過程中，請不要將垃圾堆放在門口，也不要將餐盤直接放在地面上', '如果您需要房間清潔，可以撥打酒店前臺電話，酒店提供24小時服務', '我們的訪客開放時間是10:00-17:00，一次探訪人數不能超過3人，探訪時間40分鐘，可以有一人陪住', '上午9點，我們將提供衣服免費清洗服務，房務管家將哺乳衣進行清洗、烘幹、消毒；寶寶衣物由護士手工清洗，下午6點前送回客房；其他生活衣物或家屬衣物請自行清洗或聯系酒店客房服務有償清洗，避免交叉感染及衣物損壞染色'],
  value2: ['萌新媽媽外出還需要請假，家屬在入住期間可以免費使用酒店健身房和泳池，可以享受餐食部分的優惠待遇', '作為萌新媽媽的你，如果還有任何問題，都可以撥打女王服務熱線400-106-1080，在線時間是上午 9 點至晚上 9 點', '我們將安排護士為您和寶寶提供護理服務，每日一次護理長查房，客服管家提供日常生活服務，產康顧問提供產後修復服務，每周一次兒科專家查房，產科專家查房，中醫師查房', '每日護士中餐時間30分鐘，晚餐及洗澡時間一小時，護士交接班時間為8點半，時長一小時，離開客房會主動告知原因', '入住 3日內，產康顧問將對您進行身體評估，並量身定製產後修復方案', '入住第一周，每日護士為您提供免費姜水泡腳及擦身服務', '在館期間，每日上午10點前，管家將為您提供新鮮養生茶飲，護士會在房內每日為您播放音樂療養', '如果您有任何忌口，可以在入住當天告知我們。為了保證飲食安全，館內不建議食用外帶餐食，餐食在送達後15 分鐘內請盡快食用', '我們還為您精心準備了超 ins 滿月party、新生兒寫真拍攝、寶寶理胎發服務各一次']
}
// 英文翻译
const en = {
  title: '', // CHECK-IN GUIDE
  desc: 'Welcome to the SAINT BELLA Queen Training Academy. As a new mum, please read the following carefully:',
  value1: ['On the first day of enrolment, please come to the front desk to complete the enrolment procedures. Two deposits are also required on the same day.', 'Every piece of luggage entering the Academy will undergo 360-degree UV disinfection. Every item you store here will be carefully recorded.', 'During your stay, we will provide you and your baby with a full set of world-class mother and baby products.', 'After check-in, we will clean the room daily, change the sheets weekly, and disinfect the room with UV light every week. If the number of visitors exceeds 5 people on the same day, we will increase the number of disinfections.', 'Please note that any cash, red envelopes, and gifts are not accepted by our staff members.', 'Smoking is prohibited indoors, including e-cigarettes. Use of high-power electrical appliances brought from outside is also prohibited.', 'Please do not leave trash at the door during your stay, and do not place meal trays directly on the floor.', 'If you need room cleaning, you can call the hotel front desk phone. The hotel provides 24-hour service.', 'Our visiting hours are from 10:00 a.m. to 5:00 p.m. The number of visitors cannot exceed 3 people at a time, and the visit time is limited to 40 minutes. One person can accompany you overnight during your stay.', 'At 9:00 a.m., we will provide free laundry service, and the housekeeper will wash, dry, and disinfect the nursing clothes; the baby’s clothes are manually washed by the nurse and returned to the guest room before 6:00 pm; please clean other household clothes or family clothes yourself or contact the hotel room service for paid laundry service to avoid cross-contamination and damage and dyeing of clothes.'],
  value2: ['Kindly inform our staff and ask for leave if you want to go out. During your stay, your family members can use the hotel gym and swimming pool for free and enjoy discounts on selected meals.', 'As a new mother, if you have any questions, you can call the Queen’s service hotline at 400-106-1080. The operating hours are from 9:00 am to 9:00 pm.', 'We will arrange nurses to provide nursing services for you and your baby. The head nurse will conduct daily rounds for each mother. Customer service butlers provide services for everyday needs and postpartum health consultants will provide postpartum recovery services. Paediatric experts, obstetric experts, and traditional Chinese medicine doctors will conduct rounds once a week.', 'The nurse has 30 minutes for lunch and one hour for dinner and bathing. The nurse shift change is at 8:30 p.m., and it lasts for one hour. They will inform you should they need to leave the room.', 'Within 3 days of check-in, a postpartum health consultant will conduct a physical assessment on you and provide a customized a postpartum recovery plan for you.', 'During the first week of check-in, nurses will provide you with complimentary ginger foot bath and sponge bath service every day.', 'During your stay, butlers will provide you with fresh tonic teas before 10:00 a.m. daily. Nurses will play Music Therapy in your room every day.', 'If you have any dietary restrictions, please inform us on the day of check-in. To ensure food safety, it is not recommended to consume take-out food in the room. To maintain the highest level of food safety, it is recommended to eat within 15 minutes after receiving your meal.', 'We have also thoughtfully planned an "Instagram-worthy” full month celebration party, new-born photography, and baby hairdressing service for you.']
}

export { cn, tw, en }
