
import { request } from '@/util/request'

/**
 * 获取banner图列表
 */
export const _getBannerList = (params) => {
  return request({
    method: 'get',
    url: '/Index/bannerList',
    params
  })
}

/**
 * 获取系统设置信息
 */
export const _getSystemInfo = (params) => {
  return request({
    method: 'get',
    url: '/Index/getSetup',
    params
  })
}

/**
 * 获取微信sdk配置信息
 */
export const _getWxJssdkConfig = (data) => {
  return request({
    method: 'post',
    url: '/Index/getWechatConfig',
    data
  })
}

/**
 * 百度地图 -- 不支持海外
 * ip获取定位接口
 */
export const _getIpLocation = (data) => {
  return request({
    method: 'post',
    url: '/Index/ipLocation',
    data
  })
}

/**
 * 埃文科技 -- 支持海外
 * ip获取定位接口
 */
export const _getAwIpLocation = () => {
  return request({
    method: 'post',
    url: '/Index/awIpLocation'
  })
}

/**
 * 读取后台配置的国家及地区列表
 */
export const _getCountryList = (params) => {
  return request({
    method: 'get',
    url: '/Index/getCountryList',
    params
  })
}
