import Language from '../pages'

export default {
  message: {
    hello: '你好世界'
  },
  // 头部
  header: {
    brand: {
      title: 'BRAND CENTER',
      ppgs: 'Brand Story',
      mxzx: 'Celebrity’s Choice',
      ppsj: 'Brand Moments',
      xwzx: 'News Center'
    },
    store: {
      title: 'FIND A STORE',
      list: [
        '杭州柏悅', '北京半岛', '成都华尔道夫', '北京瑰丽', '深圳莱佛士', '上海镛舍公寓', '广州瑰丽府邸', '北京璞瑄', '香港HUMANSA', '深圳文华东方', '上海外滩华尔道夫', '上海苏宁宝丽嘉', '厦门七尚', '杭州秋水山庄', '上海愚园独栋洋房', '香港四季'
      ],
      rzjd: {
        title1: 'Maternity and Baby Care Centre',
        title2: 'Maternity and Baby Care Centre'
      }
    },
    zyhh: {
      title: 'PROFESSIONAL CARE',
      hs1d1: '24 Hour Nurse One-on-one',
      hlms: 'Nursing Mode',
      mmhh: 'Mom Care',
      bbhh: 'Baby care',
      ycqzqfw: 'Long-term Value Service',
      ysly: 'BELLA Arts'
    },
    yzss: {
      title: 'DINING EXPERIENCE',
      sjdsstl: 'Four-stage Dietary Adjustment ',
      wdssyz: 'Five Dietary Principles '
    },
    chxf: {
      title: 'POSTPARTUM REPAIR',
      chyxs: 'S Treatment Beauty',
      zycp: 'STB Products'
    },
    country: {
      cn: 'Mainland China',
      hk: 'Hong Kong China',
      sg: 'Singapore'
    }
  },
  // 底部
  footer: {
    qgmd: 'FIND A STORE', // 全国门店
    ppzx: 'BRAND CENTER', // 品牌中心
    hyqy: 'SAPPHIRE UNION', // 会员权益
    zyhh: 'PROFESSIONAL CARE', // 专业呵护
    yjyys: 'PRIMECARE FAMILY', // 予家育婴师
    bkhlxx: 'PRIMECARE SCHOOL', // 贝康护理学校
    lxwm: {
      title: 'CONTACT US',
      name: 'PRIMECARE INTERNATIONAL (Hangzhou Headquarters)',
      address: 'Address：1st Floor, Building 6, Phase 6, Information Harbor, No. 666 Jianshe 2nd Road, Xiaoshan District, Hangzhou City',
      tel: 'Telephone：',
      email: 'Brand Collaborations：',
      mtlx: 'Media Consulting：'
    },
    zskf: {
      title: 'Exclusive customer service',
      time: 'Monday to Sunday',
      rzzx: 'Check-in Consultation:'
    },
    gzjh: 'Job Opportunities',
    sidebar: {
      title1: 'Maternity and Baby Care Centre',
      title2: 'Maternity and Baby Care Centre'
    }
  },
  comp: {
    xxgd: 'DOWN'
  },
  // 首页
  home: Language.homeEn,
  // 品牌中心
  brand: {
    story: Language.brandStoryEn,
    ...Language.brandEn
  },
  major: {
    hours: Language.majorHoursEn,
    cycle: Language.majorCycleEn,
    art: Language.majorArtEn,
    join: Language.majorJoinEn,
    timer: Language.majorTimerEn,
    ...Language.majorEn
  },
  month: Language.monthFoodEn,
  school: Language.schoolEn,
  store: {
    detail: Language.storeDetailEn,
    ...Language.storeEn
  },
  repair: Language.postpartumRepairEn,
  vip: Language.vipEn,
  job: Language.jobHuntEn,
  yujia: Language.yujiaEn
}
