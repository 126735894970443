// 中文翻译
const cn = {
  ppzx: '品牌中心',
  jkts: '即刻探索',

  ppgs: '品牌故事',

  ppsj: '品牌瞬间',
  xwzx: '新闻中心',
  zxdt: '最新动态',

  mxzx: '明星之选',
  rzkh: '入住客户'
}
// 繁体翻译
const tw = {
  ppzx: '品牌中心',
  jkts: '即刻探索',

  ppgs: '品牌故事',

  ppsj: '品牌瞬間',
  xwzx: '新聞中心',
  zxdt: '最新動態',

  mxzx: '明星之選',
  rzkh: '入住客戶'
}
// 英文翻译
const en = {
  ppzx: '', // Brand Center
  jkts: 'Explore Now',

  ppgs: '', // Brand Story

  ppsj: '', // Brand Moments
  xwzx: '', // News Center
  zxdt: 'Latest News',

  mxzx: '', // Celebrity’s Choice
  rzkh: 'Past Guests'
}
export { cn, tw, en }
