import axios from 'axios'
import { loading } from './tool'

const request = axios.create({
  baseURL: process.env.VUE_APP_REQUESTURL,
  timeout: 15000
})

// 请求拦截器
request.interceptors.request.use((config) => {
  loading()
  config.params = {
    ...(config.params || {})
  }
  config.headers = {
    'content-type': 'application/x-www-form-urlencoded'
  }
  // 在发送请求之前做些什么
  return config
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error)
})

// 响应拦截
request.interceptors.response.use(function (response) {
  loading(false)
  return response.data
}, function (error) {
  return Promise.reject(error)
})

export {
  request
}
