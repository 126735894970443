// import CN from './CN'
// import SG from './SG'
// import HK from './HK'

// 国家对应的下标 0简 1繁 2英（对应后台简繁英顺序）
const keyinValue = { CN: 0, HK: 1, SG: 2 }
// 当前选中的国家
const CountryKey = localStorage.getItem('country') || 'CN'
// 当前选中国家的信息
const Country = JSON.parse(localStorage.getItem('CountryList') || '[]')?.[keyinValue[CountryKey]] || {}

// lang 0简 1繁 2英
// config.toBr ## 转换为<br>标签 渲染数据有通过br换行，有通过块元素标签自动换行
const getCountryText = (text = '', lang = 0, config = {}) => {
  if (text === null) return ''
  let value = text.replaceAll('\n', '').split('###')[lang] || ''
  if (value.search('##') >= 0) {
    if (config.toBr) {
      value = value.replaceAll('##', '<br/>')
    } else {
      value = value.split('##')
    }
  }
  if (Array.isArray(value)) {
    value.forEach((v, i) => {
      if (v.startsWith('**') && v.endsWith('**')) {
        // console.log(v, value)
        value[i] = v.slice(2, -2)
        value[i] = `<p class='tips'>${value[i]}<p>`
      }
    })
  }
  return value
}

const CountryLang = {
  header: {
    zyhh: {
      hs1d1_cn: getCountryText(Country.content1, 0),
      hs1d1_tw: getCountryText(Country.content1, 1),
      hs1d1_en: getCountryText(Country.content1, 2)
    }
  },
  major: {
    hours: {
      banner: {
        title_def: getCountryText(Country.content2, 0),
        title_cn: getCountryText(Country.content3, 0),
        title_tw: getCountryText(Country.content3, 1),
        title_en: getCountryText(Country.content3, 2),
        subTitle_cn: getCountryText(Country.content4, 0, { toBr: true }),
        subTitle_tw: getCountryText(Country.content4, 1, { toBr: true }),
        subTitle_en: getCountryText(Country.content4, 2, { toBr: true })
      },
      hlms: {
        subTitle_def: getCountryText(Country.content6, 0),
        subTitle_cn: getCountryText(Country.content5, 0),
        subTitle_tw: getCountryText(Country.content5, 1),
        subTitle_en: getCountryText(Country.content5, 2),
        subDesc_cn: getCountryText(Country.content7, 0),
        subDesc_tw: getCountryText(Country.content7, 1),
        subDesc_en: getCountryText(Country.content7, 2)
      },
      mom: {
        zyhl: {
          desc_cn: getCountryText(Country.content8, 0),
          desc_tw: getCountryText(Country.content8, 1),
          desc_en: getCountryText(Country.content8, 2)
        }
      },
      baby: {
        zyhl: {
          desc_cn: getCountryText(Country.content9, 0),
          desc_tw: getCountryText(Country.content9, 1),
          desc_en: getCountryText(Country.content9, 2)
        }
      }
    }
  }
}

/**
 * 修改路径必须与翻译文件对应，且在字段后面加上相应后缀，则会自动替换掉当前对应的语言文本
 * 例：假设当前：country=cn locale=en
 *  header: {
 *  zyhh: {
 *    hs1d1_cn: '24H 1v1全天候照护',
 *    hs1d1_tw: '24H 1v1全天候照護',
 *    hs1d1_en: '24H 1v1 Round the Clock Care'
 *  }
 *}
 * 则会选中以上 header.zyhh.hs1d1_en 替换掉默认语言的 header.zyhh.hs1d1 文本内容
 * _cn  代表中国的文案
 * _tw  代表中国台湾的文案
 * _en  目前代表新加坡
 * _def 原来是页面写死的文本，因国家改动需要动态更换，新加的后缀
 */
export const KeySuffix = ['_cn', '_tw', '_en', '_def']
export { CountryLang }
