// 中文翻译
const cn = {
  ppjs: {
    title: '品牌介绍',
    title2: '圣贝拉母婴护理中心',
    desc: '简称圣贝拉 (SAINT BELLA)，高奢月子护理品牌，成立于2017年。自主研创艺术疗养，源自中国台湾资深产后护理团队，遵循ISO9001母婴认证护理标准，秉承西尔斯医学博士亲密育儿理念，坚持24小时护士一对一照护。圣贝拉肩负着颠覆和创新的使命，仅入驻全球奢华酒店，通过LTV孕产全周期的奢享服务，开启女性月子之旅，打造由内至外蜕变的至美圣地。 '
  },
  yzzl: {
    title1: '月子之旅',
    title2: '享耀新生',
    desc: '圣贝拉是为追求品质生活同时拥有精神诉求的女性所创造的世界。产后的28天不仅仅是单纯的坐月子，更是引导女性对新生美好的向往的一个阶段。蜕变成更好自己的同时，开启一段与宝宝最珍贵的初遇之旅。 '
  },
  block: {
    title: '「为了找到同类，我们创造了一个世界」',
    desc: '让每位女性在分娩后的28天中蜕变更好的自己'
  },
  lxj: {
    title: '成为圣贝拉月子旅行家',
    list: [
      {
        title: '优雅惬意的旅居体验',
        value: ['仅入驻全球奢华Luxury酒店', '超国际化标准设施与服务']
      },
      {
        title: '无处不在的精致格调',
        value: ['甄选全球大牌母婴用品品牌', '高品质产品全天候供应', '给予奢享使用体验']
      },
      {
        title: '鲜入为主的味蕾盛宴',
        value: ['营养专家潜心研制四阶段月子餐单', '药食同源', '经酒店主厨烹饪，鲜食现做，食材皆可溯源']
      },
      {
        title: '细腻入微的科学照护',
        value: ['全职服务团队9v1', '仅与国内Top主任级专家合作', '接轨国际前沿临床理论']
      },
      {
        title: '场景式多维五感疗愈',
        value: ['自主研创艺术疗养课程', '多维度场景式艺术熏陶', '调节月子期妈妈情绪', '促进宝宝感官发育']
      },

      {
        title: '雕刻优雅的高尖产康',
        value: ['自有STB产后修复品牌', '360°周期制管理，纯手工锻造', '纯进口百万仪器', '三段式进阶修复']
      },
      {
        title: '云端数智化护理系统',
        value: ['自主研发PICP智能护理系统', '每日记录母婴生命体征状态数据', '实时监测，制定和调整育婴方案', '离馆后系统将自动生成专属月子报告']
      },
      {
        title: '内外兼修的修复方案',
        value: ['S-bra功能型高定内衣品牌', '匠心锻造，96组精细化量体数据', '一对一手工裁衣', '广禾堂专注女性孕产滋补', '结合传统中医与现代营养学理论', '以药食同源的天然食补产品', '调理女性孕产健康']
      }
    ]
  },
  hdjx: {
    title: '获得奖项'
  }
}
// 繁体翻译
const tw = {
  ppjs: {
    title: '品牌介紹',
    title2: '聖貝拉母嬰護理中心',
    desc: '簡稱聖貝拉 (SAINT BELLA)，高奢月子護理品牌，成立於2017年。自主研創藝術療養，源自中國臺灣資深產後護理團隊，遵循ISO9001母嬰認證護理標準，秉承西爾斯醫學博士親密育兒理念，堅持24小時護士一對一照護。聖貝拉肩負著顛覆和創新的使命，僅入駐全球奢華酒店，通過LTV孕產全周期的奢享服務，開啟女性月子之旅，打造由內至外蛻變的至美聖地。'
  },
  yzzl: {
    title1: '月子之旅',
    title2: '享耀新生 ',
    desc: '聖貝拉是為追求品質生活同時擁有精神訴求的女性所創造的世界。產後的28天不僅僅是單純的坐月子，更是引導女性對新生美好的向往的一個階段。蛻變成更好自己的同時，開啟一段與寶寶最珍貴的初遇之旅。'
  },
  block: {
    title: '「為了找到同類，我們創造了一個世界」',
    desc: '讓每位女性在分娩後的28天中蛻變更好的自己'
  },
  lxj: {
    title: '成為聖貝拉月子旅行家',
    list: [
      {
        title: '優雅愜意的旅居體驗',
        value: ['僅入駐全球奢華Luxury酒店', '超國際化標準設施與服務']
      },
      {
        title: '無處不在的精致格調',
        value: ['甄選全球大牌母嬰用品品牌', '高品質產品全天候供應', '給予奢享使用體驗']
      },
      {
        title: '鮮入為主的味蕾盛宴',
        value: ['營養專家潛心研製四階段月子餐單', '藥食同源', '經酒店主廚烹飪，鮮食現做，食材皆可溯源']
      },
      {
        title: '細膩入微的科學照護',
        value: ['全職服務團隊9v1', '僅與國內Top主任級專家合作', '接軌國際前沿臨床理論']
      },
      {
        title: '場景式多維五感療愈',
        value: ['自主研創藝術療養課程', '多維度場景式藝術熏陶', '調節月子期媽媽情緒', '促進寶寶感官發育']
      },

      {
        title: '雕刻優雅的高尖產康',
        value: ['自有STB產後修復品牌', '360°周期製管理，純手工鍛造', '純進口百萬儀器', '三段式進階修復']
      },
      {
        title: '雲端數智化護理系統',
        value: ['自主研發PICP智能護理系統', '每日記錄母嬰生命體征狀態數據', '實時監測，製定和調整育嬰方案', '離館後系統將自動生成專屬月子報告']
      },
      {
        title: '内外兼修的修复方案',
        value: ['S-bra功能型高定內衣品牌', '匠心鍛造，96組精細化量體數據', '一對一手工裁衣', '廣禾堂專註女性孕產滋補', '結合傳統中醫與現代營養學理論', '以藥食同源的天然食補產品', '調理女性孕產健康']
      }
    ]
  },
  hdjx: {
    title: '獲得獎項'
  }
}
// 英文翻译
const en = {
  ppjs: {
    title: '', // ABOUT US
    title2: '', // SAINT BELLA Maternity and Baby Care Centre
    desc: "Known in short as SAINT BELLA, we were established in 2017, a luxury maternity and baby care brand. Originating from a team of senior post-partum care professionals in Taiwan,China. SAINT BELLA developed its own Art & Nursing programme. We have also followed ISO9001 certification nursing standards for our maternal and baby nursing system. Upholding Dr. William Sears' attachment parenting concept, we offer 24-hour, one-to-one nursing care. Stationed exclusively within luxury hotels worldwide, SAINT BELLA is on a mission to revolutionize and innovate. Through our Long-Term Value Service, which offers luxury services throughout the entire pregnancy periods, SAINT BELLA embarks on a journey to support women in their post-partum recovery, creating a beautiful haven for both inner and outer transformative changes."
  },
  yzzl: {
    title1: '', // LOVING YOU
    title2: '', // IS LOVING LIFE
    desc: 'SAINT BELLA is a world created for women who seek a life with quality while keeping up spiritual pursuits. The 28 days following childbirth are not just about confinement but also a stage about fostering an appreciation for the beauty of a new-born life. While transforming themselves into a better person, they start off on a precious journey with their newly-acquainted babies. '
  },
  block: {
    title: '“In order to find something kindred, we created a world.”',
    desc: 'We believe that every woman can transform into a better version of herself in the 28 days after giving birth.'
  },
  lxj: {
    title: 'LIVING IN SAINT BELLA',
    list: [
      {
        title: 'Travel Experience',
        value: ['Exclusively situated in luxury hotels around the world', 'World-class international standard facilities and services']
      },
      {
        title: 'Exquisite Style',
        value: ['Meticulously selected maternal and child supplies from global mega brands', 'High-quality products available around the clock', 'Thoughtful and luxurious user experience']
      },
      {
        title: 'Delicious Feast',
        // 'homology of medicine and food',
        value: ['Four-stage post-partum menus meticulously developed by nutrition experts', 'Homology of medicine and food', 'Postpartum meals expertly prepared by a team of experienced hotel head chefs', 'Freshly made, meticulously sourced ingredients']
      },
      {
        title: 'Scientific Care',
        value: ['Dedicated full-time 9v1 service team', 'Collaboration with top-tier experts in the respective field', 'Providing our guests with the latest clinical advancements']
      },
      {
        title: 'Immersive Healing',
        value: ['Self-developed BELLA Arts art therapy sessions', 'Multi-dimensional, artistic enlightenment regulating ', 'the emotions of mothers during the post-partum period', 'Promoting sensory development in babies']
      },

      {
        title: 'Advanced Projects',
        value: ['Self-developed STB Brand for post-partum wellness and recovery treatments ', '360° postpartum physical conditioning series, purely crafted by hand', 'Million-dollar wellness and aesthetic equipments, all imported', 'Comprehensive three-stage progressive postpartum recovery']
      },
      {
        title: 'Intelligent System',
        value: ['Self-developed PICP intelligent care system', 'Daily recording of vital signs data for mother and baby', 'Real-time monitoring, formulation, and customisation of infant care programme', 'Upon leaving the post-partum care centre, a dedicated post-partum report is automatically generated by the system']
      },
      {
        title: 'Comprehensive Repair',
        value: ['S-bra is a functional high-end lingerie brand that is meticulously crafted', 'With 96 sets of refined body measurement data, each piece is tailored by hand', 'GUANG HE TANG focuses on women\'s prenatal and postnatal nourishment', 'combining traditional Chinese medicine with modern nutritional theories to provide natural food supplements that promote women\'s reproductive health', '', '', '']
      }
    ]
  },
  hdjx: {
    title: '' // AWARDS
  }
}

export { cn, tw, en }
