// 中文翻译
const cn = {
  banner: {
    title: '圣贝拉LTV孕产全周期服务',
    subTitle: '全职9v1团队，悉心呵护月子全周期'
  },
  ts: {
    title: '探索',
    subTitle: ['走进一场', '极致的参观旅程'],
    value: ['由专属高级母婴顾问1V1陪伴', '品鉴米其林式营养月子膳食', '是从味蕾抵达身心的片刻治愈'],
    value2: ['向外俯瞰奢华酒店的一线景观', '过滤喧扰 邂逅静谧', '为你寻得惬意与格调的理想居所']
  },
  yq: {
    title: '孕期',
    subTitle: ['开启', '悉心呵护的月子之旅'],
    value: ['一对一私人专属服务群，随时响应', ' S-bra孕产期胸衣量身定制，有效应对胸部变化', '营养顾问提供专业营养建议，孕期餐单咨询或定制', '专家定期服务，给予孕期健康指导', '圣贝拉小程序提供科学孕期知识', '商城精选大牌折扣商品，一键购齐待产好物'],
    subTitle2: '孕期艺术沙龙体验',
    value2: ['「成为母亲」系列讲座/艺术鉴赏/奢侈品牌联合活动']
  },
  yzq: {
    title: '月子期',
    subTitle: ['全职9V1', '悉心呵护月子全周期'],
    value: ['9v1专业护理团细致照护每一天', ' 智能线上系统，记录每日护理及生命体征，随时查阅健康状', 'S-bra定制哺乳唯胸衣，肤感温柔，承托优雅', '广禾堂四款功效月子茶，20年研制，天然调理', '自有STB产后研修所，草本养发、足部护理，舒暖治愈', '沉浸式艺术美学沙龙'],
    tab1: {
      title: '精细呵护',
      subTitle: '店长、服务体验专家、房务管家',
      value: ['关注新生家庭每处生活细节', '满足入住期个性化需求']
    },
    tab2: {
      title: '专业加持',
      subTitle: '护理总监、护理长、护士',
      value: ['专业资质护理人员', '三重保障，给予新生安全感']
    },
    tab3: {
      title: '全能修复',
      subTitle: '产后修复顾问、营养顾问、泌乳顾问',
      value: ['专注产后身心灵修护', '极简方案，轻松恢复优雅体态']
    }
  },
  rzzn: '点击查看圣贝拉妈妈入住指南',
  gdq: {
    title: '过渡期',
    value: ['护士护送至家，协助育儿场所布置', ' 24H制1V1护士驻家服务，延续专业的陪伴', 'S-bra离乳唯胸衣，稳固脂肪，重塑身形', '会员积分兑换商城礼 ', '会员限定节日回馈礼赠', '会员礼宾服务权益']
  },
  yeq: {
    title: '育儿期',
    value: ['新生儿0-3岁期间，提供资质育婴师资源库', 'UCCA尤伦斯艺术中心观展', 'UCCA Kids亲子游学', 'UCCA幼儿启蒙阶段艺术绘本', '塞利格曼理论心理咨询', '亲子关系心理沙龙', 'IPHI育儿睡眠安抚指导', '儿童分龄主题嘉年华', '教育名家给予儿童成长方案', '早教、早托知名机构推荐']
  }
}
// 繁体翻译
const tw = {
  banner: {
    title: '聖貝拉LTV孕產全周期服務',
    subTitle: '全職9v1團隊，悉心呵護月子全周期'
  },
  ts: {
    title: '探索',
    subTitle: ['走進一場', '極致的參觀旅程'],
    value: ['由專屬高級母嬰顧問1V1陪伴', '品鑒米其林式營養月子膳食', '是從味蕾抵達身心的片刻治愈'],
    value2: ['向外俯瞰奢華酒店的一線景觀', '過濾喧擾 邂逅靜謐', '為你尋得愜意與格調的理想居所']
  },
  yq: {
    title: '孕期',
    subTitle: ['開啟', '悉心呵護的月子之旅'],
    value: ['一對一私人專屬服務群，隨時響應', 'S-bra孕產期胸衣量身定製，有效應對胸部變化', '營養顧問提供專業營養建議，孕期餐單咨詢或定製', '專家定期服務，給予孕期健康指導', '聖貝拉小程序提供科學孕期知識', '商城精選大牌折扣商品，一鍵購齊待產好物'],
    subTitle2: '孕期藝術沙龍體驗',
    value2: ['「成為母親」系列講座/藝術鑒賞/奢侈品牌聯合活動']
  },
  yzq: {
    title: '月子期',
    subTitle: ['全職9V1', '悉心呵護月子全周期'],
    value: ['9v1專業護理團細致照護每一天', '智能線上系統，記錄每日護理及生命體征，隨時查閱健康狀態', 'S-bra定製哺乳唯胸衣，膚感溫柔，承托優雅', '廣禾堂四款功效月子茶，20年研製，天然調理', '自有STB產後研修所，草本養發、足部護理，舒暖治愈', '沈浸式藝術美學沙龍'],
    tab1: {
      title: '精細呵護',
      subTitle: '店長、服務體驗專家、房務管家',
      value: ['關註新生家庭每處生活細節', '滿足入住期個性化需求']
    },
    tab2: {
      title: '專業加持',
      subTitle: '護理總監、護理長、護士',
      value: ['專業資質護理人員', '三重保障，給予新生安全感']
    },
    tab3: {
      title: '全能修復',
      subTitle: '產後修復顧問、營養顧問、泌乳顧問',
      value: ['專註產後身心靈修護', '極簡方案，輕松恢復優雅體態']
    }
  },
  rzzn: '點擊查看聖貝拉媽媽入住指南',
  gdq: {
    title: '過渡期',
    value: ['護士護送至家，協助育兒場所佈置', '24H制1V1護士駐家服務，延續專業的陪伴', 'S-bra離乳唯胸衣，穩固脂肪，重塑身形', '會員積分兌換商城禮 ', '會員限定節日回饋禮贈', '會員禮賓服務權益']
  },
  yeq: {
    title: '育兒期',
    value: ['新生兒0-3歲期間，提供資質育嬰師資源庫', 'UCCA尤倫斯藝術中心觀展', 'UCCA Kids親子遊學', 'UCCA幼兒啟蒙階段藝術繪本', '塞利格曼理論心理諮詢', '親子關係心理沙龍', 'IPHI育兒睡眠安撫指導', '兒童分齡主題嘉年華', '教育名家給予兒童成長方案', '早教、早托知名機构推薦']
  }
}
// 英文翻译
const en = {
  banner: {
    title: '', // 圣贝拉LTV孕产全周期服务
    subTitle: 'Full-Time 9v1 Team for Comprehensive<br />Round-The-Clock Care throughout Your Post-partum Recovery'
  },
  ts: {
    title: '', // APPOINTMENT
    subTitle: ['Embark on', 'The Ultimate Tour'],
    value: ['· Accompanied by a dedicated senior mother and baby consultant ', '· Tasting Michelin-style nutritious confinement meals', 'is a moment of healing from taste to body and soul'],
    value2: ['· Overlooking the first-line view of the luxurious hotel', 'filtering out the noise and encountering tranquility', 'finding your ideal residence with comfort and style']
  },
  yq: {
    title: '', //  is a moment of healing from taste to body and soul
    subTitle: ['Embark on', 'A Confinement Journey of Thoughtful Care'],
    value: ['· A Many-to-One Responsive and Personalized Dedicated Service Team', '· Experience the S-bra, a Customized Pregnancy and Post-partum Bra, Which Effectively Adapts and Supports Breast Changes', '· Nutritional Consultants Provide Professional Nutritional Advice, Pregnancy Meal Consultation and Customization  ', '· A Team of Experts Will Provide Guidance on Pregnancy Health ', '· SAINT BELLA Mini-Programme Will Provide Scientifically Backed Pregnancy Knowledge ', '· An App for Guests to Choose Top-Quality Goods of Renowned Brands, Conveniently Purchase All Baby Essentials with a Single Click'],
    subTitle2: '· Prenatal Art Salon Experience',
    value2: ['· The "Becoming a Mother" Series: Lectures/Art Appreciation/Luxury Brand Collaborative Events']
  },
  yzq: {
    title: '', // POSTPARTUM CONFINEMENT
    subTitle: ['Full-Time 9V1', 'Thoughtful and Meticulous Care Throughout the Entire Confinement Period'],
    value: ['· A 9V1 Professional Nursing Team Providing Daily Meticulous Care', '· An Intelligent App Records Daily Nursing and Vital Signs, Enabling Checks on Health Status at Any Time', '· S-bra Customized Breastfeeding Bra is Gentle on the Skin Yet Elegantly Supportive and Stylish', '· GUANG HE TANG\'s four types of postpartum tea have been developed for over 20 years and are naturally nourishing', '· Our own brand S Treatment Beauty offers herbal hair care, foot care, and postpartum wellness and recovery', '· Immersive Art and Aesthetic Salon Experiences'],
    tab1: {
      title: 'Meticulous Care',
      subTitle: 'Operation Manager, Guest Experience Expert, Housekeeping Supervisors',
      value: ['· Attention to Every Detail in the Life of Your New Family,', '· Meeting Personalized Needs During Your Stay']
    },
    tab2: {
      title: 'Professional Support',
      subTitle: 'Nursing Director, Head Nurse, Nurse',
      value: ['· Highly Qualified Dedicated Nursing Professionals,', '· Provide Triple Guarantees for New-born Care']
    },
    tab3: {
      title: 'All-Round Recovery',
      subTitle: 'Post-partum Recovery Consultant, Nutrition Consultant, Lactation Consultant',
      value: ['· Focused on Post-partum Physical and Mental Healing,', '· We Offer a Customized Plan to Restore Your Pre- Pregnancy Shape with Ease']
    }
  },
  rzzn: 'Click to View the SAINT BELLA Mum\'s Check-In Guide',
  gdq: {
    title: '', // TRANSITION
    value: ['· Our Nurse Will Accompany to Your Home to Assist in Setting Up the Nursery', '· 12H/24H 1V1 Nurse Home Services Available for Continued Professional Care ', '· Stabilize Fat and Reshape Your Body with our very own S-bra', '· Exchange Membership Points for Exclusive Mall Gifts', '· Members Can Enjoy Thoughtful Gifts During Holiday Seasons', '· Members Can Enjoy Customized Membership Concierge Benefits']
  },
  yeq: {
    title: '', // PARENTING
    value: ['· A Resource Pool that Provides Valuable Information for Caregivers from New-borns Aged 0-3 Years Old', '· Explore Ullens Centre for Contemporary Art (UCCA) Early Childhood', '· UCCA Kids: Parent-Child Study Tours', '· UCCA Enlightenment Stage Art Picture Books', '· Receive Seligman Theoretical Counselling', '· Engage in Parent-Child Relationship Psychological Salon Sessions', '· Access IPHI Parenting Sleep Soothing Guidance', '· Let Children Enjoy Age-Segregated Theme Carnivals', '· Benefit from Education Experts’ Child Growth Plans', '· Discover Recommended Well-Known Early Education and Early Childhood Institutions']
  }
}

export { cn, tw, en }
