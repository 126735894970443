import { request } from '@/util/request'

/**
 * 获取艺术疗养列表
 */
export const _getArtsList = (params) => {
  return request({
    method: 'get',
    url: '/Index/artsList',
    params
  })
}
