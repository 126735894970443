import { request } from '@/util/request'

/**
 * 获取品牌新闻列表
 */
export const _getNewsList = (params) => {
  return request({
    method: 'get',
    url: '/Index/newsList',
    params: { ...params, form: 1 }
  })
}

/**
 * 获取品牌瞬间列表
 */
export const _getMomentList = (params) => {
  return request({
    method: 'get',
    url: '/Index/newsList',
    params: { ...params, form: 2 }
  })
}

/**
 * 获取入住明星列表
 */
export const _getStarList = (params) => {
  return request({
    url: 'Index/starchoiceList',
    method: 'get',
    params
  })
}


/**
 * 获取奖项列表
 */
export const _getAwardsList = (params) => {
  return request({
    method: 'get',
    url: '/Index/awardsList',
    params
  })
}
