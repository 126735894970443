// 中文翻译
const cn = {
  banner: {
    title: '产后研修所',
    subTitle: '匠心定制产后修复方案，把握黄金修复期'
  },
  chyxs: {
    title: '产后研修所',
    desc: '简称STB。潜心十年精研孕产女性体质，致力于打磨科技与匠心融合的修复治愈体系。以科学为源，特聘来自日、韩、新加坡、英国等国家的医学博士及美学形体专家。引入国内外殿堂级技术与仪器，研发创新修复产品，淬炼古法手法，三维一体重愈新生。以美学为引，私人定制内在调理与外在修复方案，精准塑造身形黄金比例。以服务为基，提供始于孕期的全周期服务，把握180天产后修复黄金期，锻造每一位女性的肌体复元。',
    rightTitle: '让美与力，内外兼修'
  },
  hxxfln: {
    title: '核心修复理念',
    list1: [
      { t: '由内而外 循序渐进', v: '遵循七大核心修复体系，先调理体质、再修复体态、最终重塑身形。' },
      { t: '精准定位 极简修复', v: '只做针对性康复，不做花式疗养。区别于传统冗杂的修复项目，针对人体结构，从上至下、从内而外定点定向修复。' },
      { t: '匠心而制 赋能新生', v: '品牌自主研发精油、S-bra孕产期高定胸衣等产品，从孕期开始介入，呵护敏感部位。' }
    ],
    list2: [
      { t: ['基础修复', '产后体质调理'], d: '通过排养与提升代谢，对妈妈进行科学体质管理。恢复气血运行能力，修复因孕、产、乳而受影响的器官功能，让体质达到动态平衡。', v: ['法儿曼面部养护', 'IBCLC乳房管理', '淋巴排养'], v2: ['精油舒缓按摩', '草本热能养护', '西班牙代谢管理'] },
      { t: ['进阶修复', '产后体态管理'], d: '修复因怀孕、分娩而造成的肌体损伤，使身形与身体机能快速恢复至孕前状态。在体内良性微循环下，加速自然修复能力，紧致肌肤，塑造优雅身形。', v: ['西班牙透热塑形', '西班牙肌肉训练', '英国BTL盆底肌训练'], v2: ['S-bra孕产期高定胸衣', '新加坡JAMU全身护理'] },
      { t: ['终极重塑', '产后身形精雕'], d: '建立医院联盟，针对女性产后常见全身骨骼、盆骨等问题，私人定制四维康复训练，全面精雕产后身形，重焕光彩。', v: ['S-Corset产后功能连体衣', '体态精雕，重塑黄金比例形体美学'], v2: [] }
    ],
    tips: '*具体方案需结合医生及产康师专业建议'
  },
  kjxftx: {
    title: '七大科技修复体系',
    list: [
      {
        title: '孕期',
        item1: { t: '胸部形态保护', v: ['乳腺发育稳固承托', '胸型变化预防下垂', '脂肪外溢加强聚拢'] },
        item2: { t: '皮肤屏障保护', v: ['皮肤敏感建立屏障', '面颈色素淡化提亮', '深层养护水润紧致'] }
      },
      {
        title: '入住馆内',
        item1: { t: 'IBCLC泌乳指导', v: ['专业呵护乳腺健康', '', ''] },
        item2: { t: '舒缓疼痛管理', v: ['产后身体状态测评', '30mins按摩舒缓身心', ' (免费3次)'] }
      },
      {
        title: '入馆7日',
        item1: { t: '体质修复管理', v: ['体质修复改善循环', '手法疗愈消退肿胀', ''] },
        item2: { t: '肌能代谢管理', v: ['肌能激活加速自愈', '代谢增强紧致塑形', ''] }
      },
      {
        title: '入馆14日',
        item1: { t: '体型体态管理', v: ['体型调整矫姿挺拔', '体态稳定对抗初老', ''] },
        item2: { t: '脂肪形体管理', v: ['外溢脂肪重塑比例', '优化曲线优雅身姿', ''] }
      },
      {
        title: '入馆21日',
        item1: { t: '胸部形态管理', v: ['干瘪移位胸型轻塑', '私人定制有力承托', ''] },
        item2: { t: '', v: ['', '', ''] }
      },
      {
        title: '入馆42日',
        item1: { t: '盆底肌群管理', v: ['盆底肌群激发活力', '肌肉抗衰修复身心', ''] },
        item2: { t: '', v: ['', '', ''] }
      }
    ]
  },
  zycp: {
    title: ['STB', '自研产品'],
    list: [
      { title: ['S-bra', '功能型高定内衣'], desc: '创立于2010年，始于韩国，现隶属贝康国际集团「S Treatment Beauty 产后研修所」。延续韩国技术研发团队专业标准，依托女性身材数据库，以高奢私人服务为孕产修复、脂肪抗衰保养等需求定制功能内衣。每一件内衣均由从业10年以上技师匠心锻造，96组精细化量体数据，一对一手工裁衣。' },
      { title: ['S-Corset', '产后功能连体衣'], desc: '96个身体数据的测量分析，5大体型成因和特征数据分析，以及384个身体局部压位数值专业测定，一对一定制塑形方案。50片专享立体版型手工裁制，选用进口医用面料，靶向调整产后腰腹、臀腿等各类脂肪形态。' },
      { title: ['STB', '草本汉方养发包'], desc: '匠心研发草本汉方养发护理，汇集8种中药材无化学添加，相比清水更能够温和去污、排湿驱寒，帮助妈妈抵御产后虚弱。' },
      { title: ['STB', '草本汉方足浴包'], desc: '甄选九味草本精粹，缓解产后身体疲劳，提升睡眠质量，达到祛风散寒的功效。使用中药泡脚，适宜的温度能令血管扩张，促进血液循环与新陈代谢，利于产后身体恢复。' }
    ]
  }
}

// 繁体翻译
const tw = {
  banner: {
    title: '產後研修所',
    subTitle: '匠心定製產後修復方案，把握黃金修復期'
  },
  chyxs: {
    title: '產後研修所',
    desc: '簡稱STB。潛心十年精研孕產女性體質，致力於打磨科技與匠心融合的修復治愈體系。以科學為源，特聘來自日、韓、新加坡、英國等國家的醫學博士及美學形體專家。引入國內外殿堂級技術與儀器，研發創新修復產品，淬煉古法手法，三維一體重愈新生。以美學為引，私人定製內在調理與外在修復方案，精準塑造身形黃金比例。以服務為基，提供始於孕期的全周期服務，把握180天產後修復黃金期，鍛造每一位女性的肌體復元。',
    rightTitle: '讓美與力，內外兼修'
  },
  hxxfln: {
    title: '核心修復理念',
    list1: [
      { t: '由內而外 循序漸進', v: '遵循七大核心修復體系，先調理體質、再修復體態、最終重塑身形。' },
      { t: '精準定位 極簡修復', v: '只做針對性康復，不做花式療養。區別於傳統冗雜的修復項目，針對人體結構，從上至下、從內而外定點定向修復。' },
      { t: '匠心而製 賦能新生', v: '品牌自主研發精油、S-bra孕產期高定胸衣等產品，從孕期開始介入，呵護敏感部位。' }
    ],
    list2: [
      { t: ['基礎修復', '產後體質調理'], d: '通過排養與提升代謝，對媽媽進行科學體質管理。恢復氣血運行能力，修復因孕、產、乳而受影響的器官功能，讓體質達到動態平衡。', v: ['法兒曼面部養護', 'IBCLC乳房管理', '淋巴排養'], v2: ['精油舒緩按摩', '草本熱能養護', '西班牙代謝管理'] },
      { t: ['進階修復', '產後體態管理'], d: '修復因懷孕、分娩而造成的肌體損傷，使身形與身體機能快速恢復至孕前狀態。在體內良性微循環下，加速自然修復能力，緊致肌膚，塑造優雅身形。', v: ['西班牙透熱塑形', '西班牙肌肉訓練', '英國BTL盆底肌訓練'], v2: ['S-bra孕產期高定胸衣', '新加坡JAMU全身護理'] },
      { t: ['終極重塑', '產後身形精雕'], d: '建立醫院聯盟，針對女性產後常見全身骨骼、盆骨等問題，私人定製四維康復訓練，全面精雕產後身形，重煥光彩。', v: ['S-Corset產後功能連體衣', '體態精雕，重塑黃金比例形體美學'], v2: [] }
    ],
    tips: '*具體方案需結合醫生及產康師專業建議'
  },
  kjxftx: {
    title: '七大科技修復體系',
    list: [
      {
        title: '孕期',
        item1: { t: '胸部形態保護', v: ['乳腺發育穩固承托', '胸型變化預防下垂', '脂肪外溢加強聚攏'] },
        item2: { t: '皮膚屏障保護', v: ['皮膚敏感建立屏障', '面頸色素淡化提亮', '深層養護水潤緊致'] }
      },
      {
        title: '入住館內',
        item1: { t: 'IBCLC泌乳指導', v: ['專業呵護乳腺健康', '', ''] },
        item2: { t: '舒緩疼痛管理', v: ['產後身體狀態測評', '30mins按摩舒緩身心', '(免費3次)'] }
      },
      {
        title: '入館7日',
        item1: { t: '體質修復管理', v: ['體質修復改善循環', '手法療愈消退腫脹', ''] },
        item2: { t: '肌能代謝管理', v: ['肌能激活加速自愈', '代謝增強緊致塑形', ''] }
      },
      {
        title: '入館14日',
        item1: { t: '體型體態管理', v: ['體型調整矯姿挺拔', '體態穩定對抗初老', ''] },
        item2: { t: '脂肪形體管理', v: ['外溢脂肪重塑比例', '優化曲線優雅身姿', ''] }
      },
      {
        title: '入館21日',
        item1: { t: '胸部形態管理', v: ['幹癟移位胸型輕塑', '私人定製有力承托', ''] },
        item2: { t: '', v: ['', '', ''] }
      },
      {
        title: '入館42日',
        item1: { t: '盆底肌群管理', v: ['盆底肌群激發活力', '肌肉抗衰修復身心', ''] },
        item2: { t: '', v: ['', '', ''] }
      }
    ]
  },
  zycp: {
    title: ['STB', '自研產品'],
    list: [
      { title: ['S-bra', '功能型高定內衣'], desc: '創立於2010年，始於韓國，現隸屬貝康國際集團「S Treatment Beauty 產後研修所」。延續韓國技術研發團隊專業標準，依托女性身材數據庫，以高奢私人服務為孕產修復、脂肪抗衰保養等需求定製功能內衣。每一件內衣均由從業10年以上技師匠心鍛造，96組精細化量體數據，一對一手工裁衣。' },
      { title: ['S-Corset', '產後功能連體衣'], desc: '96個身體數據的測量分析，5大體型成因和特征數據分析，以及384個身體局部壓位數值專業測定，一對一定製塑形方案。50片專享立體版型手工裁製，選用進口醫用面料，靶向調整產後腰腹、臀腿等各類脂肪形態。' },
      { title: ['STB', '草本漢方養發包'], desc: '匠心研發草本漢方養發護理，匯集8種中藥材無化學添加，相比清水更能夠溫和去汙、排濕驅寒，幫助媽媽抵禦產後虛弱。' },
      { title: ['STB', '草本漢方足浴包'], desc: '甄選九味草本精粹，緩解產後身體疲勞，提升睡眠質量，達到祛風散寒的功效。使用中藥泡腳，適宜的溫度能令血管擴張，促進血液循環與新陳代謝，利於產後身體恢復。' }
    ]
  }
}

// 英文翻译
const en = {
  banner: {
    title: '',
    subTitle: 'Skilfully Customized Postpartum Recovery Plans, Seizing the Golden Recovery Window'
  },
  chyxs: {
    title: '',
    desc: 'Short for STB – A high-end postpartum wellness and recovery brand dedicated to developing a postpartum recovery system that combines technology and ingenuity. After ten years of intensive research on the physique of women during pregnancy and childbirth, STB has assembled a team of medical doctors and aesthetic and body experts from Japan, South Korea, Singapore, the UK, and other countries. STB uses state-of-the-art technologies and instruments to develop innovative restoration products, and refine ancient techniques, creating a three-dimensional integrated restoration system. Guided by aesthetics, STB customizes internal conditioning and external repair plans to accurately restore the golden proportions of your body shape. STB provides full-cycle services starting from pregnancy, seizing the 180-day postpartum recovery golden period, and forging every woman\'s physical recovery.',
    rightTitle: 'Let beauty and strength be fostered, taking care of both transformations on the outside and from within.'
  },
  hxxfln: {
    title: 'CORE REHABILITATION PHILOSOPHY',
    list1: [
      { t: 'Inside-Out Transformation, Step by Step', v: 'Following the programme of Seven Core Restoration Steps which focuses on “adjust/repair/sculpt”, we first assess and adjust mothers’ physical conditions, then restore their body shape, and finally reshape their physique.' },
      { t: 'Precise Targeting for Minimal Interventions', v: 'We only do targeted rehabilitation, avoiding fancy treatments. Unlike traditional restoration programmes which involve unnecessary meddling, we zero in on the essential parts of the human anatomy, repairing it inside out following methods backed by science.' },
      { t: 'Skilfully Created to Empower New Mothers', v: 'Our brand independently develops essential oils, high-end S-bra maternity underwear and other products, which offer beneficial and supportive interventions from the start of pregnancy and take intimate care of private sensitive parts.' }
    ],
    list2: [
      { t: ['Foundational Restoration ', 'Postpartum Physical Rehabilitation'], d: 'Through conditioning, waste expulsion and enhanced metabolism, our method of managing mothers’ physical well-being is scientifically based. The process restores energy and improves blood circulation, repairing organ functions affected by pregnancy, childbirth, and lactation to achieve optimal balance for mothers’ postpartum physical condition. ', v: ['· Valmont Facial Care', '· IBCLC Breast Management', '· Lymphatic Drainage'], v2: ['· Essential Oil Soothing Massage', '· Mineral Thermal Care', '· Spanish Metabolic Management'] },
      { t: ['Advanced Restoration', 'Postpartum Body Management'], d: 'Repair muscle damage caused by pregnancy and childbirth, restoring the body shape and functions back to the pre-pregnancy state. Tapping into micro-circulations in the body to accelerate natural healing process, tighten the skin, and shape an elegant figure.', v: ['· Spanish Heat Penetration Shaping', '· Spanish Muscle Training', '· British BTL Pelvic Floor Muscle Strengthening'], v2: ['· S-bra High-end Breast Underwear for Pregnancy And Childbirth', '· Singapore JAMU Full-body Care'] },
      { t: ['Ultimate Reshaping', 'Postpartum Body Sculpting'], d: 'Collaborating with a network of hospitals, we tailor-made four-dimensional rehabilitation training programmes to address common postpartum issues such as overall bone structure, particularly pelvic bones in women. This comprehensive approach helps to re-sculpt the postpartum body shape, restoring its radiance.', v: ['· S-Corset Postpartum Shaping Jumpsuit', '· Sculpting the body and restoring the golden-ratio of female body dimensions'], v2: [] }
    ],
    tips: '*Specific programmes should tie in with professional advice from doctors and postpartum health consultants.'
  },
  kjxftx: {
    title: 'Seven Major Technological Restoration Systems',
    list: [
      {
        title: 'During Pregnancy',
        item1: { t: 'Breast Shape Preservation', v: ['· Providing stable support for breast development', '· Prevent changes in breast shape and breast ptosis (sagging)', '· Proper support to preserve breast’s natural shape and create a more defined cleavage'] },
        item2: { t: 'Skin Barrier Protection', v: ['· Establish a protective barrier for sensitive skin', '· Lighten and Brighten pigmentations on the face and neck', '· Deeply nourish and hydrate to achieve firmer skin'] }
      },
      {
        title: 'Upon Check In',
        item1: { t: 'IBCLC Breastfeeding Guidance', v: ['· Certified lactation consultant support for comprehensive breast health', '', ''] },
        item2: { t: 'Gentle Pain Management Techniques', v: ['· Assessment of postpartum health and well-being', '· 30-minute relaxation massage to promote postpartum recovery', '(3 x complimentary services)'] }
      },
      {
        title: 'Day 7 after Check In',
        item1: { t: 'Postpartum Recovery Management', v: ['· Improve blood circulation to enhance recovery', '· Manual lymphatic drainage to reduce swelling', ''] },
        item2: { t: 'Muscle Metabolism Management', v: ['· Improve muscle metabolism to promote cellular regeneration', '· Enhance metabolic function to achieve toning and sculpting of muscle tissues  ', ''] }
      },
      {
        title: 'Day 14 after Admission',
        item1: { t: 'Body Shape and Posture Management', v: ['· Postural adjustments to achieve postural alignment', '· Postural re-education to combat ageing', ''] },
        item2: { t: 'Fat Body Management', v: ['· Reshaping excessive fat proportions', '· Achieving a youthful body curve', ''] }
      },
      {
        title: 'Day 21 after Admission',
        item1: { t: 'Breast Shape Management', v: ['· Customized to prevent sagging and displacement', '· Optimizing breast size, firmness, and elasticity', ''] },
        item2: { t: '', v: ['', '', ''] }
      },
      {
        title: 'Day 42 after Admission',
        item1: { t: 'Pelvic Floor Muscle Management', v: ['· Stimulating the vitality of pelvic floor muscles', '· Strengthening the pelvic floor for age-related changes', ''] },
        item2: { t: '', v: ['', '', ''] }
      }
    ]
  },
  zycp: {
    title: ['STB', 'Self-Developed Products'],
    list: [
      { title: ['S-bra', 'Functional Lingerie'], desc: 'Originating in South Korea, the brand was established in 2010 and is now under PrimeCare International\'s "S Treatment Beauty". Continuing the professionalism of South Korean technical research and development teams and tapping into a database of women\'s body shapes, the company custom-makes functional lingerie for various needs such as pregnancy breast support, and fat-managing care. Custom-made, each piece of luxurious lace is meticulously woven by craftsmen with over 10 years of experience, based on 96 sets of precise body measurement data.' },
      { title: ['S-Corset', 'Postpartum Shaping Jumpsuit'], desc: 'Through measurement and analysis of 96 sets of body data and analysis of factors that arrive at five major body types and data on their characteristics, as well as expert identification of 384 topical body pressure values, we work out one-on-one sculpting plans. 50 pieces of dedicated three-dimensional suits are cut out by hand, using imported medical-grade fabrics to fit various prominent fat forming areas postpartum such as the waist, abdomen, hips, and legs.' },
      { title: ['STB', 'Herbal Hair Care Set'], desc: 'Our artisans have painstakingly developed a form of herbal hair care, combining eight types of Chinese herbs free of chemical additives. Gentle in removing dirt, eliminating dampness and coldness, and helping mothers overcome weakness during postpartum recovery.' },
      { title: ['STB', 'Herbal Foot Bath Pack'], desc: 'We\'ve selected nine herbal essences to relieve postpartum physical fatigue, improve sleep quality, and dispel wind and cold. The herbal foot bath with suitable temperature will expand blood vessels, thus promoting blood circulation and metabolism, which is beneficial for postpartum physical recovery.' }
    ]
  }
}

export { cn, tw, en }
