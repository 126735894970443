
import { createRouter, createWebHistory } from 'vue-router'
import routes from './router'
import { updateWxConfig } from '@/util/wxJssdk'

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, c) {
    // history模式，每次进入页面都是新地址，所以需要重新获取签名信息
    // hash 定位
    if (to.hash) {
      return { el: to.hash }
    }
    // 稍微动一下，使动画不暂停
    if (to.meta.backScroll && c?.top) {
      setTimeout(() => scrollTo({ top: c.top + 5, behavior: 'smooth' }))
      return c
    }
    return { top: 0 }
  },
  routes
})

router.afterEach(() => {
  // 更新微信分享参数 设置最新url
  updateWxConfig(window.location.href.split('#')[0])
  // 延迟3000秒执行注册
})

export default router
