import { request } from '@/util/request'

/**
 * 获取艺术疗养列表
 */
export const _submitFeedBack = (data) => {
  return request({
    method: 'post',
    url: '/Index/submitFeedBack',
    data
  })
}
