// 中文翻译
const cn = {
  test: 1,
  banner: {
    title: '圣贝拉艺术疗养',
    subTitle: '关注产后女性精神世界，将艺术融入新生之旅'
  },
  theme: {
    title: '“当我们把艺术看作一种治疗手段，则可以指导、告诫和抚慰艺术欣赏者，使他们成为更好的自己。”',
    name: '《艺术的疗效》Art as Therapy Alain de Botton / John Armstrong'
  },
  lookMore: '即刻探索',
  list: [
    {
      title: '清晨「音乐疗愈」',
      value: '联合《时尚芭莎艺术》与武汉音乐学院<br>共同甄选适合产后聆听的28首古典音乐',
      desc: '妈妈的心跳，是我们和这个世界接触最早的音乐节拍。与心跳 60-90/BPM 频率相同的音乐像心灵稳定器，古典音乐里大量存在的"1/F波动"，使之成为了不可撼动的胎教音乐及治疗性音乐，能够调节情绪、改变呼吸频率、缓解心情。'
    },
    {
      title: '午后「漫谈影记」',
      value: '电影里的世界将真实融入艺术<br>有生活与人性的千万面写照',
      desc: '适当欣赏舒缓情绪的电影，避免陷入产后焦虑和不安，配以3分钟影片解读，缓解妈妈过度用眼，为新生家庭营造片刻惬意。'
    },
    {
      title: '傍晚「艺史赏析」',
      value: '绘画课程配合画作雕塑赏析解说<br>让月子生活充满丰富的艺史趣闻',
      desc: '每一幅伟大作品的背后都蕴藏着许多故事，无论作画还是赏画，都能令妈妈在月子期获得精神自由与休息。艺术作为启蒙教育的良方，能够帮助培养一定的思维与习惯，在未来启迪宝宝的艺术兴趣。'
    },
    {
      title: '夜晚「夜读诗选」',
      value: '联合《ELLE》倾情定制「夜读诗选」书册<br>每夜爸爸轻声朗读 增加爸爸育儿参与度',
      desc: '研究表明，在宝宝与世界建立关系的初生阶段，男性低频调的声音更容易被感知，以此培养亲子信任感，建立亲密关系，每夜睡前为TA朗读，给予初识世界的无限温柔。'
    }
  ]
}
// 繁体翻译
const tw = {
  banner: {
    title: '聖貝拉藝術療養',
    subTitle: '關註產後女性精神世界，將藝術融入新生之旅'
  },
  theme: {
    title: '“當我們把藝術看作一種治療手段，則可以指導、告誡和撫慰藝術欣賞者，使他們成為更好的自己。”',
    name: '《藝術的療效》Art as Therapy Alain de Botton / John Armstrong'
  },
  lookMore: '即刻探索',
  list: [
    {
      title: '清晨「音樂療愈」',
      value: '聯合《時尚芭莎藝術》與武漢音樂學院<br>共同甄選適合產後聆聽的28首古典音樂',
      desc: '媽媽的心跳，是我們和這個世界接觸最早的音樂節拍。與心跳 60-90/BPM 頻率相同的音樂像心靈穩定器，古典音樂裏大量存在的"1/F波動"，使之成為了不可撼動的胎教音樂及治療性音樂，能夠調節情緒、改變呼吸頻率、緩解心情。'
    },
    {
      title: '午後「漫談影記」',
      value: '電影裏的世界將真實融入藝術<br>有生活與人性的千萬面寫照',
      desc: '適當欣賞舒緩情緒的電影，避免陷入產後焦慮和不安，配以3分鐘影片解讀，緩解媽媽過度用眼，為新生家庭營造片刻愜意。'
    },
    {
      title: '傍晚「藝史賞析」',
      value: '繪畫課程配合畫作雕塑賞析解說<br>讓月子生活充滿豐富的藝史趣聞',
      desc: '每一幅偉大作品的背後都蘊藏著許多故事，無論作畫還是賞畫，都能令媽媽在月子期獲得精神自由與休息。藝術作為啟蒙教育的良方，能夠幫助培養一定的思維與習慣，在未來啟迪寶寶的藝術興趣。'
    },
    {
      title: '夜晚「夜讀詩選」',
      value: '聯合《ELLE》傾情定製「夜讀詩選」書冊<br>每夜爸爸輕聲朗讀 增加爸爸育兒參與度',
      desc: '研究表明，在寶寶與世界建立關系的初生階段，男性低頻調的聲音更容易被感知，以此培養親子信任感，建立親密關系，每夜睡前為TA朗讀，給予初識世界的無限溫柔。'
    }
  ]
}
// 英文翻译
const en = {
  test: 1,
  banner: {
    title: '', // BELLA ARTS
    subTitle: 'Focusing on the Spiritual World of Postpartum Women<br>Integrating Art into the Journey of a New Life'
  },
  theme: {
    title: '“When we view art as a therapeutic tool, we can guide, admonish, and console art enthusiasts, helping them evolve into better versions of themselves.”',
    name: 'Art as Therapy Alain de Botton / John Armstrong'
  },
  lookMore: 'Explore Now',
  list: [
    {
      title: 'Morning: Music Therapy',
      value: 'In Collaboration with Harper’s BAZAAR and Wuhan Conservatory of Music<br>Together we curated 28 Classical Pieces suitable for postpartum listening',
      desc: 'Mother’s heartbeat is the earliest musical rhythm we encounter in this world. Music with a 60-90 BPM frequency, akin to a heartbeat, serves as a stabilizer for the soul. The rich “1/F tempo” found in classical music makes it an unshakable tool for prenatal education and therapeutic relaxation. It can regulate emotions, alter breathing frequencies, and uplift moods.'
    },
    {
      title: 'Afternoon: Movie Diary',
      value: 'Where Real Life Blends with Art in Cinema<br>A Kaleidoscope of Life and Humanity',
      desc: 'Watching appropriately soothing movies can effectively prevent postpartum anxiety and restlessness. Complement that with a 3-minute movie review, we help mothers relieve their eye strain and offer a moment of solace to the new families.'
    },
    {
      title: 'Evening: Art Reader',
      value: 'Painting Classes Paired with Sculpture Appreciation Commentary<br>Enriching Postpartum Life with Stories from Art History',
      desc: 'Behind every masterpiece lies a multitude of stories. Whether it is about creating paintings or admiring them, this would enable mothers to gain spiritual liberation and rest during their postpartum period. As an enlightening educational formula, art can help cultivate specific thinking patterns and ignite a future interest in art in the babies.'
    },
    {
      title: 'Late Night: Poetry Collections',
      value: 'In Collaboration with ELLE: Customized Poetry Collections Booklet<br>Dad Softly Reads Every Night, Enhancing His Involvement in Childcare',
      desc: 'Research indicates that during a baby’s establishment of connections with the world at infant stage, low-pitch male voices are more perceptible. This helps cultivate trust and establish intimate parent-child bond. Reading to your baby every night before bedtime provides boundless tenderness for their first encounter with the world.'
    }
  ]
}

export { cn, tw, en }
