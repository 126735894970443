import { _getStroeList, _getBannerList, _getSystemInfo } from '@/api'
import { valueLang, getCountryInfo } from '@/util'
import { createStore } from 'vuex'

export default createStore({
  state: {
    isMobile: navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i),
    ASSETSURL: 'https://devop.primecare.top/website/master/assets/', // 静态资源路径
    REQASSETSURL: 'https://devop.primecare.top/', // 后端资源路径
    headerTheme: {
      textColor: true, // 右边部分颜色 true-白色  false-黑色
      // 背景 颜色
      bgColor: 'transparent',
      // logo颜色 white || black
      logo: ''
    },
    // 门店列表
    storeList: JSON.parse(localStorage.getItem('StoreList') || '[]'),
    // 首页轮播数据
    swiperList: JSON.parse(localStorage.getItem('SwiperList') || '[]'),
    // 系统设置信息
    system: JSON.parse(localStorage.getItem('System') || '{}'),
    // 国家列表
    countryList: JSON.parse(localStorage.getItem('CountryList') || '[]'),
    // 当前选中国家
    country: localStorage.getItem('country'),
    // 0 云雀 1 企微
    customerType: 1
  },
  getters: {
    // 首页左屏轮播
    getLeftSwiper(state) {
      return state.swiperList[0]
    },
    // 首页右屏轮播
    getRightSwiper(state) {
      return state.swiperList[1]
    },
    // 门店列表
    getStoreList(state) {
      return state.storeList.filter(v => v.type === '1').map(v => {
        v.addressName = v[valueLang('addressName')]
        v.addressValue = v[valueLang('addressValue')]
        return v
      })
    },
    // 独栋系列列表
    getHotelList(state) {
      return state.storeList.filter(v => v.type === '2').map(v => {
        v.addressName = v[valueLang('addressName')]
        v.addressValue = v[valueLang('addressValue')]
        return v
      })
    },
    // 导航栏-门店信息数据
    getStoreListDate(state, getters) {
      const storeList = getters.getStoreList || []
      const hotelList = getters.getHotelList || []
      const sliceArray = (arr, isFront = true) => arr.slice(isFront ? 0 : Math.ceil(arr.length / 2), isFront ? Math.ceil(arr.length / 2) : 999).map(v => {
        v.name = v[valueLang('name')]
        return v
      })
      return {
        storeLeftList: sliceArray(storeList, true),
        storeRightList: sliceArray(storeList, false),
        hotelLeftList: sliceArray(hotelList, true),
        hotelRightList: sliceArray(hotelList, false)
      }
    },
    getCustomerType(state) {
      return state.customerType
    }
  },
  mutations: {
    setMobile(state, isMobile) {
      state.isMobile = isMobile
    },
    setHeaderTheme(state, theme = {}) {
      state.headerTheme = theme
    },
    setStoreList(state, list) {
      state.storeList = list
      localStorage.setItem('StoreList', JSON.stringify(list))
    },
    setSwiperList(state, list) {
      state.swiperList = list
      localStorage.setItem('SwiperList', JSON.stringify(list))
    },
    setSystemInfo(state, info) {
      state.system = info
      localStorage.setItem('System', JSON.stringify(info))
    },
    updateCountry(state, country) {
      state.country = country
      localStorage.setItem('country', country)
    }
  },
  actions: {
    async init() {
      this.dispatch('updateSwiperList')
      this.dispatch('updateStoreList')
      this.dispatch('updateSystemInfo')
    },
    // 更新首页swiper
    async updateSwiperList() {
      // const identifier = getCountry()
      // const countryInfo = this.state.countryList.find(v => v.identifier === identifier) || {}
      const countryInfo = getCountryInfo()
      const list = await _getBannerList({ page: 1, limit: 999, countryID: countryInfo.id }).then(res => {
        const arr = [[], []]
        res.list.sort((a, b) => b - a)
        res.list.forEach(v => {
          arr[v.position - 1].push({
            url: v.imgPath,
            title: v.title,
            title_tw: v.title_tw,
            title_en: v.title_en,
            desc: v.desc.split(';'),
            desc_tw: v.desc_tw?.split?.(';'),
            desc_en: v.desc_en?.split?.(';'),
            link: v.link
          })
        })
        return arr
      })

      this.commit('setSwiperList', list)
    },
    // 更新门店列表
    async updateStoreList() {
      const countryInfo = getCountryInfo()
      const list = await _getStroeList({ page: 1, limit: 999, countryID: countryInfo.id }).then(res => {
        return res.list.map(item => ({
          url: '/store/detail?id=' + item.id,
          name: item.title_cn,
          name_en: item.title_en,
          name_tw: item.title_tw,
          addressName: item.title_cn,
          addressName_en: item.title_en,
          addressName_tw: item.title_tw,
          addressValue: item.address_cn,
          addressValue_en: item.address_en,
          addressValue_tw: item.address_tw,
          type: item.type, // 1 酒店  2 独栋
          id: item.id,
          location: item.location,
          countryID: item.countryID
        }))
      })

      this.commit('setStoreList', list)
    },
    // 更新系统设置
    async updateSystemInfo() {
      const { data: info } = await _getSystemInfo()
      info.mediaEmail = 'pr@primecare.group'
      this.commit('setSystemInfo', info)
      return info
    }
  },
  modules: {
  }
})
